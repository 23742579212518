import { IconProps } from '@chakra-ui/react';

import {
  ArrowUpOnSquareIcon,
  BadgeCheckIcon,
  BankNotesIcon,
  CircularBubbleIcon,
  CubeIcon,
  DocumentAttachmentIcon,
  DocumentCheckIcon,
  EnvelopeIcon,
  FlagIcon,
  FootballIcon,
  GiftTopIcon,
  GlobeAsiaAustraliaIcon,
  HeartIcon,
  MobilePhoneIcon,
  OutletIcon,
  ReceiptPercentIcon,
  SquaresPlusIcon,
  TicketIcon,
  TransactionsIcon,
} from '../../components/icons';

type NavConfigType = {
  navGroup: null | string;
  toggle?: boolean;
  subnavGroup?: {
    title: string;
    key?: string;
    icon: (props: IconProps) => JSX.Element;
    endIcon?: (props: IconProps) => JSX.Element;
    show?: boolean;
    navs: {
      title: string;
      path: string;
      icon?: (props: IconProps) => JSX.Element;
    }[];
  }[];
  navs: {
    title: string;
    key?: string;
    path: string;
    icon: (props: IconProps) => JSX.Element;
    show?: boolean;
  }[];
}[];

const navConfig: NavConfigType = [
  {
    navGroup: null,
    navs: [
      {
        title: 'Get Started',
        path: '/app/get-started',
        icon: FlagIcon,
      },
      {
        title: 'Compliance',
        path: '/app/compliance',
        icon: DocumentAttachmentIcon,
        show: false,
      },
    ],
  },
  {
    navGroup: 'Our Services',
    navs: [
      {
        title: 'Product Features',
        path: '/app/product-features',
        icon: CircularBubbleIcon,
      },
    ],
  },
  {
    navGroup: 'Overview',
    navs: [
      {
        title: 'Account',
        path: '/app/dashboard',
        icon: SquaresPlusIcon,
      },
      {
        title: 'Earnings',
        path: '/app/earnings',
        icon: ReceiptPercentIcon,
      },
      {
        title: 'Transactions',
        path: '/app/transactions',
        icon: TransactionsIcon,
      },
      {
        title: 'Control Center',
        path: '/app/control-center',
        icon: CubeIcon,
      },
    ],
  },
  {
    navGroup: 'Payments',
    navs: [
      {
        title: 'Transfers',
        path: '/app/transfers',
        icon: ArrowUpOnSquareIcon,
      },
      {
        title: 'Airtime',
        path: '/app/airtime',
        icon: MobilePhoneIcon,
      },
      {
        title: 'Bill Payment',
        path: '/app/bill-payment',
        icon: BankNotesIcon,
      },
      {
        title: 'Gift Cards',
        path: '/app/gift-cards',
        icon: GiftTopIcon,
      },
      {
        title: 'Referrals',
        path: '/app/referral',
        icon: HeartIcon,
      },
    ],
  },
  {
    navGroup: 'Lifestyle',
    navs: [
      {
        title: 'Flights',
        path: '/app/flights',
        icon: GlobeAsiaAustraliaIcon,
      },
      {
        title: 'Movies & Events',
        path: '/app/movies',
        icon: TicketIcon,
      },
    ],
  },
  {
    navGroup: 'Account Management',
    navs: [
      {
        title: 'Outlets',
        path: '/app/settings?view=outlets',
        icon: OutletIcon,
      },
      {
        title: 'Mandates',
        path: '/app/settings?view=mandates',
        icon: DocumentCheckIcon,
      },
    ],
  },
  {
    navGroup: 'Developer',
    toggle: true,
    navs: [
      {
        title: 'Get Started',
        path: '/app/developer/virtual-accounts/overview',
        icon: OutletIcon,
      },
      {
        title: 'Settings',
        path: '/app/settings?view=developer',
        icon: OutletIcon,
      },
    ],
    subnavGroup: [
      {
        title: 'Virtual Accounts',
        icon: EnvelopeIcon,
        endIcon: EnvelopeIcon,
        show: true,
        navs: [
          {
            title: 'Overview',
            path: '/app/developer/opt-in/virtual-accounts',
            icon: EnvelopeIcon,
          },
          {
            title: 'Transactions',
            path: '/app/developer/virtual-accounts/transactions',
            icon: BadgeCheckIcon,
          },
          // {
          //   title: 'Audit Trail',
          //   path: '/app/developer/virtual-accounts/audit-trail',
          //   icon: GlobeAltIcon,
          // },
        ],
      },
      {
        title: 'Betting',
        icon: FootballIcon,
        endIcon: FootballIcon,
        show: true,
        navs: [
          // {
          //   title: 'Overview',
          //   path: '/app/developer/betting/overview',
          //   icon: EnvelopeIcon,
          // },
          {
            title: 'Wallet',
            path: '/app/developer/betting/wallet',
            icon: BadgeCheckIcon,
          },
        ],
      },
    ],
  },
];

export default navConfig;
