import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';

interface ISetPinRequest {
  pin: string;
  confirmPin: string;
}

interface ISetPinResponse {
  status?: boolean;
  message?: string;
  errors?: IApiResponseError[];
}

const initialState: IApiState<ISetPinResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const setPinAsync = createAsyncThunk(
  'pin/setPin',
  async (
    { pin, confirmPin }: ISetPinRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };
      const { data } = await api.post<ISetPinResponse>(
        '/auth/set-pin',
        {
          pin,
          confirmPin,
        },
        config
      );

      localStorage.setItem('userHasPin', 'true');

      return data;
    } catch (err) {
      const error = err as AxiosError<ISetPinResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
      });

      dispatch(
        showAlert({
          status: 'error',
          type: 'drawer',
          description: errorMessage,
        })
      );

      return rejectWithValue(error.response?.data);
    }
  }
);

export const setPinSlice = createSlice({
  name: 'pin',
  initialState,
  reducers: {
    resetSetPin: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setPinAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(setPinAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload;
      })
      .addCase(setPinAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as ISetPinResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetSetPin } = setPinSlice.actions;

export default setPinSlice.reducer;
