import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { ITeamMember } from '../../../types/teamMember';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';

interface IDisableOutletResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    name?: string;
    address?: string;
    city?: string;
    state?: string;
    isActive?: boolean;
    createdAt?: string;
    updatedAt?: string;
    teamMembers?: ITeamMember[];
  };

  errors?: IApiResponseError[];
}

const initialState: IApiState<IDisableOutletResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const disableOutletAsync = createAsyncThunk(
  'outlet/disable',
  async (outletId: string, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.put<IDisableOutletResponse>(
        `/outlets/${outletId}/disable`,
        {},
        config
      );

      dispatch(
        showAlert({
          status: 'success',
          description: `${data.data?.name} disabled successfully`,
        })
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IDisableOutletResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
        customMessage: 'Failed to disable outlet, try again',
      });

      dispatch(
        showAlert({
          status: 'error',
          description: errorMessage,
          type: 'drawer',
        })
      );

      return rejectWithValue(error.response?.data);
    }
  }
);

export const outletDisableSlice = createSlice({
  name: 'outlet/disable',
  initialState,
  reducers: {
    resetOutletDisable(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(disableOutletAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(disableOutletAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IDisableOutletResponse;
      })
      .addCase(disableOutletAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IDisableOutletResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetOutletDisable } = outletDisableSlice.actions;

export default outletDisableSlice.reducer;
