import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiState } from '../../../types/api';
import { RootState } from '../..';
import { getUserBusinessesAsync } from '../business/getUserBusinessesSlice';

interface IGetAccountsResponse {
  status?: boolean;
  message?: string;
  data?: {
    businessId?: string;
    accountName?: string;
    accountNumber?: string;
    accountType?: string;
    accountTier?: number;
  }[];
}

const initialState: IApiState<IGetAccountsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getAccountsAsync = createAsyncThunk(
  'accounts/get',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      await dispatch(getUserBusinessesAsync());

      const state = getState() as RootState;

      const businessId = localStorage.getItem('businessId');
      if (state.userBusinesses.status === 'success' && businessId) {
        const accessToken = state.auth.value?.data?.token?.accessToken;
        const config = {
          headers: {
            Authorization: `Token ${accessToken}`,
            'x-business-id': businessId,
          },
        };

        const { data } = await api.get<IGetAccountsResponse>(
          '/accounts',
          config
        );

        const businessIdFromStorage = localStorage.getItem('businessId');

        if (businessId && !businessIdFromStorage) {
          localStorage.setItem('businessId', businessId);
        }

        return data;
      }
      return null;
    } catch (err) {
      const error = err as AxiosError<IGetAccountsResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const accountsFetch = createSlice({
  name: 'accounts/fetch',
  initialState,
  reducers: {
    resetAccounts: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
      localStorage.removeItem('accountNumber');
      localStorage.removeItem('accountName');
      localStorage.removeItem('businessId');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getAccountsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetAccountsResponse;
      })
      .addCase(getAccountsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetAccountsResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetAccounts } = accountsFetch.actions;
export default accountsFetch.reducer;
