import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiState } from '../../../types/api';
import { IPermission } from '../../../types/permission';
import { RootState } from '../..';

interface IGetUserDetailsForBusinessResponse {
  status?: boolean;
  message?: string;
  data?: {
    business?: {
      id?: string;
      category?: string;
      type?: string;
      name?: string;
      email?: string;
      address?: string;
      city?: string;
      state?: string;
      hasAccount?: boolean;
      profiles?: {
        id?: string;
        businessId?: string;
        type?: string;
        createdAt?: string;
        updatedAt?: string;
      }[];
      developerId?: string;
      developerStatus?: string;
      creatorId?: string;
      creatorType?: string;
      createdAt?: string;
      updatedAt?: string;
    };
    outlets?: {
      id?: string;
      account?: {
        businessId?: string;
        accountName?: string;
        accountNumber?: string;
        accountCategory?: string;
      };
      name?: string;
      address?: string;
      city?: string;
      state?: string;
      isActive?: boolean;
      createdAt?: string;
      updatedAt?: string;
    }[];
    role?: {
      id?: string;
      name?: string;
      slug?: string;
      description?: string;
      isDefault?: boolean;
      permissions?: IPermission[];
    };
    accounts?: {
      businessId?: string;
      accountName?: string;
      accountNumber?: string;
      commissionsAccountNumber?: string;
      accountCategory?: string;
    }[];
  };
}

const initialState: IApiState<IGetUserDetailsForBusinessResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getUserDetailsForBusinessAsync = createAsyncThunk(
  'userDetailsForBusiness/get',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get<IGetUserDetailsForBusinessResponse>(
        '/user/business',
        config
      );

      const accountNumber = data?.data?.accounts?.[0]?.accountNumber;

      const accountNumberFromStorage = localStorage.getItem('accountNumber');

      if (accountNumber && !accountNumberFromStorage) {
        localStorage.setItem('accountNumber', accountNumber);
      }

      const commissionsAccountNumber =
        data?.data?.accounts?.[0]?.commissionsAccountNumber;

      const commissionsAccountNumberFromStorage = localStorage.getItem(
        'commissionsAccountNumber'
      );

      if (commissionsAccountNumber && !commissionsAccountNumberFromStorage) {
        localStorage.setItem(
          'commissionsAccountNumber',
          commissionsAccountNumber
        );
      }

      const accountName = data?.data?.accounts?.[0]?.accountName;

      const accountNameFromStorage = localStorage.getItem('accountName');

      if (accountName && !accountNameFromStorage) {
        localStorage.setItem('accountName', accountName);
      }

      return data;
    } catch (err) {
      const error = err as AxiosError<IGetUserDetailsForBusinessResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const userDetailsForBusinessFetch = createSlice({
  name: 'userDetailsForBusiness/fetch',
  initialState,
  reducers: {
    resetUserDetailsForBusiness: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetailsForBusinessAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getUserDetailsForBusinessAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetUserDetailsForBusinessResponse;
      })
      .addCase(getUserDetailsForBusinessAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetUserDetailsForBusinessResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetUserDetailsForBusiness } =
  userDetailsForBusinessFetch.actions;
export default userDetailsForBusinessFetch.reducer;
