import {
  Box,
  BoxProps,
  CloseButton,
  Divider,
  Flex,
  Image,
  Switch,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';

import Logo from '../../assets/images/9jaPay Logo_Horizontal.svg';
import { useViewComplianceContext } from '../../contexts/ViewComplianceContext';
import getHasPermission from '../../helpers/getHasPermission';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getBusinessByIdAsync } from '../../store/slices/apiKeys/getBusinessByIdSlice';
import { getWalletTransactionsAsync } from '../../store/slices/betting/getWalletTransactionsSlice';
import { toggleEnvironment } from '../../store/slices/devPlatformEnvironment/getEnvironmentSlice';
import { getTransactionsAsync } from '../../store/slices/virtualAccounts/getTransactionsSlice';
import { getVirtualAccountsAsync } from '../../store/slices/virtualAccounts/getVirtualAccountsSlice';
import navConfig from './navConfig';
import NavItem from './NavItem';
import SidebarAccounts from './SidebarAccounts';

interface AppSidebarProps extends BoxProps {
  onClose: () => void;
}

export const DRAWER_WIDTH = 240;

export default function AppSidebar({ onClose, ...rest }: AppSidebarProps) {
  const [showDeveloperPlatform, setShowDeveloperPlatform] = useState(true);
  const [hasPermission, setHasPermission] = useState(true);

  const dispatch = useAppDispatch();

  const { value } = useAppSelector((state) => state.auth);

  const { value: environment } = useAppSelector((state) => state.environment);

  const { pathname } = useLocation();

  const { viewStatus } = useViewComplianceContext();

  const match = (path?: string) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  const [accountNumber, setAccountNumber] = useState(
    localStorage.getItem('accountNumber')
  );

  const { status: accountsStatus, value: accountsValue } = useAppSelector(
    (state) => state.accounts
  );

  const { value: userBusinesses } = useAppSelector(
    (state) => state.userBusinesses
  );

  const { status: currentBusinessStatus, value: currentBusiness } =
    useAppSelector((state) => state.userDetailsForBusinessFetch);

  const [developerId, setDeveloperId] = useState(
    localStorage.getItem('developerId')
  );

  const approvalStatus = currentBusiness?.data?.business?.developerStatus;

  useEffect(() => {
    setDeveloperId(localStorage.getItem('developerId'));
  }, [userBusinesses?.data, value?.data?.user?.businesses]);

  const [isOpenIndex, setIsOpenIndex] = useState<number | null>(null);

  useEffect(() => {
    if (accountsStatus === 'success' && accountNumber === null) {
      setAccountNumber(accountsValue?.data?.[0]?.accountNumber || '');
    }
  }, [accountNumber, accountsStatus, accountsValue?.data]);

  useEffect(() => {
    if (
      currentBusinessStatus === 'success' &&
      !getHasPermission(
        'view-developer-virtual-accounts',
        currentBusiness?.data?.role?.slug,
        currentBusiness?.data?.role?.permissions
      )
    ) {
      setShowDeveloperPlatform(false);
      setHasPermission(false);
    }
  }, [
    currentBusinessStatus,
    currentBusiness?.data?.role?.permissions,
    currentBusiness?.data?.role?.slug,
  ]);

  const businessIdFromStorage = localStorage.getItem('businessId') ?? '';

  const getBusinessDetailsByBusinessId = value?.data?.user?.businesses?.find(
    (business) => business.id === businessIdFromStorage
  );

  const isAggregator = getBusinessDetailsByBusinessId?.isAggregator;

  const filteredNavConfig = accountNumber
    ? navConfig.map((navItem) => ({
        ...navItem,
        navGroup: navItem?.navGroup === 'Our Services' ? '' : navItem.navGroup,
        navs: navItem.navs.filter(
          (nav) =>
            nav.path !== '/app/get-started' &&
            nav.path !== '/app/product-features' &&
            nav.path !== '/app/compliance'
        ),
      }))
    : navConfig.map((navItem) => ({
        ...navItem,
        navGroup: navItem?.navGroup !== 'Our Services' ? '' : navItem.navGroup,
        subnavGroup: [],
        navs: navItem.navs
          .map((nav) =>
            nav.path === '/app/compliance' ? { ...nav, show: viewStatus } : nav
          )
          .filter(
            (nav) =>
              nav.path === '/app/get-started' ||
              nav.path === '/app/product-features' ||
              nav.path === '/app/compliance'
          ),
      }));

  const aggregatorNavConfig = isAggregator
    ? filteredNavConfig
    : filteredNavConfig.map((navItem) => ({
        ...navItem,
        navs: navItem.navs.filter((nav) => nav.path !== '/app/control-center'),
      }));

  const devNavConfig = aggregatorNavConfig
    .filter((navItem) => {
      if (navItem.navGroup === 'Developer') {
        return showDeveloperPlatform;
      }
      return true;
    })
    .map((navItem) => {
      if (navItem.navGroup === 'Developer') {
        const navs = developerId
          ? navItem.navs.filter((nav) => nav.title !== 'Get Started')
          : navItem.navs.filter((nav) => nav.title === 'Get Started');

        return {
          ...navItem,
          navs,
          subnavGroup: developerId ? navItem.subnavGroup : [],
        };
      }
      return navItem;
    });

  const handleOpen = (index: number) => {
    setIsOpenIndex(index === isOpenIndex ? null : index);
  };

  const [isLiveMode, setIsLiveMode] = useState(environment === 'live');

  useEffect(() => {
    setIsLiveMode(environment === 'live');
  }, [environment]);

  const handleToggleLiveMode = () => {
    dispatch(toggleEnvironment());
  };

  useEffect(() => {
    if (developerId) {
      dispatch(getBusinessByIdAsync({ id: developerId }));
      dispatch(
        getVirtualAccountsAsync({
          page: 1,
          filter: { permanent: false, transient: false },
        })
      );
      dispatch(getWalletTransactionsAsync({ page: 1 }));
    }
  }, [dispatch, developerId, environment]);

  useEffect(() => {
    if (developerId) {
      dispatch(getTransactionsAsync({ page: 1 }));
    }
  }, [developerId, dispatch, environment]);

  return (
    <Box
      as="aside"
      transition="3s ease"
      bg="#fafafa"
      w={{ base: 'full', md: DRAWER_WIDTH }}
      pos="fixed"
      h="full"
      overflowY="auto"
      css={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        MsOverflowStyle: 'none' /* IE and Edge */,
        scrollbarWidth: 'none' /* Firefox */,
      }}
      {...rest}
    >
      <Box position="relative">
        <Box mb="120px">
          <Flex
            h="20"
            alignItems="center"
            mx="6"
            justifyContent="space-between"
            mb={8}
            id="flex"
            position="sticky"
            top="0"
            bg="#fafafa"
          >
            <Box>
              <RouterLink to="/app">
                <Image src={Logo} w={{ base: '60%', md: '100%' }} />
              </RouterLink>
              <Divider position="absolute" bottom="0" borderColor="#e0e3e6" />
            </Box>
            <CloseButton
              display={{ base: 'flex', md: 'none' }}
              onClick={onClose}
            />
          </Flex>

          {devNavConfig.map((navItem, index) => (
            <Box key={navItem.navGroup || index + 1}>
              {navItem.navGroup && (
                <Flex align="center" justify="space-between" mx={6} my={6}>
                  <Text
                    textTransform="uppercase"
                    fontSize=".6875rem"
                    lineHeight=".9375rem"
                    letterSpacing="-0.41px"
                    color="gray.300"
                  >
                    {navItem.navGroup}
                  </Text>
                  {navItem.toggle && (
                    <Flex align="center">
                      <Tooltip
                        label="You cannot switch to live mode until your opt-in approval is granted."
                        isDisabled={approvalStatus === 'approved'}
                        placement="top"
                      >
                        <Box>
                          <Switch
                            isChecked={isLiveMode}
                            onChange={handleToggleLiveMode}
                            isDisabled={approvalStatus !== 'approved'}
                          />
                        </Box>
                      </Tooltip>

                      <Text
                        textTransform="uppercase"
                        fontSize=".6875rem"
                        lineHeight=".9375rem"
                        letterSpacing="-0.41px"
                        color="gray.300"
                        ml={2}
                      >
                        {isLiveMode ? 'Live' : 'Test'}
                      </Text>
                    </Flex>
                  )}
                </Flex>
              )}
              {navItem.subnavGroup?.map((subnavs, subIndex) => (
                <React.Fragment key={subIndex}>
                  <NavItem
                    key={subnavs.title}
                    item={{
                      title: subnavs.title,
                      icon: subnavs.icon,
                      endIcon: subnavs.endIcon,
                    }}
                    active={() =>
                      subnavs?.navs
                        ?.map((nav) => nav.path)
                        .join('')
                        .includes(pathname)
                    }
                    onClick={() => handleOpen(subIndex)}
                  />

                  {isOpenIndex === subIndex && (
                    <Box ml={5}>
                      {subnavs?.navs.map((nav) => (
                        <NavItem key={nav.title} item={nav} active={match} />
                      ))}
                    </Box>
                  )}
                </React.Fragment>
              ))}
              {navItem.navs.map(
                (nav) =>
                  (nav?.show === undefined || nav?.show === true) && (
                    <NavItem key={nav.title} item={nav} active={match} />
                  )
              )}
            </Box>
          ))}
        </Box>

        <SidebarAccounts />
      </Box>
    </Box>
  );
}
