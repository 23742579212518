import { Navigate } from 'react-router-dom';

const AppRoutesIndex: React.FC = () => {
  const userHasAccountFromStorage = localStorage.getItem('userHasAccount');

  if (userHasAccountFromStorage) {
    return <Navigate to="/app/dashboard" replace />;
  }

  return <Navigate to="/app/get-started" />;
};

export default AppRoutesIndex;
