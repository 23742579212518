const Stepper = {
  baseStyle: {
    stepper: {
      gap: 0,
    },
    step: {
      gap: 0,
    },
    indicator: {
      '&[data-status="active"], &[data-status="incomplete"]': {
        border: 0,
      },
      '&[data-status="complete"]': {
        background: "transparent",
        color: "initial",
      },
    },
  },
};

export default Stepper;
