import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { retailApi } from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { IOAuth } from '../../../types/user';

interface IOAuthLoginCredentials {
  email: string;
  passcode: string;
}

interface IOAuthLoginResponse {
  status?: boolean;
  message?: string;
  data?: IOAuth;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IOAuthLoginResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const oAuthLoginAsync = createAsyncThunk(
  'auth/oAuthLogin',
  async ({ email, passcode }: IOAuthLoginCredentials, { rejectWithValue }) => {
    try {
      const { data } = await retailApi.post<IOAuthLoginResponse>(
        '/auth/profile',
        {
          email: email.toLowerCase(),
          passcode,
        }
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IOAuthLoginResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const oAuthLogin = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetOAuthLogin: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(oAuthLoginAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(oAuthLoginAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload;
      })
      .addCase(oAuthLoginAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IOAuthLoginResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetOAuthLogin } = oAuthLogin.actions;

export default oAuthLogin.reducer;
