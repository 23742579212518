import { Outlet } from 'react-router-dom';

import MinimalNavbar from './MinimalNavbar';

export default function MinimalLayout() {
  return (
    <>
      <nav>
        <MinimalNavbar />
      </nav>
      <main style={{ maxWidth: '1728px', margin: 'auto' }}>
        <Outlet />
      </main>
    </>
  );
}
