import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { getAccountBalanceAsync } from '../account/getAccountBalanceSlice';

interface IMakeGiftCardPaymentRequest {
  price_id: string;
  gift_card_id: string;
  customer_id: string;
  customer_account_number: string;
  price: string;
  recipients_email: string[];
  pin: string;
  sender_name: string;
  message: string;
}

interface IMakeGiftCardPaymentResponse {
  status?: boolean;
  message?: string;
  data?: unknown;
  code?: number;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IMakeGiftCardPaymentResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const makeGiftCardPaymentAsync = createAsyncThunk(
  'giftCards/makeGiftCardPayment',
  async (
    values: IMakeGiftCardPaymentRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.post(
        '/gift-card/payment/business',
        values,
        config
      );

      dispatch(getAccountBalanceAsync());

      return data;
    } catch (err) {
      const error = err as AxiosError<IMakeGiftCardPaymentResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const makeGiftCardPaymentSlice = createSlice({
  name: 'giftCards/makeGiftCardPayment',
  initialState,
  reducers: {
    resetMakeGiftCardPayment: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(makeGiftCardPaymentAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(makeGiftCardPaymentAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IMakeGiftCardPaymentResponse;
      })
      .addCase(makeGiftCardPaymentAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IMakeGiftCardPaymentResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetMakeGiftCardPayment } = makeGiftCardPaymentSlice.actions;

export default makeGiftCardPaymentSlice.reducer;
