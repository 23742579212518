import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { IAggregatorReferralsCommission } from '../../../types/commission';
import { RootState } from '../..';

export interface IGetAggregatorReferralsCommissionsResponse {
  status?: boolean;
  message?: string;
  data?: {
    transactions?: IAggregatorReferralsCommission[];
    page: number;
    dataSize: number;
    total: number;
  };
  errors?: IApiResponseError;
}

const initialState: IApiState<IGetAggregatorReferralsCommissionsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getAggregatorReferralsCommissionsAsync = createAsyncThunk(
  'aggregatorReferralsCommissions/get',
  async (commissionNumber: string, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get(
        `/commission/transactions/aggregators/${commissionNumber}?channel=merchant`,
        config
      );

      return data;
    } catch (err) {
      const error =
        err as AxiosError<IGetAggregatorReferralsCommissionsResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const aggregatorReferralsCommissionsFetch = createSlice({
  name: 'aggregatorReferralsCommissions/fetch',
  initialState,
  reducers: {
    resetAggregatorReferralsCommissions: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAggregatorReferralsCommissionsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(
        getAggregatorReferralsCommissionsAsync.fulfilled,
        (state, action) => {
          state.status = 'success';
          state.value =
            action.payload as IGetAggregatorReferralsCommissionsResponse;
        }
      )
      .addCase(
        getAggregatorReferralsCommissionsAsync.rejected,
        (state, action) => {
          state.status = 'failed';
          state.value =
            action.payload as IGetAggregatorReferralsCommissionsResponse;
          state.error = action.error.message;
        }
      );
  },
});

export const { resetAggregatorReferralsCommissions } =
  aggregatorReferralsCommissionsFetch.actions;
export default aggregatorReferralsCommissionsFetch.reducer;
