import { Navigate, useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../store/hooks';
import { signOut } from '../store/slices/auth/signOutSlice';

interface PublicRouteProps {
  children: React.ReactNode;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const { value } = useAppSelector((state) => state.auth);

  const isSignedIn = value?.data?.token?.accessToken;
  const userHasAccount =
    value?.data?.user?.businesses?.[
      value?.data?.user?.businesses?.length - 1 || 0
    ]?.hasAccount;

  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  if (pathname.includes('passcode-reset') && isSignedIn) {
    dispatch(signOut());

    return <>{children}</>;
  }

  if (isSignedIn && userHasAccount) {
    return <Navigate to="/app/dashboard" replace />;
  } else if (isSignedIn) {
    return <Navigate to="/app/get-started" replace />;
  }

  return <>{children}</>;
};

export default PublicRoute;
