import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import {
  getNinetyDaysBeforeToday,
  getTodayDateInYYYYMMDD,
} from '../../../helpers/utilityFunctions';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { getAccountBalanceAsync } from '../account/getAccountBalanceSlice';
import { getTransactionHistoryAsync } from '../account/getTransactionHistorySlice';

export interface IInitiateBulkTransferRequest {
  customerId: string;
  title: string;
  pin: string;
  senderAccountName: string;
  senderAccountNumber: string;
  recipients: {
    recipientAccountName: string;
    recipientAccountNumber: string;
    recipientBankCode: string;
    recipientBankName: string;
    amount: number;
    nameEnquiryReference: string;
    narration: string;
  }[];
}

interface IInitiateBulkTransferResponse {
  status?: boolean;
  message?: string;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IInitiateBulkTransferResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const initiateBulkTransferAsync = createAsyncThunk(
  'transfers/create/bulk',
  async (
    {
      customerId,
      title,
      pin,
      senderAccountName,
      senderAccountNumber,
      recipients,
    }: IInitiateBulkTransferRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId =
        localStorage.getItem('businessId') ??
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };
      const { data } = await api.post(
        '/transfers/bulk',
        {
          customerId,
          pin,
          title,
          senderAccountName,
          senderAccountNumber,
          recipients,
          senderKycLevel: 3,
        },
        config
      );

      const oldAvailableBalanceFromStorage =
        localStorage.getItem('availableBalance');
      const parsedAvailableBalance = oldAvailableBalanceFromStorage
        ? (JSON.parse(oldAvailableBalanceFromStorage) as number)
        : null;

      const totalAmountTransferred = recipients.reduce((total, recipient) => {
        if (recipient.amount) {
          return total + recipient.amount;
        }

        return total;
      }, 0);

      const newAvailableBalance = parsedAvailableBalance
        ? parsedAvailableBalance - totalAmountTransferred * 100
        : null;

      if (newAvailableBalance) {
        localStorage.setItem(
          'availableBalance',
          JSON.stringify(newAvailableBalance)
        );
      }

      dispatch(getAccountBalanceAsync());

      dispatch(
        getTransactionHistoryAsync({
          searchParams: {
            startDate: getNinetyDaysBeforeToday(),
            endDate: getTodayDateInYYYYMMDD(),
            searchField: 'FinancialDate',
          },
        })
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IInitiateBulkTransferResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const transferInitiationSlice = createSlice({
  name: 'transfer/initiate',
  initialState,
  reducers: {
    resetInitiateBulkTransfer: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateBulkTransferAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(initiateBulkTransferAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IInitiateBulkTransferResponse;
      })
      .addCase(initiateBulkTransferAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IInitiateBulkTransferResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetInitiateBulkTransfer } = transferInitiationSlice.actions;

export default transferInitiationSlice.reducer;
