import { Box, BoxProps, Image, Text } from '@chakra-ui/react';

import ErrorImage from '../../../assets/images/error-image.svg';
import FlightImage from '../../../assets/images/flight-img.svg';
import InProgressImage from '../../../assets/images/in-progress-image.svg';
import MailSentImg from '../../../assets/images/mail-sent.svg';
import SuccessImage from '../../../assets/images/success-image.svg';
import TimePendingImage from '../../../assets/images/TimePendingImage.svg';

interface StatusBoxProps extends BoxProps {
  title: string;
  variant:
    | 'success'
    | 'error'
    | 'inProgress'
    | 'mailSent'
    | 'noFlight'
    | 'timePending';
  children: string;
  imgWidth?: number | string;
  imgHeight?: number | string;
  /**
   * @default 200px
   */
  imgSize?: number | string;
  cta?: React.ReactNode;
}

export default function StatusBox({
  title,
  variant,
  children,
  imgWidth,
  imgHeight,
  cta,
  imgSize = '200px',

  ...rest
}: StatusBoxProps) {
  let imgSrc = '';
  if (variant === 'success') {
    imgSrc = SuccessImage;
  } else if (variant === 'error') {
    imgSrc = ErrorImage;
  } else if (variant === 'mailSent') {
    imgSrc = MailSentImg;
  } else if (variant === 'noFlight') {
    imgSrc = FlightImage;
  } else if (variant === 'timePending') {
    imgSrc = TimePendingImage;
  } else {
    imgSrc = InProgressImage;
  }

  return (
    <Box p="10" {...rest}>
      <Image
        src={imgSrc}
        alt={variant}
        boxSize={imgSize}
        width={imgWidth}
        height={imgHeight}
        mx="auto"
        mb="4"
      />
      <Text
        fontSize="24px"
        lineHeight="133%"
        textAlign="center"
        fontWeight={500}
        mb={3}
      >
        {title}
      </Text>
      <Text
        letterSpacing="-0.12px"
        color="gray.200"
        textAlign="center"
        whiteSpace="break-spaces"
      >
        {children}
      </Text>

      <Box w="100%" display="flex" justifyContent="center" mt="8">
        {cta}
      </Box>
    </Box>
  );
}
