const Menu = {
  baseStyle: {
    list: {
      background: "#fff",
      padding: "10px",
      boxShadow: "0px 8px 20px rgba(0, 20, 25, 0.1)",
      borderRadius: "10px",
      borderWidth: 0,
      minWidth: "100%",
      display: "flex",
      flexDirection: "column",
      gap: 1,
    },
    item: {
      fontSize: ".625rem",
      lineHeight: "12px",
      "& span": {
        marginRight: "4px",
      },
    },
  },
};

export default Menu;
