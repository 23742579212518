const Divider = {
  baseStyle: {
    borderWidth: "1px",
    borderColor: "#d4d9dd",
    opacity: 1,
    borderBottomWidth: "1.5px",
  },
};

export default Divider;
