const FocusableElement = {
  baseStyle: {
    _focus: {
      borderColor: "primaryGreen",
      boxShadow: "0 0 0 2px #14CC52",
    },
  },
};

export default FocusableElement;
