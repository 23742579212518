/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../store/hooks';
import { showAlert } from '../../store/slices/appToast/appToastSlice';
import { signOut } from '../../store/slices/auth/signOutSlice';
interface InactivityDetectorProps {
  children: React.ReactNode;
}

export default function InactivityDetector({
  children,
}: InactivityDetectorProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const lastActiveTimeStampRef = useRef(Date.now());
  const timeoutIdRef = useRef<NodeJS.Timeout>();
  const eventListenersRef = useRef<{
    mousemove: () => void;
    click: () => void;
    keypress: () => void;
  }>({
    mousemove: () => {},
    click: () => {},
    keypress: () => {},
  });

  const inactivityTimeout = process.env.NODE_ENV === 'development' ? 30 : 5;

  const handleInactivity = useCallback(() => {
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(() => {
      dispatch(signOut());

      if (navigator.onLine) {
        navigate('./');
      }

      dispatch(
        showAlert({
          status: 'info',
          title: 'Inactivity Detected',
          description: 'You have been signed out due to inactivity.',
        })
      );
    }, inactivityTimeout * 60 * 1000);
  }, [dispatch, inactivityTimeout, navigate]);

  const handleUserActivity = useCallback(() => {
    lastActiveTimeStampRef.current = Date.now();

    handleInactivity();
  }, [handleInactivity]);

  useEffect(() => {
    // Set up the event handlers
    const mouseMoveHandler = handleUserActivity;
    const clickHandler = handleUserActivity;
    const keyPressHandler = handleUserActivity;

    eventListenersRef.current.mousemove = mouseMoveHandler;
    eventListenersRef.current.click = clickHandler;
    eventListenersRef.current.keypress = keyPressHandler;

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('click', clickHandler);
    document.addEventListener('keypress', keyPressHandler);

    // Initial call to set the inactivity timeout
    handleInactivity();

    return () => {
      // Clean up event listeners and timeout
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('click', clickHandler);
      document.removeEventListener('keypress', keyPressHandler);
      clearTimeout(timeoutIdRef.current);
    };
  }, [handleInactivity, handleUserActivity]);

  return <>{children}</>;
}
