import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { getTeamMembersAsync } from './getTeamMembers';

interface IInviteTeamMemberRequest {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  outlets?: string[];
}

interface IInviteTeamMemberResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    role?: {
      id?: string;
      name?: string;
      slug?: string;
      description?: string;
      isDefault?: boolean;
    };
    firstName?: string;
    lastName?: string;
    email?: string;
    outlets?: string[];
    createdAt?: string;
    updatedAt?: string;
    businessId?: string;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IInviteTeamMemberResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const inviteTeamMemberAsync = createAsyncThunk(
  'teamMember/create',
  async (
    values: IInviteTeamMemberRequest[],
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId =
        localStorage.getItem('businessId') ||
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.post<IInviteTeamMemberResponse>(
        '/team-members/invites',
        values,
        config
      );

      dispatch(getTeamMembersAsync());

      return data;
    } catch (err) {
      const error = err as AxiosError<IInviteTeamMemberResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const teamMemberInvitationSlice = createSlice({
  name: 'teamMember/invite',
  initialState,
  reducers: {
    resetTeamMemberInvite(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(inviteTeamMemberAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(inviteTeamMemberAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IInviteTeamMemberResponse;
      })
      .addCase(inviteTeamMemberAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IInviteTeamMemberResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetTeamMemberInvite } = teamMemberInvitationSlice.actions;

export default teamMemberInvitationSlice.reducer;
