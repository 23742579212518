import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiState } from '../../../types/api';
import { ICity } from '../../../types/location';

interface ICitiesResponse {
  status: true;
  message: string;
  data: {
    id: string;
    name: string;
    cities: ICity[];
  };
}

const initialState: IApiState<ICitiesResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getCitiesAsync = createAsyncThunk(
  'location/cities',
  async (stateId: string, { rejectWithValue, getState }) => {
    try {
      const { data } = await api.get(`states/${stateId}`);

      return data;
    } catch (err) {
      const error = err as AxiosError<ICitiesResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const citiesFetch = createSlice({
  name: 'location/cities',
  initialState,
  reducers: {
    resetCities: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';

      localStorage.removeItem('cities');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCitiesAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getCitiesAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as ICitiesResponse;
      })
      .addCase(getCitiesAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as ICitiesResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetCities } = citiesFetch.actions;
export default citiesFetch.reducer;
