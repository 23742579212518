import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiState } from '../../../types/api';
import { RootState } from '../..';

export interface IGetUserBusinessesResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    category?: string;
    type?: string;
    name?: string;
    address?: string;
    city?: string;
    state?: string;
    hasAccount?: boolean;
    profiles: {
      id?: string;
      businessId?: string;
      type?: string;
      createdAt?: string;
      updatedAt?: string;
    }[];
    creatorId?: string;
    creatorType?: string;
    roleId?: string;
    aggregatorId?: string;
    aggregatorCode?: string;
    isAggregator?: boolean;
    joinedAggregatorAt?: string;
    aggregatorLink?: string;
    createdAt?: string;
    updatedAt?: string;
    developerId?: string;
  }[];
}

const initialState: IApiState<IGetUserBusinessesResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getUserBusinessesAsync = createAsyncThunk(
  'userBusinesses/get',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };

      const { data } = await api.get<IGetUserBusinessesResponse>(
        '/businesses',
        config
      );

      const businessIdFromStorage = localStorage.getItem('businessId') ?? '';

      const currentBusiness = data?.data?.find(
        (business) => business?.id === businessIdFromStorage
      );

      if (currentBusiness) {
        const developerId = currentBusiness?.developerId;
        if (developerId) {
          localStorage.setItem('developerId', developerId);
        }
      }
      return data;
    } catch (err) {
      const error = err as AxiosError<IGetUserBusinessesResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const userBusinessesFetch = createSlice({
  name: 'userBusinesses/fetch',
  initialState,
  reducers: {
    resetUserBusinesses: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserBusinessesAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getUserBusinessesAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetUserBusinessesResponse;
      })
      .addCase(getUserBusinessesAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetUserBusinessesResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetUserBusinesses } = userBusinessesFetch.actions;
export default userBusinessesFetch.reducer;
