import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { getScheduledTransfersAsync } from './getScheduledTransfersSlice';

interface IRecipients {
  amount: number;
  nameEnquiryReference: string;
  narration: string;
  recipientAccountName: string;
  recipientAccountNumber: string;
  recipientBankName: string;
  recipientBankCode: string;
}

interface IInitiateScheduledTransferRequest {
  customerId: string;
  pin: string;
  senderAccountName: string;
  senderAccountNumber: string;
  bankCode: string;
  startDate: string;
  endDate: string;
  repeatCycle: string;
  recipients: IRecipients[];
}

interface IInitiateScheduledTransferResponse {
  status?: boolean;
  message?: string;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IInitiateScheduledTransferResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const initiateScheduledTransferAsync = createAsyncThunk(
  'scheduledTransfer/create',
  async (
    {
      customerId,
      pin,
      senderAccountName,
      senderAccountNumber,
      bankCode,
      startDate,
      endDate,
      repeatCycle,
      recipients,
    }: IInitiateScheduledTransferRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId =
        localStorage.getItem('businessId') ??
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      setTimeout(() => {
        // window.location.reload();
        dispatch(getScheduledTransfersAsync());
      }, 2500);

      const { data } = await api.post(
        '/transfers/scheduled',
        {
          customerId,
          pin,
          senderAccountName,
          senderAccountNumber,
          bankCode,
          startDate,
          endDate,
          repeatCycle,
          recipients,
        },
        config
      );

      const oldAvailableBalanceFromStorage =
        localStorage.getItem('availableBalance');
      const parsedAvailableBalance = oldAvailableBalanceFromStorage
        ? (JSON.parse(oldAvailableBalanceFromStorage) as number)
        : null;

      const totalAmountTransferred = recipients.reduce((total, recipient) => {
        if (recipient.amount) {
          return total + recipient.amount;
        }

        return total;
      }, 0);

      const newAvailableBalance = parsedAvailableBalance
        ? parsedAvailableBalance - totalAmountTransferred
        : null;

      if (newAvailableBalance) {
        localStorage.setItem(
          'availableBalance',
          JSON.stringify(newAvailableBalance)
        );
      }

      return data;
    } catch (err) {
      const error = err as AxiosError<IInitiateScheduledTransferResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const scheduledTransferInitiationSlice = createSlice({
  name: 'scheduledTransfer/initiate',
  initialState,
  reducers: {
    resetInitiateScheduledTransfer: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateScheduledTransferAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(initiateScheduledTransferAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IInitiateScheduledTransferResponse;
      })
      .addCase(initiateScheduledTransferAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IInitiateScheduledTransferResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetInitiateScheduledTransfer } =
  scheduledTransferInitiationSlice.actions;

export default scheduledTransferInitiationSlice.reducer;
