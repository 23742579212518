import {
  Box,
  Divider,
  Flex,
  FlexProps,
  HStack,
  IconButton,
  Image,
  Link,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import Logo from '../../assets/images/9jaPay Logo_Horizontal.svg';
import { SecondaryButton } from '../../components/custom';
import {
  BarsIcon,
  BellIcon,
  BellSlashIcon,
  CheckedIcon,
  ChevronDownIcon,
  MiniBriefcaseIcon,
  ProfileIcon,
  SettingsIcon,
} from '../../components/icons';
import { getLastPathTitle } from '../../helpers/utilityFunctions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { signOut } from '../../store/slices/auth/signOutSlice';
import { getUserBusinessesAsync } from '../../store/slices/business/getUserBusinessesSlice';
import { DRAWER_WIDTH } from './AppSidebar';

interface AppHeaderProps extends FlexProps {
  onOpen: () => void;
}

const AppHeader = ({ onOpen, ...rest }: AppHeaderProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const url = process.env.REACT_APP_API_DOCS_URL;

  const { value: authValue } = useAppSelector((state) => state.auth);

  const handleSignOut = () => {
    dispatch(signOut());
    navigate('./');
  };

  const handleGoToSettings = () => {
    navigate('./settings?view=profile');
  };

  const { status: businessesStatus, value: businessesValue } = useAppSelector(
    (state) => state.userBusinesses
  );

  const businessIdFromStorage = localStorage.getItem('businessId');
  const businessNameFromStorage = localStorage.getItem('businessName');

  const userHasAccountFromStorage = localStorage.getItem('userHasAccount');

  const [businessId, setBusinessId] = useState(
    businessIdFromStorage || businessesValue?.data?.[0]?.id || ''
  );
  const [businessName, setBusinessName] = useState(
    businessNameFromStorage ?? businessesValue?.data?.[0]?.name ?? ''
  );

  const userHasPin = authValue?.data?.user?.hasPin;

  const userHasAccount = authValue?.data?.user?.businesses?.find(
    (business) => business?.id === businessId
  )?.hasAccount;

  const handleChangeBusiness = useCallback(
    ({
      businessId,
      businessName,
      reloadPage = true,
    }: {
      businessId: string;
      businessName: string;
      reloadPage?: boolean;
    }) => {
      setBusinessId(businessId);
      setBusinessName(businessName);

      const storeBusinessInfoInStorage = () => {
        localStorage.setItem('businessId', businessId);
        localStorage.setItem('businessName', businessName);
      };

      storeBusinessInfoInStorage();

      if (userHasPin) {
        localStorage.setItem('userHasPin', 'true');
      }

      if (userHasAccount) {
        localStorage.setItem('userHasAccount', 'true');
      }

      if (reloadPage) {
        const otherAuthData = localStorage.getItem('otherAuthData') as string;
        const userId = localStorage.getItem('userId');

        localStorage.clear();
        localStorage.setItem('otherAuthData', otherAuthData);
        localStorage.setItem('userId', userId as string);

        if (userHasPin) {
          localStorage.setItem('userHasPin', 'true');
        }

        if (userHasAccount) {
          localStorage.setItem('userHasAccount', 'true');
        }

        storeBusinessInfoInStorage();

        window.location.reload();
      }
    },
    [userHasAccount, userHasPin]
  );

  useEffect(() => {
    if (userHasAccount && userHasAccountFromStorage === null) {
      localStorage.setItem('userHasAccount', 'true');
    } else if (!userHasAccount && userHasAccountFromStorage) {
      localStorage.removeItem('userHasAccount');
    }
  }, [userHasAccount, userHasAccountFromStorage]);

  useEffect(() => {
    if (!businessNameFromStorage && businessesStatus === 'success') {
      handleChangeBusiness({
        businessId: businessesValue?.data?.[0]?.id ?? '',
        businessName: businessesValue?.data?.[0]?.name ?? '',
        reloadPage: false,
      });
    }
  }, [
    businessIdFromStorage,
    businessNameFromStorage,
    businessesStatus,
    businessesValue?.data,
    handleChangeBusiness,
  ]);

  useEffect(() => {
    if (userHasAccount && userHasAccountFromStorage === null) {
      localStorage.setItem('userHasAccount', 'true');
    } else if (!userHasAccount && userHasAccountFromStorage) {
      localStorage.removeItem('userHasAccount');
    }
  }, [userHasAccount, userHasAccountFromStorage]);

  return (
    <Flex
      as="header"
      width={{ base: '100%', md: `calc(100% - ${DRAWER_WIDTH + 1}px)` }}
      px={{ base: 4, md: '44px' }}
      ml={{ base: 0, md: DRAWER_WIDTH + 1 }}
      height="20"
      alignItems="center"
      justifyContent="space-between"
      position="fixed"
      zIndex="1001"
      background="#fafafa"
      // maxW="1728px"
      // margin="auto"
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="ghost"
        aria-label="open menu"
        icon={<BarsIcon w="24px" />}
      />

      <LinkBox display={{ base: 'flex', md: 'none' }} justifyContent="center">
        <LinkOverlay as={RouterLink} to="/app">
          <Image src={Logo} w="60%" />
        </LinkOverlay>
      </LinkBox>

      <Text
        id="page-header-title"
        display={{ base: 'none', md: 'block' }}
        fontWeight={500}
      >
        {getLastPathTitle(pathname)}
      </Text>

      <HStack spacing={{ base: '0', md: '1' }} alignItems="center">
        <Box
          display={{ base: 'none', md: 'flex' }}
          alignItems="center"
          gap={{ base: '0', md: '1' }}
        >
          <Box borderRadius="40px" bg="#ffffff" padding="8px">
            <Link href={url} isExternal>
              <Text fontSize="14px" fontWeight={500} mr={2}>
                API Documentation
              </Text>
            </Link>
          </Box>
          <Tooltip label="Settings" hasArrow>
            <IconButton
              as={RouterLink}
              to="/app/settings"
              size="lg"
              variant="ghost"
              _hover={{
                background: 'transparent',
              }}
              aria-label="settings"
              icon={<SettingsIcon w="18px" />}
            />
          </Tooltip>

          <Menu matchWidth>
            <MenuButton transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <IconButton
                as={Box}
                size="lg"
                variant="ghost"
                _hover={{
                  background: 'transparent',
                }}
                aria-label="open notifications"
                icon={<BellIcon w="18px" />}
              />
            </MenuButton>
            <MenuList bg="white" borderColor="gray.100" w="200px">
              <MenuItem
                fontSize=".875rem"
                lineHeight="150%"
                noOfLines={1}
                borderRadius="4px"
                display="flex"
                height="200px"
                _focus={{ boxShadow: 'none' }}
              >
                <Box py="80px" maxW="307px" mx="auto">
                  <BellSlashIcon
                    boxSize="100px"
                    display="block"
                    margin="auto"
                    mb="6"
                  />
                  <Text textAlign="center" mb={1} fontWeight={500}>
                    There is nothing to see here yet
                  </Text>
                </Box>
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>

        <Flex alignItems="center">
          <Menu matchWidth>
            <MenuButton
              py={2}
              px={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack mr={2} justifyContent="space-between">
                <ProfileIcon />

                <Box display={{ base: 'none', md: 'flex' }}>
                  <ChevronDownIcon w="20px" />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList bg="white" borderColor="gray.100" w="100px">
              <MenuItem
                fontSize=".875rem"
                lineHeight="150%"
                noOfLines={1}
                borderRadius="4px"
                onClick={handleGoToSettings}
                display="flex"
                justifyContent="space-between"
              >
                Profile
              </MenuItem>
              <MenuItem
                fontSize=".875rem"
                lineHeight="150%"
                borderRadius="4px"
                onClick={handleSignOut}
                textAlign="center"
                justifyContent="center"
              >
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>

          {businessesStatus === 'failed' && (
            <SecondaryButton onClick={() => dispatch(getUserBusinessesAsync())}>
              Refetch Businesses
            </SecondaryButton>
          )}

          {businessesStatus === 'success' &&
            !!businessesValue?.data?.length && (
              <Menu matchWidth>
                <MenuButton
                  py={2}
                  px={2}
                  transition="all 0.3s"
                  _focus={{ boxShadow: 'none' }}
                  borderRadius="8px"
                  background="ash.300"
                  padding="8px 6px 8px 12px"
                  width={{ base: '150px', md: '192px' }}
                >
                  <HStack mr={2} justifyContent="space-between">
                    <MiniBriefcaseIcon />

                    <Text
                      maxW="104px"
                      fontSize=".875rem"
                      fontWeight={500}
                      noOfLines={1}
                      textAlign="left"
                    >
                      {businessName}
                    </Text>

                    <Box display={{ base: 'none', md: 'flex' }}>
                      <ChevronDownIcon w="20px" />
                    </Box>
                  </HStack>
                </MenuButton>
                <MenuList bg="white" borderColor="gray.100" w="100%">
                  {businessesValue?.data?.map((business, index) => (
                    <MenuItem
                      id={index.toString()}
                      key={business.id}
                      fontSize=".875rem"
                      lineHeight="150%"
                      noOfLines={1}
                      borderRadius="4px"
                      onClick={() =>
                        handleChangeBusiness({
                          businessId: business.id ?? '',
                          businessName: business.name ?? '',
                        })
                      }
                      display="flex"
                      gap="3"
                      justifyContent="space-between"
                    >
                      {business.name}{' '}
                      {business.name === businessName && (
                        <CheckedIcon
                          verticalAlign="middle"
                          boxSize="14px"
                          fill="#00C707"
                        />
                      )}
                    </MenuItem>
                  ))}

                  <Divider display={{ base: 'inherit', md: 'none' }} />

                  <MenuItem
                    as={RouterLink}
                    to="/app/settings"
                    fontSize=".875rem"
                    lineHeight="150%"
                    borderRadius="4px"
                    display={{ base: 'inherit', md: 'none' }}
                  >
                    Settings
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
        </Flex>
      </HStack>
    </Flex>
  );
};

export default AppHeader;
