import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiState } from '../../../types/api';
import { RootState } from '../..';

export interface ITransactionLimitResponse {
  status?: boolean;
  message?: string;
  data?: [
    {
      id?: string;
      name?: string;
      category?: string;
      code?: string;
      description?: string;
      minimumValue: number;
      defaultValue: number;
      maximumValue: number;
      valueType?: string;
      version?: number;
      businessType?: string;
    }
  ];
}

const initialState: IApiState<ITransactionLimitResponse['data']> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getTransactionLimitListAsync = createAsyncThunk(
  'transactionLimitList/get',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `BearerToken ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get('transaction-limits/business', config);

      return data;
    } catch (err) {
      const error = err as AxiosError<ITransactionLimitResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const transactionLimitListFetch = createSlice({
  name: 'transactionLimitList/fetch',
  initialState,
  reducers: {
    resetTransactionLimitList: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionLimitListAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getTransactionLimitListAsync.fulfilled, (state, action) => {
        state.status = 'success';
        const payload = action.payload as ITransactionLimitResponse;
        state.value = payload?.data || null;
      })
      .addCase(getTransactionLimitListAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetTransactionLimitList } = transactionLimitListFetch.actions;

export default transactionLimitListFetch.reducer;
