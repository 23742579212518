import {
  AbsoluteCenter,
  Box,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import NINImage3 from '../../../assets/images/nin3.png';
import NINImage4 from '../../../assets/images/nin4.png';
import NINImage5 from '../../../assets/images/nin5.png';
import {
  AnimatedLogo,
  CustomFormErrorMessage,
  PrimaryButton,
  StatusBox,
} from '../../../components/custom';
import { InputGroupWithLoader } from '../../../components/custom/CustomInputs';
import { ninValidation } from '../../../constants/validationConstants';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { useTrackEvent } from '../../../iterable/useTrackEvent';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  createNinVerificationAsync,
  resetNinVerification,
} from '../../../store/slices/kyb/createNinVerificationSlice';
import { getKybByIdAsync } from '../../../store/slices/kyb/fetchKybSlice';
import { AccountForms } from './AccountSetupForms';

interface NINFormProps {
  setCurrentAccountSetupForm?: React.Dispatch<
    React.SetStateAction<AccountForms>
  >;
}

export default function NINForm({ setCurrentAccountSetupForm }: NINFormProps) {
  const { value: authValue } = useAppSelector((state) => state.auth);

  const { status: kybStatus, value: kybValue } = useAppSelector(
    (state) => state.kybGetById
  );

  const { status: ninStatus, value: ninValue } = useAppSelector(
    (state) => state.ninVerification
  );

  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const trackEvent = useTrackEvent();

  useEffect(() => {
    appDispatch(getKybByIdAsync());
  }, [appDispatch]);

  useEffect(() => {
    if (
      kybStatus === 'success' &&
      kybValue?.data?.ninVerificationStatus !== 'approved' &&
      kybValue?.data?.ninVerificationStatus !== 'pending'
    ) {
      const refetchKybStatus = setTimeout(() => {
        appDispatch(getKybByIdAsync());
      }, 60000);

      return () => clearTimeout(refetchKybStatus);
    }
  }, [appDispatch, kybStatus, kybValue?.data?.ninVerificationStatus]);

  useEffect(() => {
    if (
      (ninStatus === 'success' &&
        kybValue?.data?.ninVerificationStatus !== 'approved') ||
      (kybStatus === 'success' &&
        kybValue?.data?.ninVerificationStatus !== 'approved')
    ) {
      const refetchKybStatus = setTimeout(() => {
        appDispatch(getKybByIdAsync());
      }, 60000);

      return () => clearTimeout(refetchKybStatus);
    }
  }, [
    appDispatch,
    ninStatus,
    kybStatus,
    kybValue?.data?.ninVerificationStatus,
  ]);

  useEffect(() => {
    if (
      ninStatus === 'success' &&
      kybValue?.data?.ninVerificationStatus === 'approved'
    ) {
      trackEvent({
        eventName: 'kyb',
        dataFields: {
          livenessCheckVerified: true,
          passcodeCreated: true,
          lastSeen: Date(),
          bvnSubmitted: true,
          bvnVerified: true,
          idVerificationSubmitted: true,
          idVerificationVerified: true,
        },
      });
    }
  }, [ninStatus, kybStatus, kybValue, trackEvent]);

  const initialValues = {
    nin: '',
  };

  const validationSchema = Yup.object().shape({
    nin: ninValidation,
  });

  const handleNINChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value === '' || (value.length <= 11 && /^\d+$/.test(value))) {
      setFieldValue('nin', value);
    }
  };

  const [isRevalidatingNIN, setIsRevalidatingNIN] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ nin }) => {
      appDispatch(
        createNinVerificationAsync({
          nin,
          dateOfBirth: authValue?.data?.user?.dateOfBirth?.split('T')[0] ?? '',
          email: authValue?.data?.user?.email ?? '',
          firstName: authValue?.data?.user?.firstName ?? '',
          lastName: authValue?.data?.user?.lastName ?? '',
          phoneNumber: authValue?.data?.user?.phoneNumber ?? '',
          gender: authValue?.data?.user?.gender ?? '',
          userPhotoUrl:
            authValue?.data?.user?.livenessDocument?.selfiePhotoUrl ?? '',
        })
      );
    },
  });

  const {
    errors,
    touched,
    values,
    isValid,
    getFieldProps,
    setFieldValue,
    handleSubmit,
  } = formik;

  const errorMessage = useGetUIErrorMessage({
    message: ninValue?.message,
    errors: ninValue?.errors,
  });

  const revalidateNIN = (nin: string) => {
    setIsRevalidatingNIN(false);

    appDispatch(resetNinVerification());

    appDispatch(
      createNinVerificationAsync({
        nin,
        dateOfBirth: authValue?.data?.user?.dateOfBirth?.split('T')[0] ?? '',
        email: authValue?.data?.user?.email ?? '',
        firstName: authValue?.data?.user?.firstName ?? '',
        lastName: authValue?.data?.user?.lastName ?? '',
        phoneNumber: authValue?.data?.user?.phoneNumber ?? '',
        gender: authValue?.data?.user?.gender ?? '',
        userPhotoUrl:
          authValue?.data?.user?.livenessDocument?.selfiePhotoUrl ?? '',
      })
    );
  };

  if (isRevalidatingNIN) {
    return (
      <Box>
        <Box mt={4} mb={6}>
          <Text fontSize="1.5rem" color="#000" mb={1}>
            Identity Verification
          </Text>
          <Text color="gray.300" fontSize=".875rem" lineHeight="171.5%">
            Verify your identity by entering your NIN
          </Text>
        </Box>
        <FormikProvider value={formik}>
          <Form
            autoComplete="off"
            noValidate
            onSubmit={(e) => {
              e.preventDefault();

              revalidateNIN(values.nin);
            }}
          >
            <Box display="flex" flexDirection="column">
              <Stack spacing={8} flex="1">
                <FormControl
                  id="nin"
                  isInvalid={Boolean(touched.nin && errors.nin)}
                >
                  <FormLabel>National Identity Number</FormLabel>
                  <InputGroupWithLoader isLoading={ninStatus === 'loading'}>
                    <Input
                      placeholder="12345678910"
                      {...getFieldProps('nin')}
                      onChange={handleNINChange}
                    />
                  </InputGroupWithLoader>
                  <CustomFormErrorMessage>
                    {touched.nin && errors.nin}
                  </CustomFormErrorMessage>
                </FormControl>

                <Box
                  background="ash.100"
                  // border="1px solid #ebedef"
                  borderRadius="8px"
                  padding="12px 16px"
                  fontSize=".875rem"
                  lineHeight="150%"
                  letterSpacing="-0.12px"
                  color="gray.200"
                >
                  <Text fontWeight="600" mb={2}>
                    NIN Sample
                  </Text>
                  <Text mb={6}>
                    Lorem ipsum dolor sit amet, consectetur amet site
                    adipiscing. Visit{' '}
                    <a
                      href="https://nimc.gov.ng/sms-service/"
                      style={{
                        fontWeight: 500,
                        color: '#12BA4B',
                      }}
                    >
                      nimc.gov.ng/sms-service
                    </a>
                  </Text>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '16px',
                      width: '100%',
                      overflow: 'scroll',
                    }}
                  >
                    <Image
                      src={NINImage3}
                      alt="Sample NIN Slip Image"
                      style={{
                        border: '10px solid #F5F6F6',
                        borderRadius: '32px',
                      }}
                    />
                    <Image
                      src={NINImage4}
                      alt="Sample NIN Card Image"
                      style={{
                        border: '10px solid #F5F6F6',
                        borderRadius: '32px',
                      }}
                    />
                    <Image
                      src={NINImage5}
                      alt="Sample NIN Slip Image"
                      style={{
                        border: '10px solid #F5F6F6',
                        borderRadius: '32px',
                      }}
                    />
                  </Box>
                </Box>
              </Stack>

              <PrimaryButton
                w="full"
                type="submit"
                isDisabled={!isValid || !values.nin}
                marginTop="24px"
                data-testid="revalidate-nin-submit-button"
              >
                Verify NIN
              </PrimaryButton>
            </Box>
          </Form>
        </FormikProvider>
      </Box>
    );
  }

  if (kybStatus === 'loading') {
    return (
      <Box
        position="relative"
        zIndex="2"
        height="80vh"
        top="-120px"
        background="#fff"
      >
        <AbsoluteCenter>
          <AnimatedLogo />
        </AbsoluteCenter>
      </Box>
    );
  }

  if (kybStatus === 'failed') {
    return (
      <>
        <Box
          position="relative"
          zIndex="2"
          height="80vh"
          top="-120px"
          background="#fff"
        >
          <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
            <StatusBox
              variant="error"
              title="Unable to retrieve your account information"
              px={4}
            >
              An error occurred while retrieving your account information
            </StatusBox>
          </AbsoluteCenter>
        </Box>
        <PrimaryButton
          onClick={() => {
            appDispatch(getKybByIdAsync());
          }}
          w="full"
          data-testid="try-failed-nin-button"
        >
          Retry
        </PrimaryButton>
      </>
    );
  }

  if (
    ninStatus === 'failed' &&
    (errorMessage.includes('already in progress') ||
      errorMessage.includes('being verified'))
  ) {
    return (
      <>
        <Box
          position="relative"
          zIndex="2"
          height="80vh"
          top="-120px"
          background="#fff"
        >
          <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
            <StatusBox
              px={4}
              variant="inProgress"
              title="Verification in progress."
            >
              {errorMessage}
            </StatusBox>
          </AbsoluteCenter>
        </Box>

        <PrimaryButton
          onClick={() => navigate('/app/get-started')}
          w="full"
          data-testid="in-progress-exit-button"
        >
          Dismiss
        </PrimaryButton>
      </>
    );
  }

  if (ninStatus === 'failed') {
    return (
      <>
        <Text fontSize="1.5rem">NIN Verification</Text>
        <Box
          position="relative"
          zIndex="2"
          height="80vh"
          top="-120px"
          background="#fff"
        >
          <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
            <StatusBox variant="error" title="Verification Failed" px={4}>
              {errorMessage}
            </StatusBox>
          </AbsoluteCenter>
        </Box>
        <PrimaryButton
          onClick={() => {
            appDispatch(resetNinVerification());
          }}
          w="full"
          data-testid="failed-nin-retry-button"
        >
          Retry
        </PrimaryButton>
      </>
    );
  }

  if (ninStatus === 'success') {
    trackEvent({
      eventName: 'kyb',
      dataFields: {
        livenessCheckVerified: true,
        passcodeCreated: true,
        lastSeen: Date(),
        bvnSubmitted: true,
        bvnVerified: true,
        idVerificationSubmitted: true,
        idVerificationVerified: false,
      },
    });

    return (
      <>
        <Box position="relative" zIndex="2" height="80vh" background="#fff">
          <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
            <StatusBox
              px={4}
              variant="inProgress"
              title="NIN Verification In Progress."
            >
              Your verification would take a few minutes and an email
              notification would be sent once verification is completed.
            </StatusBox>
          </AbsoluteCenter>
        </Box>
        <PrimaryButton
          w="full"
          top="initial"
          right="initial"
          onClick={() => {
            appDispatch(resetNinVerification());
            appDispatch(getKybByIdAsync());
          }}
          data-testid="nin-verification-dismiss-button"
        >
          Dismiss
        </PrimaryButton>
      </>
    );
  }

  if (
    kybValue?.data?.ninVerificationStatus === 'rejected' &&
    ninStatus === 'idle'
  ) {
    return (
      <>
        <Box
          position="relative"
          zIndex="2"
          height="80vh"
          top="-120px"
          background="#fff"
        >
          <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
            <StatusBox
              px={4}
              variant="error"
              title="NIN Verification Rejected."
            >
              Kindly review the submitted NIN and make the necessary
              adjustments.
            </StatusBox>
          </AbsoluteCenter>
        </Box>
        <PrimaryButton
          w="full"
          top="initial"
          right="initial"
          onClick={() => {
            appDispatch(resetNinVerification());
            setIsRevalidatingNIN(true);
          }}
          data-testid="rejected-nin-revalidate-button"
        >
          Revalidate
        </PrimaryButton>
      </>
    );
  }

  if (kybValue?.data?.ninVerificationStatus === 'processing') {
    return (
      <>
        <Box
          position="relative"
          zIndex="2"
          height="80vh"
          top="-120px"
          background="#fff"
        >
          <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
            <StatusBox
              px={4}
              variant="inProgress"
              title="NIN Verification In Progress."
            >
              Your verification would take a few minutes and an email
              notification would be sent once verification is completed.
            </StatusBox>
          </AbsoluteCenter>
        </Box>
        <PrimaryButton
          w="full"
          top="initial"
          right="initial"
          onClick={() => {
            appDispatch(resetNinVerification());
          }}
          data-testid="processing-nin-dismiss-button"
        >
          Dismiss
        </PrimaryButton>
      </>
    );
  }

  if (kybValue?.data?.ninVerificationStatus === 'approved') {
    return (
      <>
        <Box
          position="relative"
          zIndex="2"
          height="50vh"
          // top="-120px"
          background="#fff"
        >
          <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
            <StatusBox
              px={4}
              variant="success"
              title="NIN Verification Successful."
            >
              Your NIN verification has been completed.
            </StatusBox>
          </AbsoluteCenter>
        </Box>
        {setCurrentAccountSetupForm ? (
          <PrimaryButton
            w="full"
            top="initial"
            right="initial"
            onClick={() => {
              setCurrentAccountSetupForm('cdd');
            }}
            data-testid="continue-to-cdd-button"
          >
            Continue
          </PrimaryButton>
        ) : (
          <PrimaryButton
            data-testid="nin-verified-close-button"
            onClick={() => navigate('/app/get-started')}
          >
            Close
          </PrimaryButton>
        )}
      </>
    );
  }

  return (
    <Box>
      <Box fontSize="1.5rem" mt={4} mb={6}>
        <Text color="#000" mb={1}>
          Identity Verification
        </Text>
        <Text color="gray.300" fontSize=".875rem" lineHeight="171.5%">
          Verify your identity by entering your NIN
        </Text>
      </Box>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column">
            <Stack spacing={8} flex="1">
              <FormControl
                id="nin"
                isInvalid={Boolean(touched.nin && errors.nin)}
              >
                <FormLabel>National Identification Number</FormLabel>
                <InputGroup>
                  <Input
                    placeholder="12345678910"
                    {...getFieldProps('nin')}
                    onChange={handleNINChange}
                  />
                  <InputRightElement
                    sx={{
                      display: ninStatus === 'loading' ? 'initial' : 'none',
                      mr: 4,
                    }}
                  >
                    <Box
                      sx={{
                        width: '24px',
                        height: '24px',
                        borderRadius: '50%',
                        background: 'ash.200',
                        padding: '4px',
                        mt: 4,
                      }}
                    >
                      <Spinner
                        speed="0.65s"
                        thickness="2px"
                        color="#7B828E"
                        size="sm"
                        mb="1"
                      />
                    </Box>
                  </InputRightElement>
                </InputGroup>
                <CustomFormErrorMessage>
                  {touched.nin && errors.nin}
                </CustomFormErrorMessage>
              </FormControl>

              <PrimaryButton
                type="submit"
                isDisabled={!isValid}
                w="full"
                marginTop="24px"
                data-testid="verify-nin-button"
              >
                Verify NIN
              </PrimaryButton>

              <Box
                background="ash.100"
                // border="1px solid #ebedef"
                borderRadius="32px"
                padding="24px"
                fontSize=".875rem"
                lineHeight="150%"
                letterSpacing="-0.12px"
                color="gray.200"
              >
                <Text fontWeight="600" mb={2}>
                  NIN Sample
                </Text>
                <Text mb={6}>
                  Lorem ipsum dolor sit amet, consectetur amet site adipiscing.
                  Visit{' '}
                  <a
                    href="https://nimc.gov.ng/sms-service/"
                    style={{
                      fontWeight: 500,
                      color: '#12BA4B',
                    }}
                  >
                    nimc.gov.ng/sms-service
                  </a>
                </Text>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '16px',
                    width: '100%',
                    overflow: 'scroll',
                  }}
                >
                  <Image
                    src={NINImage3}
                    alt="Sample NIN Slip Image"
                    style={{
                      border: '10px solid #F5F6F6',
                      borderRadius: '32px',
                    }}
                  />
                  <Image
                    src={NINImage4}
                    alt="Sample NIN Card Image"
                    style={{
                      border: '10px solid #F5F6F6',
                      borderRadius: '32px',
                    }}
                  />
                  <Image
                    src={NINImage5}
                    alt="Sample NIN Slip Image"
                    style={{
                      border: '10px solid #F5F6F6',
                      borderRadius: '32px',
                    }}
                  />
                </Box>
              </Box>
            </Stack>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
}
