const Select = {
  baseStyle: {
    field: {
      borderColor: "#ebedef",
      padding: "8px 24px",
      minHeight: "56px",
      borderWidth: 1,
      borderRadius: "14px",
      fontSize: ".875rem",
      lineHeight: "1.25rem",
      ":focus": {
        borderColor: "primaryGreen",
        boxShadow: "0 0 0 1px #14CC52",
        fontSize: ".875rem",
        borderRadius: "14px",
      },
      ":not(:placeholder-shown)": {
        fontSize: ".875rem",
        borderRadius: "14px",
      },
      ":not(:focus)": {
        borderRadius: "14px",
      },
      _invalid: {
        borderColor: "red.600",
        "&:focus": {
          boxShadow: "0 0 0 1px #db4343",
        },
      },
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {
    variant: null,
  },
};

export default Select;
