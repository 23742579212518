import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IGetReferralStatsRequest {
  businessId: string;
  startDate?: string;
  endDate?: string;
}

interface IGetReferralStatsResponse {
  status?: boolean;
  message?: string;
  data?: {
    activeReferees: number;
    inactiveReferees: number;
    totalReffered: number;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetReferralStatsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getReferralStatsAsync = createAsyncThunk(
  'getReferralStats',
  async (
    { businessId, startDate, endDate }: IGetReferralStatsRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const accessToken = state.auth.value?.data?.token?.accessToken;
      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };
      const { data } = await api.get(
        `/referral/referrer/stats/${businessId}?startDate=${startDate}&endDate=${endDate}`,
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IGetReferralStatsResponse>;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const referralStatsFetchSlice = createSlice({
  name: 'referralStats/get',
  initialState,
  reducers: {
    resetGetReferralStats: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (buliders) => {
    buliders
      .addCase(getReferralStatsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getReferralStatsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetReferralStatsResponse;
      })
      .addCase(getReferralStatsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetReferralStatsResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetReferralStats } = referralStatsFetchSlice.actions;

export default referralStatsFetchSlice.reducer;
