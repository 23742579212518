import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { IOptInResponseData } from '../../../types/devOptIn';
import { RootState } from '../..';

interface ICheckSlugExistResponse {
  status: boolean;
  message: string;
  data?: IOptInResponseData;
  error?: IApiResponseError[];
}

const initialState: IApiState<ICheckSlugExistResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const checkSlugExistAsync = createAsyncThunk(
  'checkSlugExist',
  async (slug: string, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const accessToken = state.auth.value?.data?.token?.accessToken;
      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };
      const { data } = await api.get(`/check-slug-exists?slug=${slug}`, config);
      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const checkSlugExistSlice = createSlice({
  name: 'checkSlugExist/get',
  initialState,
  reducers: {
    resetCheckSlugExist: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkSlugExistAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(checkSlugExistAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as ICheckSlugExistResponse;
      })
      .addCase(checkSlugExistAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as ICheckSlugExistResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetCheckSlugExist } = checkSlugExistSlice.actions;

export default checkSlugExistSlice.reducer;
