import { TableHeadProps, Thead } from "@chakra-ui/react";

interface CustomTheadProps extends TableHeadProps {
  children: React.ReactNode;
}

export default function CustomThead({ children }: CustomTheadProps) {
  return (
    <Thead background="#F9FAFA" color="gray.300" textTransform="uppercase">
      {children}
    </Thead>
  );
}
