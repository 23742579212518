import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';
import { getTransactionMandatesAsync } from './getTransactionMandates';

interface IDeleteTransactionMandateResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    businessId?: string;
    categoryId?: string;
    name?: string;
    minimumAmount?: number;
    description?: string;
    isActive?: boolean;
    createdAt?: string;
    updatedAt?: string;
  };

  errors?: IApiResponseError[];
}

const initialState: IApiState<IDeleteTransactionMandateResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const deleteTransactionMandateAsync = createAsyncThunk(
  'transactionMandate/delete',
  async (
    transactionMandateId: string,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const businessId =
        localStorage.getItem('businessId') ||
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.delete<IDeleteTransactionMandateResponse>(
        `/transaction/mandates/${transactionMandateId}`,
        config
      );

      dispatch(
        showAlert({
          status: 'success',
          description: data?.message ?? 'Transaction mandate has been deleted',
        })
      );

      dispatch(getTransactionMandatesAsync());

      return data;
    } catch (err) {
      const error = err as AxiosError<IDeleteTransactionMandateResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const transactionMandateDeletionSlice = createSlice({
  name: 'transactionMandate/delete',
  initialState,
  reducers: {
    resetTransactionMandateDeletion(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteTransactionMandateAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(deleteTransactionMandateAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IDeleteTransactionMandateResponse;
      })
      .addCase(deleteTransactionMandateAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IDeleteTransactionMandateResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetTransactionMandateDeletion } =
  transactionMandateDeletionSlice.actions;

export default transactionMandateDeletionSlice.reducer;
