import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface ICustomerLookupRequest {
  customerId: string;
  billerId: string;
  billType?: string;
}

interface ICustomerLookupData {
  name: string;
  referenceId?: string;
  address?: string;
}
interface ICustomerLookupResponse {
  status?: boolean;
  message?: string;
  data?: ICustomerLookupData;
  errors?: IApiResponseError[];
}

const initialState: IApiState<ICustomerLookupResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const customerLookupAsync = createAsyncThunk(
  'customer/lookup',
  async (
    values: ICustomerLookupRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `BearerToken ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.post(
        'billers/customer-lookup',
        values,
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<ICustomerLookupResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const customerLookupSlice = createSlice({
  name: 'customer/lookup',
  initialState,
  reducers: {
    resetCustomerLookup(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(customerLookupAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(customerLookupAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as ICustomerLookupResponse;
      })
      .addCase(customerLookupAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as ICustomerLookupResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetCustomerLookup } = customerLookupSlice.actions;

export default customerLookupSlice.reducer;
