import { useAppSelector } from '../store/hooks';

export default function useGetBusinessDetails() {
  const { value } = useAppSelector((state) => state.auth);
  const { value: businessValue } = useAppSelector(
    (state) => state.userBusinesses
  );

  const businessIdFromStorage = localStorage.getItem('businessId') ?? '';

  const getBusinessDetailsByBusinessIdFromAuth =
    value?.data?.user?.businesses?.find(
      (business) => business?.id === businessIdFromStorage
    );

  const getBusinessDetailsByBusinessIdFromBusiness = businessValue?.data?.find(
    (business) => business?.id === businessIdFromStorage
  );

  return (
    getBusinessDetailsByBusinessIdFromAuth ??
    getBusinessDetailsByBusinessIdFromBusiness
  );
}
