import dayjs from 'dayjs';

import { DAYS, MONTHS } from '../constants/dateConstants';

/**
 * Formats milliseconds to time in mm:ss format
 * @param {number} timeInMs The number of milliseconds
 * @returns {string} formatted time in mm:ss
 */
export const formatMilliSecondsToMmSs = (timeInMs: number): string => {
  const minutes = parseInt(String((timeInMs / (1000 * 60)) % 60));
  const seconds = parseInt(String((timeInMs / 1000) % 60));

  // format time to mm:ss
  const mmMinutes = Number(minutes) < 10 ? '0' + minutes : minutes;
  const ssSeconds = Number(seconds) < 10 ? '0' + seconds : seconds;

  return `${mmMinutes}:${ssSeconds}`;
};

/**
 * Formats a string to a title-cased string
 * @param {string} value - The string to be formatted.
 * @returns {string} The Title Cased String.
 */
export const getTitleCase = (value?: string) => {
  if (!value) return '';

  return value.replace(
    /\b\w+/g,
    (match) => match.charAt(0).toUpperCase() + match.slice(1).toLowerCase()
  );
};

/**
 * Extracts the last part of a path and converts it to title case.
 * Hyphens in the last part are replaced with spaces.
 * @param {string} path - The path string to extract the last part from.
 * @returns {string} The last part of the path in title case.
 */
export const getLastPathTitle = (path: string): string => {
  // Remove leading and trailing slashes
  path = path.replace(/^(\/|\/$)/g, '');

  // Split the path into parts using slashes
  const parts = path.split('/');

  // Get the last part of the path
  const lastPart = parts[parts.length - 1];

  // Convert hyphens to spaces
  const lastPartHyphensToSpaces = lastPart.replace(/-/g, ' ');

  return getTitleCase(lastPartHyphensToSpaces);
};

/**
 * Determines the appropriate article ("a" or "an") based on the given word.
 * @param {string} word - The word to determine the article for.
 * @returns {string} The article ("a" or "an").
 */
export const getArticle = (word: string): string => {
  const startsWithVowel = /^[aeiou]/i.test(word);
  return startsWithVowel ? 'an' : 'a';
};

/**
 * Formats a number to various representations.
 * @param num - The number to format.
 * @returns An object containing the formatted representations of the number.
 */
export const formatNumber = (num: number | string | undefined) => {
  // Convert the input to a valid number
  const parsedNum = parseFloat(num as string);

  if (isNaN(parsedNum)) {
    // Return 0 for invalid inputs
    return {
      nairaString: '₦0',
      nairaStringWithKobo: '₦0.00',
      koboValue: '00',
      formattedString: '0',
      formattedStringWithKobo: '0.00',
      formattedStringWithLeadingZero: '0',
      nairaValue: '0',
      truncatedFormattedString: '0',
    };
  }

  // Format the number to nairaString (1000 -> ₦1,000)
  const nairaString = `₦${parsedNum.toLocaleString()}`;

  // Format the number to nairaStringWithKobo (1000 -> ₦1,000.00)
  const nairaStringWithKobo = parsedNum.toLocaleString('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Format the number to formattedString (1000 -> 1,000)
  const formattedString = parsedNum.toLocaleString();

  const nairaValue = Math.floor(parsedNum).toLocaleString();

  // Format the number to formattedStringWithKobo (1000 -> 1,000.00)
  const formattedStringWithKobo = parsedNum.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Get the kobo value (1000 -> 00 or 100.32 -> 32)
  const numParts = parsedNum.toFixed(2).split('.');
  const koboValue = numParts[1];

  const formattedStringWithLeadingZero =
    parsedNum < 10 ? `0${parsedNum}` : parsedNum.toString();

  // Format the number to a whole number (truncated) formattedString
  const truncatedFormattedString = Math.trunc(parsedNum).toLocaleString();

  return {
    nairaString,
    nairaStringWithKobo,
    nairaValue,
    koboValue,
    formattedString,
    formattedStringWithKobo,
    formattedStringWithLeadingZero,
    truncatedFormattedString,
  };
};

/**
 * Formats a date into the "DD MMM, YYYY" format.
 * @param {Date | string | number} date - The date to format.
 * @returns {string} The formatted date string, or an empty string if the input is not a valid date.
 */
export const formatDate = (date?: Date | string | number): string => {
  if (!date) return '';

  const parsedDate = new Date(date);

  if (!(parsedDate instanceof Date) || isNaN(parsedDate.getTime())) {
    return ''; // Return empty string for invalid or empty input
  }

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  };
  return parsedDate.toLocaleDateString('en-US', options);
};

/**
 * Formats a date into the "DD MMM, YYYY" format.
 * @param {Date | string | number} date - The date to format.
 * @returns {string} The formatted date string, or an empty string if the input is not a valid date.
 */
export const formatDateAndTime = (date?: Date | string | number): string => {
  if (!date) return '';

  const parsedDate = new Date(date);

  if (!(parsedDate instanceof Date) || isNaN(parsedDate.getTime())) {
    return ''; // Return empty string for invalid or empty input
  }

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  };
  return `${parsedDate.toLocaleDateString('en-US', options)} - ${new Date(date)
    .toLocaleTimeString()
    .substring(0, 5)}`;
};

/**
 * Parses a phone number in the format of +234xxxxxxxx or 0xxxxxxxx to 234xxxxxx which is expected on the backend
 * @param {string} phoneNumber
 * @returns {string} The parsed phone number
 */
export const parsePhoneNumber = (phoneNumber: string): string => {
  let parsedNumber = phoneNumber.trim();

  // Remove leading "+" or "0" if present
  if (parsedNumber.startsWith('+')) {
    parsedNumber = parsedNumber.substring(1);
  } else if (parsedNumber.startsWith('0')) {
    parsedNumber = parsedNumber.substring(1);
    parsedNumber = '234' + parsedNumber;
  }

  // Remove any non-digit characters
  parsedNumber = parsedNumber.replace(/\D/g, '');

  return parsedNumber;
};

/**
 * Converts milliseconds to days. Defaults to 1 day(86400000ms)
 * @param {number} [milliseconds=86400000]
 * @returns {string} The milliseconds in days
 */
export const convertMilliSecondsToDays = (milliseconds = 86400000): number => {
  return milliseconds / (1000 * 60 * 60 * 24);
};

/**
 * Calculates the time to send a request for a refresh token by subtracting 5 mins from the original expiration time. Defaults to 1hr25mins
 * @param {number} [expiresInMilliseconds=5400000] The time the token expires in milliseconds. Default is 1hr30mins
 * @returns {string} The refresh token time
 */
export const calculateTokenRefreshTime = (
  expiresInMilliseconds = 5400000
): number => {
  // Add Date.now() and Subtract 5 minutes from the expiration time to give time for network requests
  const refreshTime = Date.now() + (expiresInMilliseconds - 5 * 60 * 1000);

  return refreshTime;
};

/**
 * Converts a File object to base64 data.
 *
 * @param {File | null} file - The File object to convert.
 * @returns {Promise<string>} A promise that resolves with the base64 data.
 * @throws {Error} If an error occurs during the conversion.
 */
export const convertFileToBase64 = (file: File | null): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!file) {
      resolve('invalid file');
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result as string;
      resolve(base64Data);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

/**
 * Converts a date object to a string in YYYY-MM-DD format
 * @param {Date} date - The date to convert
 * @returns {string} The date string in YYYY-MM-DD format
 */
export const formatDateToYYYYMMDD = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

/**
 * Gets the date one year before the input date
 * @param {Date} date - The input date
 * @returns {Date} The date one year before the input date
 */
const getOneYearBeforeDate = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setFullYear(date.getFullYear() - 1);

  return newDate;
};

/**
 * Gets the date one year after the input date
 * @param {Date} date - The input date
 * @returns {Date} The date one year after the input date
 */
const getOneYearAfterDate = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setFullYear(date.getFullYear() + 1);

  return newDate;
};

/**
 * Gets the current date (today) in YYYY-MM-DD format
 * @returns {string} The date string in YYYY-MM-DD format
 */
export const getTodayDateInYYYYMMDD = (): string => {
  const today = new Date();

  return formatDateToYYYYMMDD(today);
};

/**
 * Gets the date a year before today in YYYY-MM-DD format
 * @returns {string} The date string in YYYY-MM-DD format
 */
export const getTheDateAYearBeforeToday = (): string => {
  const today = new Date();
  const oneYearBeforeToday = getOneYearBeforeDate(today);

  return formatDateToYYYYMMDD(oneYearBeforeToday);
};

/**
 * Gets the date a year after today in YYYY-MM-DD format
 * @returns {string} The date string in YYYY-MM-DD format
 */
export const getDateAYearAfterToday = (): string => {
  const today = new Date();
  const oneYearFromToday = getOneYearAfterDate(today);

  return formatDateToYYYYMMDD(oneYearFromToday);
};

/**
 * Gets the date a specific number of days before the input date
 * @param {Date} date - The input date
 * @param {number} days - The number of days to subtract. To add days, pass a negative value.
 * @returns {Date} The date `days` before the input date
 */
export const getDateForDaysBeforeDate = (date: Date, days: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() - days);

  return newDate;
};

/**
 * Gets the date 90 days before today in YYYY-MM-DD format
 * @returns {string} The date string in YYYY-MM-DD format
 */
export const getNinetyDaysBeforeToday = (): string => {
  const today = new Date();
  const ninetyDaysBeforeToday = getDateForDaysBeforeDate(today, 90);

  return formatDateToYYYYMMDD(ninetyDaysBeforeToday);
};

/**
 * Formats a number to a currency representation using the Intl.NumberFormat API. Currency is in naira.
 * @param {number} value - The value to format
 * @param {number} [divideBy=1] - The value to divide the value by. Default is 1.
 * @returns {string} The formatted currency string in Nigerian Naira.
 */
export const currencyFormat = (value: number, divideBy = 1) =>
  new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(
    value / divideBy
  );

/**
Formats a number to a currency string with a specified maximum of 2 decimal places
@param {number} value - The value to format
@returns {string} - The formatted currency string which is either a whole number or a decimal place value.
*/
export const currencyFormatWithDecimals = (value: number) =>
  new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 0,
    maximumFractionDigits: value % 1 === 0 ? 0 : 2, // Show 0 decimal places if the value is a whole number, otherwise show 2 decimal places
  }).format(value);

/**
Formats a number to a formatted string with a specified maximum of 2 decimal places
@param {number} value - The value to format
@returns {string} - The formatted currency string which is either a whole number or a decimal place value.
*/
export const numberFormatWithDecimals = (value: number) =>
  new Intl.NumberFormat('en-NG', {
    minimumFractionDigits: 0,
    maximumFractionDigits: value % 1 === 0 ? 0 : 2, // Show 0 decimal places if the value is a whole number, otherwise show 2 decimal places
  }).format(value);

/**
 * Unformats a currency string representation to a number.
 * @param {string} value - The currency string to unformat
 * @returns {number} The unformatted number value
 */
export const currencyUnformat = (value: string): number => {
  const numericValue = value.replace(/[^.\d]+/g, '');

  if (numericValue === '.') {
    return 0;
  }

  return Number(numericValue);
};

/**
 * Converts the amount from kobo to naira and kobo format.
 * @param {number} kobo - The amount in kobo.
 * @returns {string} The converted amount in naira and kobo format.
 */
export const formatKoboToNairaAndKobo = (kobo: number): string => {
  const naira = kobo / 100;
  return naira.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

/**
 * Gets the current time in the format: "h:mm a".
 * @returns {string} The current time in "h:mm a" format.
 */
export const getCurrentTimeFormatted = (): string => {
  const currentTime = new Date();
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';

  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes}${ampm}`;
};

/**
 * Converts 12-hour time format to 24-hour format.
 * @param time - Time in 12-hour format (e.g., "3:00pm").
 * @returns Time in 24-hour format (e.g., "15:00").
 */
export const convertTo24HourFormat = (time: string): string => {
  const [timePart, period] = time.split(/(am|pm)/);
  const [hourString, minuteString] = timePart.split(':');
  const hours = parseInt(hourString, 10);
  const minutes = parseInt(minuteString, 10);

  let convertedHours = hours;

  if (period === 'pm' && hours !== 12) convertedHours += 12;
  if (period === 'am' && hours === 12) convertedHours = 0;

  return `${convertedHours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
};

/**
 * Sorts an array of bundles based on their names.
 * Bundles are sorted first by unit (MB before GB), then numerically (ascending order).
 * @param {string[]} bundles - The array of bundles to be sorted.
 * @returns {string[]} The sorted array of bundles.
 */
export function sortBundles(bundles: string[]): string[] {
  return bundles.sort((a: string, b: string) => {
    const regex = /(\d+(\.\d+)?)\s*(M?B|GB)?/;

    const matchA = a.match(regex) || [];
    const matchB = b.match(regex) || [];

    const valueA = parseFloat(matchA[1] || '0');
    const unitA = matchA[3] || '';
    const valueB = parseFloat(matchB[1] || '0');
    const unitB = matchB[3] || '';

    if (unitA === unitB) {
      return valueA - valueB;
    } else {
      return unitA === 'MB' ? -1 : 1;
    }
  });
}

/**
 * Uses the clipboard api to copy text
 * @param {string} textToCopy
 */
export const copyToClipboard = async (textToCopy: string) => {
  try {
    await navigator.clipboard.writeText(textToCopy);
  } catch (error) {
    console.error('Failed to copy text: ', error);
  }
};

/**
 * Formats an airport name. Checks if airport is available in the string passed
 * @param {string} airportName
 */
export const formatAirportName = (airportName?: string) => {
  if (!airportName) return '';

  if (airportName.toLowerCase().includes('airport')) return airportName;

  return `${airportName} Airport`;
};

/**
 * Checks if current host is production, then formats airport label based on the condition
 * @param airportName Airport name
 * @param airportIata Airport IATA
 * @returns formatted Airport Label
 */
export const getAirportLabel = (airportName?: string, airportIata?: string) =>
  `${formatAirportName(airportName)} ${
    isCurrentHostProd() ? '' : `(${airportIata?.toUpperCase()})`
  }`;

/**
 * Function to convert a date string to the format "Day. Month DayOfMonth"
 * @param {string} dateString - The date string to be formatted (in ISO 8601 format)
 * @returns {string} The formatted date string
 */
export const formatDateToDayMonth = (dateString?: string): string => {
  if (!dateString) return '';

  const date = new Date(dateString);

  // Get the month, day, and day of the week
  const month: string = MONTHS[date.getMonth()];
  const day: number = date.getDate();
  const dayOfWeek: string = DAYS[date.getDay()];

  // Format the date string
  const formattedDateString = `${dayOfWeek}. ${month} ${day}`;
  return formattedDateString;
};

/**
 * Formats flight duration into hours and minutes or days
 * @param {string} duration
 */
export const formatFlightDuration = (duration?: string) => {
  if (!duration) return '';

  const durationArray = duration?.split(':') ?? [];

  if (durationArray[0].includes('.')) {
    const daySplit = durationArray[0].split('.');

    return `${daySplit[0]}${daySplit[0] === '1' ? 'day' : 'days'} ${
      daySplit[1]
    }hr ${durationArray?.[1] ?? '00'}min`;
  } else {
    return `${durationArray?.[0] ?? '00'}hr ${durationArray?.[1] ?? '00'}min`;
  }
};

export const getDollarRateFromNaira = ({
  dollar,
  naira,
}: {
  dollar: number;
  naira: number;
}) => {
  return naira / dollar;
};

/**
 *
 * @param arr An array of strings
 * @returns the duplicates in an array of strings
 */

// Helper function to find duplicates in an array
export const findDuplicates = (arr: string[]) => {
  const counts: { [key: string]: number } = {};
  const duplicates: string[] = [];

  for (const item of arr) {
    counts[item] = counts[item] ? counts[item] + 1 : 1;
    if (counts[item] > 1 && !duplicates.includes(item)) {
      duplicates.push(item);
    }
  }

  return duplicates;
};

/**
 * Checks if hostname includes 'business' i.e. production
 * @returns {boolean} true if the current host is production
 */
export const isCurrentHostProd = () => {
  if (window.location.hostname.includes('business')) {
    return true;
  } else {
    return false;
  }
};

/**
 * Converts an array of objects into CSV format and triggers a download.
 *
 * @param {Object[]} data - The data to export. Each object represents a row in the CSV.
 * @param {string} filename - The name of the file to be downloaded, including the .csv extension.
 */
export const exportToCSV = (
  data: Record<string, any>[],
  filename: string
): void => {
  if (data.length === 0) {
    console.error('No data to export');
    return;
  }

  const csvRows: string[] = [];
  const headers = Object.keys(data[0]);

  // Convert camel case to user-friendly labels
  const camelToTitleCase = (str: string): string => {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, str[0].toUpperCase());
  };

  const mappedHeaders = headers.map(camelToTitleCase);
  csvRows.push(mappedHeaders.join(','));

  // Format each row of data
  for (const row of data) {
    const values = headers.map((header) => {
      const escaped = ('' + row[header]).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(','));
  }

  // Create a CSV Blob and trigger a download
  const csvBlob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
  const csvUrl = URL.createObjectURL(csvBlob);
  const link = document.createElement('a');
  link.href = csvUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Validates whether the provided URL is valid and uses the HTTP or HTTPS protocol.
 *
 * @param {string} url - The URL to validate.
 * @returns {boolean} - Returns `true` if the URL is valid and uses HTTP or HTTPS, otherwise `false`.
 */
export const validateUrl = (url: string): boolean => {
  const urlPattern =
    /^(https?:\/\/)([\w-]+(\.[\w-]+)+)(:\d+)?(\/[\w\-./?%&=]*)?$/i;
  return urlPattern.test(url);
};

/**
 * Extracts initials from a full name.
 *
 * This function takes a string representing a full name, splits it by spaces, and returns the initials of the first and last names. If the name has only one part, it will use just that part's initial.
 * If the name is undefined, null, or an empty string, it will return an empty string.
 *
 * @param {string} name - The full name from which to extract initials.
 * @returns {string} The initials of the name or an empty string if the name is not valid.
 *
 */
export function getInitials(name: string | undefined | null): string {
  if (!name || typeof name !== 'string') {
    return '';
  }

  const beforeSpecialChar = name.split(/[^a-zA-Z\s]/)[0].trim();

  const nameParts = beforeSpecialChar.split(/\s+/);

  if (nameParts.length === 1) {
    return nameParts[0].charAt(0).toUpperCase();
  } else {
    const firstInitial = nameParts[0].charAt(0).toUpperCase();
    const lastInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();
    return `${firstInitial}${lastInitial}`;
  }
}

/**
 * Converts a date string to a datetime string with a specific time.
 *
 * @param date - The date string in 'YYYY-MM-DD' format.
 * @param time - The time string in 'HH:MM:SS' format.
 * @returns A datetime string in 'YYYY-MM-DDTHH:MM:SS' format.
 */
export function convertToDateTime(date: string, time: string): string {
  if (!date || !time) {
    throw new Error('Both date and time must be provided.');
  }

  const formattedDate = new Date(date).toISOString().split('T')[0];

  const [hours, minutes = '00', seconds = '00'] = time.split(':');
  const formattedTime = `${hours.padStart(2, '0')}:${minutes.padStart(
    2,
    '0'
  )}:${seconds.padStart(2, '0')}`;

  return `${formattedDate}T${formattedTime}`;
}
/**
 * Concat a sentence string to a shorter form.
 *
 * @param sentence - The sentence string you want to cut.
 * @param num - The number you want to cut the sentence by.
 * @returns A shorter sentence.
 */
export const formatStringToSeeMore = (
  sentence: string,
  num: number
): string => {
  const words = sentence.split(' ', num);
  const formattedSentence = words.map((word, index) => {
    if (index === words.length - 1) {
      return word;
    }
    return `${word}`;
  });
  return formattedSentence.join(' ');
};

/**
 * Extracts and formats the time from a given ISO 8601 date string.
 *
 * @param {Date | string | number} [isoDateString] - The ISO 8601 date string, Date object, or timestamp (e.g., "2024-08-22T02:00:00.000Z").
 * @returns {string} The formatted time in 12-hour format (e.g., "2:00 AM" or "2:00 PM").
 * @throws {Error} If the input is not a valid date.
 */
export const getFormattedTime = (
  isoDateString?: Date | string | number
): string => {
  try {
    if (isoDateString === undefined) {
      throw new Error('Date input is undefined');
    }

    const date = new Date(isoDateString);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }

    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12; // Convert to 12-hour format

    return `${hours}:${minutes} ${period}`;
  } catch (error) {
    throw new Error('Invalid date input');
  }
};
