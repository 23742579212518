import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiState } from '../../../types/api';
import { IState } from '../../../types/location';

interface IStatesResponse {
  status: true;
  message: string;
  data: IState[];
}

const statesFromStorage = localStorage.getItem('states');
const parsedStatesFromStorage = statesFromStorage
  ? JSON.parse(statesFromStorage)
  : null;

const initialState: IApiState<IStatesResponse> = {
  value: parsedStatesFromStorage,
  status: 'idle',
  error: '',
};

export const getStatesAsync = createAsyncThunk(
  'location/states',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { data } = await api.get('/states');

      localStorage.setItem('states', JSON.stringify(data));

      return data;
    } catch (err) {
      const error = err as AxiosError<IStatesResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const statesFetch = createSlice({
  name: 'location/states',
  initialState,
  reducers: {
    resetStates: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';

      localStorage.removeItem('states');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStatesAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getStatesAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IStatesResponse;
      })
      .addCase(getStatesAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IStatesResponse;
        state.error = action.error.message;
      });
  },
});

export default statesFetch.reducer;
