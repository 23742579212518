import { Box, Flex, IconButton, Input, Text } from '@chakra-ui/react';
import { useState } from 'react';

import { CloudArrowUpIcon, MiniDocumentTextIcon, TrashIcon } from '../../icons';

interface CustomFileUploaderProps {
  onChange: (value: File) => void;
  fieldName: string;
  value?: File | null;
  placeholder?: string;
  accept?: string;
  isInvalid?: boolean;
  setFieldTouched: (
    field: string,
    touched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => unknown;
}

export default function CustomFileInput({
  onChange,
  fieldName,
  value,
  setFieldTouched,
  placeholder = 'Upload Document',
  accept = 'image/*',
  isInvalid = false,
}: CustomFileUploaderProps) {
  const [file, setFile] = useState<File | null | undefined>(value);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];

    if (uploadedFile) {
      setFile(uploadedFile);
      onChange(uploadedFile);
    }
  };

  const handleFileInputClick = () => {
    setFieldTouched(fieldName, true);
  };

  const handleRemoveFile = () => {
    try {
      setFile(null);
    } catch (e) {
      // Do nothing
    }
  };

  let borderStyle = '1px dashed #7B828E';

  if (isInvalid) {
    borderStyle = '1px solid #db4343';
  } else if (file) {
    borderStyle = '1px solid #656B76';
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      padding="8px 24px"
      border={borderStyle}
      borderRadius="14px"
      height="56px"
    >
      <label
        htmlFor={`file-input-${fieldName}`}
        style={{
          display: 'block',
          textAlign: file ? 'left' : 'center',
          width: '100%',
          cursor: 'pointer',
        }}
      >
        {file ? (
          <Flex as="span" justifyContent="space-between" alignItems="center">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <MiniDocumentTextIcon />
              <Box as="span" display="flex" flexDirection="column" gap={1}>
                <Text
                  fontWeight={400}
                  fontSize=".75rem"
                  lineHeight="150%"
                  letterSpacing="-0.12px"
                  color="gray.500"
                >
                  {file?.name}
                </Text>
                <Text
                  fontSize=".5rem"
                  fontStyle="italic"
                  fontWeight={400}
                  lineHeight="100%"
                  color="#7b828e"
                >
                  Uploaded
                </Text>
              </Box>
            </Box>

            <IconButton
              icon={<TrashIcon />}
              onClick={() => {
                handleRemoveFile();
              }}
              aria-label="Remove File"
              variant="ghost"
              boxSize="24px"
              _hover={{ background: 'transparent' }}
              sx={{ mr: -3 }}
            />
          </Flex>
        ) : (
          <Flex as="span" alignItems="center" justifyContent="center" gap={2}>
            <CloudArrowUpIcon />
            <Text
              fontWeight={400}
              fontSize=".875rem"
              lineHeight="150%"
              letterSpacing="-0.12px"
              color="#7B828E"
            >
              {placeholder}
            </Text>
          </Flex>
        )}
      </label>
      <Input
        id={`file-input-${fieldName}`}
        type="file"
        onChange={handleFileChange}
        onClick={handleFileInputClick}
        accept={accept}
        hidden
      />
    </Box>
  );
}
