import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { getTransactionMandatesAsync } from './getTransactionMandates';

interface ICreateTransactionMandateRequest {
  name: string;
  categoryId: string;
  minimumAmount: number;
  pin: string;
  amounts: {
    teamMemberIds: string[];
    maximumAmount: number;
  }[];
}

interface ICreateTransactionMandateResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    businessId?: string;
    categoryId?: string;
    name?: string;
    minimumAmount?: string;
    isActive?: boolean;
    amounts?: {
      id?: string;
      approvers?: {
        id?: string;
        createdAt?: string;
        updatedAt?: string;
        isActive?: boolean;
      }[];
      maximumAmount?: string;
      mandateId?: string;
    }[];
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<ICreateTransactionMandateResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const createTransactionMandateAsync = createAsyncThunk(
  'transactionMandate/create',
  async (
    values: ICreateTransactionMandateRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const businessId =
        localStorage.getItem('businessId') ||
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.post('/transaction/mandates', values, config);

      dispatch(getTransactionMandatesAsync());

      return data;
    } catch (err) {
      const error = err as AxiosError<ICreateTransactionMandateResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const transactionMandateCreationSlice = createSlice({
  name: 'transactionMandate/create',
  initialState,
  reducers: {
    resetTransactionMandateCreation(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTransactionMandateAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(createTransactionMandateAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as ICreateTransactionMandateResponse;
      })
      .addCase(createTransactionMandateAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as ICreateTransactionMandateResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetTransactionMandateCreation } =
  transactionMandateCreationSlice.actions;
export default transactionMandateCreationSlice.reducer;
