import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface INameEnquriyRequest {
  accountNumber: string;
  bankCode: string;
}

export interface INameEnquiryResponse {
  success?: boolean;
  message?: string;
  data?: {
    accountName: string;
    accountNumber: string;
    bankCode: string;
    sessionId: string;
    transferFee: number;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<INameEnquiryResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const nameEnquiryAsync = createAsyncThunk(
  'nameEnquiry/fetch',
  async (
    { accountNumber, bankCode }: INameEnquriyRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };
      const { data } = await api.post<INameEnquiryResponse>(
        '/name-enquiry',
        {
          accountNumber,
          bankCode,
        },
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<INameEnquiryResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const nameEnquireSlice = createSlice({
  name: 'nameEnquiry/get',
  initialState,
  reducers: {
    resetNameEnquiry: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(nameEnquiryAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(nameEnquiryAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as INameEnquiryResponse;
      })
      .addCase(nameEnquiryAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as INameEnquiryResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetNameEnquiry } = nameEnquireSlice.actions;

export default nameEnquireSlice.reducer;
