/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, Button, Image, Text } from '@chakra-ui/react';
import { useRef, useState } from 'react';

import InProgressImage from '../../assets/images/in-progress-image.svg';
import VideoVerificationImage from '../../assets/images/VideoVerificationImg.svg';
import {
  CustomModal,
  CustomModalFooter,
  MetaMapVideoVerification,
  PrimaryButton,
} from '../../components/custom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { signOut } from '../../store/slices/auth/signOutSlice';

export default function LivenessVerificationStatus() {
  const btnRef = useRef<HTMLButtonElement>(null);

  const { value } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const verificationStatus = value?.data?.user?.livenessVerificationStatus;
  const requiresLivenessVerification =
    value?.data?.user?.requiresLivenessVerification;

  const verificationRetakenFromSession = localStorage.getItem(
    'verificationRetaken'
  );

  const [verificationRetaken, setVerificationRetaken] = useState(
    verificationRetakenFromSession
  );

  const completedVerificationCallback = () => {
    setVerificationRetaken('yes');

    localStorage.setItem('verificationRetaken', 'yes');
  };

  return (
    <CustomModal
      blurOverlay
      hideCloseButton
      isOpen={
        !!(requiresLivenessVerification && verificationStatus !== 'approved')
      }
      onClose={() => {}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      btnRef={btnRef}
    >
      {verificationRetaken ? (
        <>
          <Box pb={8}>
            <Image
              src={InProgressImage}
              alt="Identity Revalidation"
              boxSize="300px"
              marginX="auto"
            />
            <Text color="gray.500" textAlign="center">
              Your Identity Verification has been re-captured. Please check back
              within a few minutes or contact us.
            </Text>
          </Box>

          <CustomModalFooter height="">
            <PrimaryButton isDisabled w="full" textTransform="capitalize">
              Revalidating
            </PrimaryButton>

            <Button
              w="full"
              variant="unstyled"
              onClick={handleSignOut}
              fontWeight={400}
            >
              Sign Out
            </Button>
          </CustomModalFooter>
        </>
      ) : (
        <>
          <Box pb={8}>
            <Image
              src={VideoVerificationImage}
              alt="Video verification"
              boxSize="300px"
              marginX="auto"
            />
            {verificationStatus === 'rejected' ? (
              <Text color="gray.500" textAlign="center">
                Your Identity Verification was rejected. Kindly retry your
                verification or contact us.
              </Text>
            ) : (
              <Text color="gray.500" textAlign="center">
                Your Identity Verification is {verificationStatus}. Please check
                back within a few minutes or contact us.
              </Text>
            )}
          </Box>

          <CustomModalFooter height="">
            {verificationStatus === 'rejected' ? (
              <MetaMapVideoVerification
                completedVerificationCallback={completedVerificationCallback}
                email={value?.data?.user?.email}
              />
            ) : (
              <PrimaryButton isDisabled w="full" textTransform="capitalize">
                {verificationStatus ?? 'Processing'}
              </PrimaryButton>
            )}

            <Button
              w="full"
              variant="unstyled"
              onClick={handleSignOut}
              fontWeight={400}
            >
              Sign Out
            </Button>
          </CustomModalFooter>
        </>
      )}
    </CustomModal>
  );
}
