import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { getScheduledTransfersAsync } from './getScheduledTransfersSlice';

interface IResumeScheduledTransferResponse {
  status?: boolean;
  message?: string;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IResumeScheduledTransferResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const resumeScheduledTransferAsync = createAsyncThunk(
  'scheduledTransfer/resume',
  async (id: string, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId =
        localStorage.getItem('businessId') ||
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      setTimeout(() => {
        // window.location.reload();
        dispatch(getScheduledTransfersAsync());
      }, 2500);

      const { data } = await api.put<IResumeScheduledTransferResponse>(
        `/transfers/scheduled/${id}/resume`,
        {},
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IResumeScheduledTransferResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const scheduledTransferResumeSlice = createSlice({
  name: 'scheduledTransfer/resume',
  initialState,
  reducers: {
    resetScheduledTransferResume(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resumeScheduledTransferAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(resumeScheduledTransferAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IResumeScheduledTransferResponse;
      })
      .addCase(resumeScheduledTransferAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IResumeScheduledTransferResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetScheduledTransferResume } =
  scheduledTransferResumeSlice.actions;

export default scheduledTransferResumeSlice.reducer;
