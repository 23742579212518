import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "../../../api/axios";
import { IApiState } from "../../../types/api";

interface IGetPhoneVerificationByIdValues {
  phoneNumber: string;
  id: string;
}

interface IGetPhoneVerificationResponse {
  success?: boolean;
}

const initialState: IApiState<IGetPhoneVerificationResponse> = {
  value: null,
  status: "idle",
  error: "",
};

export const getPhoneVerificationByIdAsync = createAsyncThunk(
  "phoneVerification/verify",
  async (
    { phoneNumber, id }: IGetPhoneVerificationByIdValues,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.post(`/verification/phonenumber/${id}`, {
        identifier: phoneNumber,
      });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const phoneVerificationGetByIdSlice = createSlice({
  name: "phoneVerification/getById",
  initialState,
  reducers: {
    resetPhoneVerificationGetById: (state) => {
      state.value = null;
      state.status = "idle";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPhoneVerificationByIdAsync.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getPhoneVerificationByIdAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.value = action.payload as IGetPhoneVerificationResponse;
      })
      .addCase(getPhoneVerificationByIdAsync.rejected, (state, action) => {
        state.status = "failed";
        state.value = action.payload as IGetPhoneVerificationResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetPhoneVerificationGetById } =
  phoneVerificationGetByIdSlice.actions;

export default phoneVerificationGetByIdSlice.reducer;
