import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IAggregatorMerchant } from '../../../types/aggregator';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IGetAggregatorMerchantsRequest {
  page?: number;
}

interface IGetAggregatorMerchantsResponse {
  status?: boolean;
  message?: string;
  data?: IAggregatorMerchant[];
  meta?: {
    total?: number;
    perPage?: number;
    totalPages?: number;
    page?: number;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetAggregatorMerchantsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getAggregatorMerchantsAsync = createAsyncThunk(
  'aggregatorMerchants',
  async (
    { page = 1 }: IGetAggregatorMerchantsRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get(
        `/aggregators/merchants?page=${page}`,
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const aggregatorMerchantsGetSlice = createSlice({
  name: 'aggregatorMerchants/get',
  initialState,
  reducers: {
    resetGetAggregatorMerchants: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAggregatorMerchantsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getAggregatorMerchantsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetAggregatorMerchantsResponse;
      })
      .addCase(getAggregatorMerchantsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetAggregatorMerchantsResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetAggregatorMerchants } =
  aggregatorMerchantsGetSlice.actions;

export default aggregatorMerchantsGetSlice.reducer;
