import { Button, ButtonProps } from '@chakra-ui/react';

interface SecondaryButtonProps extends ButtonProps {
  children: string;
  sx?: React.CSSProperties;
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  children,
  sx,
  ...rest
}) => {
  return (
    <Button
      sx={{
        background: 'ash.200',
        height: '48px',
        borderRadius: '12px',
        color: 'gray.500',
        fontWeight: '400',
        lineHeight: '23px',
        letterSpacing: '-0.12px',
        '&:disabled': {
          color: 'ash.600',
          opacity: 0.8,
        },
        '&:hover': {
          background: 'ash.400',
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default SecondaryButton;
