import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IGetCountryByIdRequest {
  countryId: string;
}

export interface IGetCountryByIdResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    name?: string;
    code?: string;
    created_at?: string;
    updated_at?: string;
  };
  code?: number;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetCountryByIdResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getCountryByIdAsync = createAsyncThunk(
  'giftCards/countries',
  async (
    { countryId }: IGetCountryByIdRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get(`/gift-card/country/${countryId}`, config);

      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const countriesGetSlice = createSlice({
  name: 'giftCards/countries/getById',
  initialState,
  reducers: {
    resetGetCountryById: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountryByIdAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getCountryByIdAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetCountryByIdResponse;
      })
      .addCase(getCountryByIdAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetCountryByIdResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetCountryById } = countriesGetSlice.actions;

export default countriesGetSlice.reducer;
