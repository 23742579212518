import { track } from '@iterable/web-sdk';

import { useInitializeIterable } from './useInitializeIterable';
// import { InAppEventRequestParams } from './types';

export const useTrackEvent = () => {
  const initializer = useInitializeIterable();

  const trackEvent = ({
    eventName,
    dataFields,
    userEmailFromParams,
  }: {
    eventName: string;
    dataFields: object;
    userEmailFromParams?: string;
  }) => {
    initializer(userEmailFromParams)
      .then((res) => {
        track({
          eventName: eventName,
          dataFields: dataFields,
        })
          .then((res) => {
            // Do nothing
          })
          .catch((err) => {
            //  Do nothing
          });
      })
      .catch((err) => {
        // Do nothing
      });
  };

  return trackEvent;
};
