import { Button, ButtonProps, Tooltip } from '@chakra-ui/react';

interface DashboardButtonProps extends ButtonProps {
  info?: string | null;
}

export default function DashboardButton({
  info,
  ...props
}: DashboardButtonProps) {
  return (
    <Tooltip label={info} hasArrow sx={{ display: info ? 'inherit' : 'none' }}>
      <Button
        w="full"
        colorScheme="green"
        p="14px 12px 14px 8px"
        borderRadius="12px"
        height="48px"
        fontWeight={400}
        fontSize="1rem"
        lineHeight="125%"
        textAlign="center"
        letterSpacing="0.2px"
        color="#fff"
        {...props}
      >
        {props.children}
      </Button>
    </Tooltip>
  );
}
