import { Box, Button, Grid, GridItem, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import ApprovalImg from '../../../../../assets/images/approval-cropped-img.svg';
import { NotificationDotIcon } from '../../../../../components/icons';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { getPendingTransactionsForApprovalAsync } from '../../../../../store/slices/transactionMandate/getPendingTransactionsForApproval';

export default function TransactionsApprovalCard() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { status, value } = useAppSelector(
    (state) => state.getPendingTransactionsForApproval
  );

  const showOnRoutes = ['/app/dashboard'];

  useEffect(() => {
    dispatch(getPendingTransactionsForApprovalAsync());
  }, [dispatch]);

  if (status === 'success' && value?.data?.length) {
    return (
      <Box
        display={
          showOnRoutes.some((route) => location.pathname.includes(route))
            ? 'inherit'
            : 'none'
        }
        borderRadius="16px"
        border="1px solid #ab94f2"
        background="#CDBEFA"
        minH="104px"
        mb="62px"
        position="relative"
      >
        <NotificationDotIcon
          position="absolute"
          top="-10px"
          left={{ base: '18px', lg: '24px' }}
        />

        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }}
          gap={6}
          px={8}
          py={{ base: 4, lg: 'initial' }}
        >
          <GridItem
            ml="12px"
            w="100%"
            position="relative"
            backgroundImage={ApprovalImg}
            backgroundRepeat="no-repeat"
            backgroundSize="contain"
          />
          <GridItem
            w="100%"
            py={{ base: 0, lg: '6' }}
            mt={{ base: '-5', lg: 'initial' }}
          >
            <Text
              fontFamily="RecklessNeue"
              fontSize="1.125rem"
              fontWeight={700}
              lineHeight="2rem"
              mb={{ base: 0, lg: 2 }}
            >
              Transaction Awaiting Approval
            </Text>
            <Text fontSize=".875rem" lineHeight="114.286%">
              An action needs your attention: there's a pending transaction
              awaiting your approval.
            </Text>
          </GridItem>
          <GridItem
            w="100%"
            py={{ base: 0, lg: '2rem' }}
            textAlign="right"
            alignSelf="center"
          >
            <Button
              as={RouterLink}
              to="/app/transactions"
              bg="#001419"
              color="#fff"
              fontSize=".75rem"
              fontWeight={400}
              width={{ base: '100%', lg: 'initial' }}
              borderRadius={{ base: '8px', lg: '32px' }}
              p="12px 24px"
            >
              View All Awaiting
            </Button>
          </GridItem>
        </Grid>
      </Box>
    );
  }

  return null;
}
