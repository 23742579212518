import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';

interface IOnboardExistingBusinessOwnerRequest {
  businessId: string;
  email: string;
}

interface IOnboardExistingBusinessOwnerResponse {
  status?: boolean;
  message?: string;
  data?: unknown;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IOnboardExistingBusinessOwnerResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const onboardExistingBusinessOwnerAsync = createAsyncThunk(
  'onboardBusinessOwner/existing',
  async (values: IOnboardExistingBusinessOwnerRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.post<IOnboardExistingBusinessOwnerResponse>(
        `/onboard-existing-business-owner`,
        values
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IOnboardExistingBusinessOwnerResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const onboardBusinessOwnerCheckSlice = createSlice({
  name: 'onboardBusinessOwner/existing',
  initialState,
  reducers: {
    resetOnboardingExistingOwner(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(onboardExistingBusinessOwnerAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(onboardExistingBusinessOwnerAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IOnboardExistingBusinessOwnerResponse;
      })
      .addCase(onboardExistingBusinessOwnerAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IOnboardExistingBusinessOwnerResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetOnboardingExistingOwner } =
  onboardBusinessOwnerCheckSlice.actions;

export default onboardBusinessOwnerCheckSlice.reducer;
