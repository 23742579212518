import * as Yup from 'yup';

export const firstNameValidation = Yup.string()
  .trim()
  .min(2, 'First Name must be at least 2 characters long')
  .max(30, 'First Name must not exceed 30 characters')
  .matches(/^[A-Za-z'-]+$/, 'Only letters, hyphens, or apostrophes are allowed')
  .test(
    'no-trailing-punctuation',
    'Trailing punctuation is not allowed',
    (value) => {
      if (value?.trim() && /[^A-Za-z]$/.test(value?.trim())) {
        return new Yup.ValidationError(
          'First Name should not end with punctuation',
          null,
          'firstName'
        );
      }
      return true;
    }
  )
  .required('First Name is required');

export const lastNameValidation = Yup.string()
  .trim()
  .min(2, 'Last Name must be at least 2 characters long')
  .max(30, 'Last Name must not exceed 30 characters')
  .matches(/^[A-Za-z'-]+$/, 'Only letters, hyphens, or apostrophes are allowed')
  .test(
    'no-trailing-punctuation',
    'Trailing punctuation is not allowed',
    (value) => {
      if (value && /[^A-Za-z]$/.test(value)) {
        return new Yup.ValidationError(
          'Last Name should not end with punctuation',
          null,
          'lastName'
        );
      }
      return true;
    }
  )
  .required('Last Name is required');

export const middleNameValidation = Yup.string()
  .trim()
  .min(2, 'Middle Name must be at least 2 characters long')
  .max(30, 'Middle Name must not exceed 30 characters')
  .matches(/^[A-Za-z'-]+$/, 'Only letters, hyphens, or apostrophes are allowed')
  .test(
    'no-trailing-punctuation',
    'Trailing punctuation is not allowed',
    (value) => {
      if (value && /[^A-Za-z]$/.test(value)) {
        return new Yup.ValidationError(
          'Middle Name should not end with punctuation',
          null,
          'lastName'
        );
      }
      return true;
    }
  );

export const businessNameValidation = Yup.string()
  .trim()
  .min(5, 'Business Name must be at least 5 characters long')
  .max(50, 'Business Name must not exceed 50 characters')
  .matches(
    /^[A-Za-z0-9 &'-]+$/,
    'Name can only contain letters, numbers, spaces, and hyphens'
  )
  .test(
    'is-not-one-word',
    'Business Name must be more than one word',
    (value) => {
      if (!value) return true; // Skip validation if value is empty
      return value.trim().includes(' '); // Check if value contains at least one space
    }
  )
  .test(
    'no-trailing-punctuation',
    'Trailing punctuation is not allowed',
    (value) => {
      if (value && /[^A-Za-z0-9]$/.test(value)) {
        return new Yup.ValidationError(
          'Business Name should not end with punctuation',
          null,
          'name'
        );
      }
      return true;
    }
  )
  .required('Business Name is required');

export const nameValidation = Yup.string()
  .trim()
  .min(3, 'Name must be at least 3 characters')
  .max(30, 'Name cannot be more than 30 characters')
  .matches(
    /^[A-Za-z'-\s]+$/,
    'Only letters, hyphens, or apostrophes are allowed'
  )
  .test(
    'no-trailing-punctuation',
    'Trailing punctuation is not allowed',
    (value) => {
      if (value?.trim() && /[^A-Za-z]$/.test(value?.trim())) {
        return new Yup.ValidationError(
          'Name should not end with punctuation',
          null,
          'name'
        );
      }
      return true;
    }
  )
  .required('Name is required');

export const emailAddressValidation = Yup.string()
  .trim()
  .email('Please enter a valid email address')
  .required('Email Address is required');

export const phoneNumberValidation = Yup.string()
  .matches(
    /^(?:\+234|0|234)\d{10}$/,
    'Please enter a valid Nigerian phone number'
  )
  .min(11, 'Phone Number cannot be lesser than 11 characters')
  .required('Phone Number is required');

export const zeroOnlyPhoneNumberValidation = Yup.string()
  .matches(/^0\d{10}$/, 'Input valid phone number ')
  .required('Phone Number is required');

export const otpValidation = Yup.string()
  .length(6, 'Please enter a valid OTP')
  .required('OTP is required');

export const passwordValidation = Yup.string()
  .min(8, 'Password must be at least 8 characters long')
  .max(60, 'Password cannot exceed 60 characters')
  .test(
    'uppercase',
    'Password must include at least one uppercase letter',
    (value) => !!value && /[A-Z]/.test(value)
  )
  .test(
    'lowercase',
    'Password must include at least one lowercase letter',
    (value) => !!value && /[a-z]/.test(value)
  )
  .test(
    'specialCharacters',
    'Password must include at least one special character',
    (value) => !!value && /[!@#$%^&*()\-=_+[\]{}|\\;:'",.<>/?]/.test(value)
  )
  .test(
    'number',
    'Password must include at least one number',
    (value) => !!value && /\d/.test(value)
  )
  .test(
    '9jaPay',
    "Password must not include '9jaPay'",
    (value) => !!value && !/9jaPay/i.test(value)
  )
  .required('Password is required');

export const confirmPasswordValidation = Yup.string()
  .oneOf([Yup.ref('password')], 'Passwords must match')
  .nullable()
  .required('Confirm Password is required');

export const passcodeValidation = Yup.string()
  .matches(/^\d{6}$/, 'Passcode must be a 6 digit number')
  .test(
    'non-repeating',
    'Passcode must have at least two different digits',
    (value) => {
      const digitSet = new Set(value);
      return digitSet.size >= 2;
    }
  )
  .required('Passcode is required');

export const confirmPasscodeValidation = Yup.string()
  .test(
    'non-repeating',
    'Passcode must have at least two different digits',
    (value) => {
      const digitSet = new Set(value);
      return digitSet.size >= 2;
    }
  )
  .oneOf([Yup.ref('passcode')], 'Passcodes must match')
  .required('Confirm passcode is required');

export const transactionPinValidation = Yup.string()
  .length(4, 'PIN must be 4 digits')
  .required('PIN is required');

export const confirmTransactionPinValidation = Yup.string()
  .oneOf([Yup.ref('pin')], 'PINs must match')
  .nullable()
  .required('PIN Confirmation is required');

export const addressValidation = Yup.string()
  .min(10, 'Address must be at least 10 characters')
  .max(100, 'Address must not exceed 100 characters')
  .required('Address is required');

export const cityValidation = Yup.string()
  .min(3, 'City Name must be at least 3 characters')
  .max(30, 'City Name must not exceed 30 characters')
  .required('City is required');

export const dateOfBirthValidation = Yup.date()
  .max(new Date(), 'Date of Birth cannot be in the future')
  .test('minAge', 'You must be at least 16 years old', (value) => {
    const currentDate = new Date();
    const selectedDate = new Date(value ?? Date.now());
    const minAgeDate = new Date();
    minAgeDate.setFullYear(currentDate.getFullYear() - 16);

    return selectedDate <= minAgeDate;
  })
  .required('Date of Birth is required');

export const adultDateOfBirthValidation = (
  departureDate: Date,
  returnDate?: Date | null
) =>
  Yup.date()
    .max(new Date(), 'Date of Birth cannot be in the future')
    .test(
      'minAge',
      `Adult must be at least 12 years old ${
        returnDate ? 'by the time of return flight.' : ''
      }`,
      (value) => {
        const selectedDate = new Date(value ?? Date.now());

        if (!(selectedDate instanceof Date)) {
          return false; // Invalid date
        }

        const minAgeMilliseconds = 12 * 365.25 * 24 * 60 * 60 * 1000; // 12 years in milliseconds

        if (returnDate) {
          const returnAgeDiffMilliSeconds =
            returnDate.getTime() - selectedDate.getTime();

          if (returnAgeDiffMilliSeconds < minAgeMilliseconds) {
            return false; // Age is less than 12 years
          }

          const returnDepartureDiffMilliseconds =
            returnDate.getTime() - selectedDate.getTime();
          return returnDepartureDiffMilliseconds >= 0; // Age is at least 12 by return flight
        }

        const departureAgeDiffMilliseconds =
          departureDate.getTime() - selectedDate.getTime();

        if (departureAgeDiffMilliseconds < minAgeMilliseconds) {
          return false; // Age is less than 12 years
        }

        const departureDiffMilliseconds =
          departureDate.getTime() - selectedDate.getTime();
        return departureDiffMilliseconds >= 0; // Age is at least 12 by departure date
      }
    )
    .required('Date of Birth is required');

export const childDateOfBirthValidation = (
  departureDate: Date,
  returnDate?: Date | null
) =>
  Yup.date()
    .max(new Date(), 'Date of Birth cannot be in the future')
    .test(
      'minAge',
      `Child must be at least 2 years old ${
        returnDate ? 'by the time of return flight' : ''
      }`,
      (value) => {
        const selectedDate = new Date(value ?? Date.now());

        if (!(selectedDate instanceof Date)) {
          return false; // Invalid date
        }

        const minAgeMilliseconds = 2 * 365.25 * 24 * 60 * 60 * 1000; // 2 years in milliseconds

        if (returnDate) {
          const returnAgeDiffMilliSeconds =
            returnDate.getTime() - selectedDate.getTime();

          if (returnAgeDiffMilliSeconds < minAgeMilliseconds) {
            return false; // Age is less than 12 years
          }

          const returnDepartureDiffMilliseconds =
            returnDate.getTime() - selectedDate.getTime();
          return returnDepartureDiffMilliseconds >= 0; // Age is at least 2 by return flight
        }

        const departureAgeDiffMilliseconds =
          departureDate.getTime() - selectedDate.getTime();

        if (departureAgeDiffMilliseconds < minAgeMilliseconds) {
          return false; // Age is less than 12 years
        }

        const departureDiffMilliseconds =
          departureDate.getTime() - selectedDate.getTime();
        return departureDiffMilliseconds >= 0; // Age is at least 2 by departure date
      }
    )
    .test(
      'maxAge',
      `Child tickets are only for children below the age of 12 years ${
        returnDate ? 'at the time of return flight' : ''
      }`,
      (value) => {
        const selectedDate = new Date(value ?? Date.now());

        if (!(selectedDate instanceof Date)) {
          return false; // Invalid date
        }

        if (returnDate) {
          const ageAtReturnMilliseconds =
            returnDate.getTime() - selectedDate.getTime();
          const maxAgeMilliseconds = 12 * 365.25 * 24 * 60 * 60 * 1000; // 12 years in milliseconds
          return ageAtReturnMilliseconds < maxAgeMilliseconds;
        } else {
          const ageAtDepartureMilliseconds =
            departureDate.getTime() - selectedDate.getTime();
          const maxAgeMilliseconds = 12 * 365.25 * 24 * 60 * 60 * 1000; // 12 years in milliseconds
          return ageAtDepartureMilliseconds < maxAgeMilliseconds;
        }
      }
    )
    .required('Date of Birth is required');

export const infantDateOfBirthValidation = (
  departureDate: Date,
  returnDate?: Date | null
) =>
  Yup.date()
    .max(new Date(), 'Date of Birth cannot be in the future')
    .test(
      'maxAge',
      `Infant tickets are only for infants below the age of 2 years ${
        returnDate ? 'at the time of return flight' : ''
      }`,
      (value) => {
        const selectedDate = new Date(value ?? Date.now());

        if (!(selectedDate instanceof Date)) {
          return false; // Invalid date
        }

        if (returnDate) {
          const ageAtReturnMilliseconds =
            returnDate.getTime() - selectedDate.getTime();
          const maxAgeMilliseconds = 2 * 365.25 * 24 * 60 * 60 * 1000; // 2 years in milliseconds
          return ageAtReturnMilliseconds < maxAgeMilliseconds;
        } else {
          const ageAtDepartureMilliseconds =
            departureDate.getTime() - selectedDate.getTime();
          const maxAgeMilliseconds = 2 * 365.25 * 24 * 60 * 60 * 1000; // 2 years in milliseconds
          return ageAtDepartureMilliseconds < maxAgeMilliseconds;
        }
      }
    )
    .required('Date of Birth is required');

export const fileValidation = Yup.mixed()
  .required('File is required')
  .test(
    'fileSize',
    'File is too large. Should not be more than 4MB',
    (value) => {
      if (value && value instanceof File) {
        return value.size <= 4 * 1024 * 1024; // 4MB
      }
      return true; // Skip validation if no file is provided or if value is not a File object
    }
  );

// Schema for .docx file validation
export const docxFileValidation = Yup.mixed()
  .required('File is required')
  .test('docxValidation', 'Invalid .docx file', (value) => {
    if (value && value instanceof File) {
      if (
        value.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        // MIME type for .docx
        return true; // Return true if the .docx file meets the criteria
      } else {
        return false; // Return false if the file is not a .docx file
      }
    }
    return true; // Skip validation if no file is provided or if value is not a File object
  });

export const businessCategoryValidation = Yup.string().required(
  'Business Category is required'
);

export const airtimeCategoryValidation = Yup.string().required(
  'Network Category is required'
);

export const tinValidation = Yup.string()
  .trim()
  .min(7, 'TIN must be at least 7 characters')
  .max(14, 'TIN cannot exceed 14 characters')
  .required('TIN is required');

export const cacNumberValidation = Yup.string()
  .trim()
  .min(5, 'CAC Number must be at least 5 characters')
  .max(10, 'CAC Number cannot exceed 10 characters')
  .matches(/^(BN|RC)/, "CAC Number must start with 'BN' or 'RC'")
  .required('CAC Number is required');

export const bvnValidation = Yup.string()
  .matches(/^\d{11}$/, 'BVN must be an 11-digit number')
  .required('BVN is required');

export const ninValidation = Yup.string()
  .matches(/^\d{11}$/, 'NIN must be an 11-digit number')
  .required('NIN is required');

export const scumlRegisteredValidation = Yup.string().required(
  'Please select an answer'
);

export const scumlCertificateValidation = Yup.mixed()
  .test(
    'requiredIfScumlIsRegistered',
    'SCUML File is required',
    function (value) {
      const { scumlRegistered } = this.parent;
      if (scumlRegistered === 'yes') {
        return !!value;
      }
      return true;
    }
  )
  .test(
    'fileSize',
    'File is too large. Should not be more than 2MB',
    (value) => {
      if (value && value instanceof File) {
        return value.size <= 2 * 1024 * 1024; // 2 * 1MB or 2 * 2048KB
      }
      return true; // Skip validation if no file is provided or if value is not a File object
    }
  );

export const amountValidation = Yup.string()
  .test('is-valid-amount', 'Invalid amount format', (value) => {
    if (!value) return false;

    const numericValue = parseFloat(value.replace(/[^0-9.]/g, ''));
    if (isNaN(numericValue)) return false;

    const formattedAmount = numericValue.toFixed(2);
    const parts = formattedAmount.split('.');
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const decimalPart = parts[1];

    return `${integerPart}.${decimalPart}` === value;
  })
  .required('Amount is required');

export const genericStringValidation = Yup.string()
  .trim()
  .max(100, 'Cannot exceed 100 characters');

export const handleNumericInput = (
  e: React.KeyboardEvent<HTMLInputElement>,
  limit: number
) => {
  const inputValue = e.currentTarget.value;
  const isBackspace = e.key === 'Backspace' || e.key === 'Delete';

  if (
    !(
      (
        (e.key >= '0' && e.key <= '9') ||
        isBackspace ||
        e.key === 'Tab' ||
        e.key === 'ArrowLeft' ||
        e.key === 'ArrowRight' ||
        (e.key === 'v' && e.metaKey)
      ) // Allow paste with Ctrl (or Command) + V
    ) ||
    (isBackspace && inputValue.length <= 0) ||
    (inputValue.length >= limit && !isBackspace)
  ) {
    e.preventDefault();
  }
};

export const handlePhoneNumericInput = (
  e: React.KeyboardEvent<HTMLInputElement>,
  limit: number
) => {
  const inputValue = e.currentTarget.value;
  const isBackspace = e.key === 'Backspace' || e.key === 'Delete';

  if (
    !(
      (e.key === '0' && inputValue.length === 0) || // Only allow '0' as the first character
      (e.key >= '0' && e.key <= '9' && inputValue.length > 0) ||
      isBackspace ||
      e.key === 'Tab' ||
      e.key === 'ArrowLeft' ||
      e.key === 'ArrowRight' ||
      (e.key === 'v' && e.metaKey)
    ) ||
    (isBackspace && inputValue.length <= 0) ||
    (inputValue.length >= limit && !isBackspace)
  ) {
    e.preventDefault();
  }
};

export const passportNumberValidation = Yup.string()
  .trim()
  .min(5, 'Passport Number must be at least 5 characters long')
  .max(20, 'Passport Number cannot be more than 20 characters')
  .matches(/^[A-Za-z0-9]+$/, 'Only alphanumeric characters are allowed')
  .required('Passport Number is required');

export const placeOfBirthValidation = Yup.string()
  .trim()
  .min(4, 'Place of birth must be at least 4 characters long')
  .max(30, 'Place of birth must not exceed 30 characters')
  .matches(/^[A-Za-z'-]+$/, 'Only letters, hyphens, or apostrophes are allowed')
  .test(
    'no-trailing-punctuation',
    'Trailing punctuation is not allowed',
    (value) => {
      if (value?.trim() && /[^A-Za-z]$/.test(value?.trim())) {
        return new Yup.ValidationError(
          'Place of birth should not end with punctuation',
          null,
          'placeOfBirth'
        );
      }
      return true;
    }
  )
  .required('Place of birth is required');

export const motherMaidenNameValidation = Yup.string()
  .trim()
  .min(2, 'Mother’s maiden name must be at least 2 characters long')
  .max(30, 'Mother’s maiden name must not exceed 30 characters')
  .matches(/^[A-Za-z'-]+$/, 'Only letters, hyphens, or apostrophes are allowed')
  .test(
    'no-trailing-punctuation',
    'Trailing punctuation is not allowed',
    (value) => {
      if (value?.trim() && /[^A-Za-z]$/.test(value?.trim())) {
        return new Yup.ValidationError(
          'Mother’s maiden name should not end with punctuation',
          null,
          'motherMaidenName'
        );
      }
      return true;
    }
  )
  .required('Mother’s maiden name is required');

export const employerNameValidation = Yup.string()
  .trim()
  .min(3, 'Employer name must be at least 3 characters long')
  .max(30, 'Employer name must not exceed 30 characters')
  .matches(/^[A-Za-z'-]+$/, 'Only letters, hyphens, or apostrophes are allowed')
  .test(
    'no-trailing-punctuation',
    'Trailing punctuation is not allowed',
    (value) => {
      if (value?.trim() && /[^A-Za-z]$/.test(value?.trim())) {
        return new Yup.ValidationError(
          'Employer name should not end with punctuation',
          null,
          'employerName'
        );
      }
      return true;
    }
  )
  .required('Employer name is required');

export const dateOfEmploymentValidation = Yup.date()
  .max(new Date(), 'Employment date cannot be in the future')
  .required('Date of Employment is required');
