import {
  AbsoluteCenter,
  Box,
  FormControl,
  FormLabel,
  Input,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';

import {
  CustomFileInput,
  CustomFormErrorMessage,
  PrimaryButton,
  StatusBox,
} from '../../../components/custom';
import { CustomDropdownMenu } from '../../../components/custom/CustomDropdown';
import {
  addressValidation,
  fileValidation,
} from '../../../constants/validationConstants';
import { convertFileToBase64 } from '../../../helpers/utilityFunctions';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  representativeAddressAsync,
  resetRepresentativeAddress,
} from '../../../store/slices/kyb/representativeAddressSlice';
import {
  getCitiesAsync,
  resetCities,
} from '../../../store/slices/location/citiesSlice';
import { getStatesAsync } from '../../../store/slices/location/statesSlice';

interface ResidentialAddressFormProps {
  goToNextStep?: () => void;
}

export default function ResidentialAddressForm({
  goToNextStep,
}: ResidentialAddressFormProps) {
  const appDispatch = useAppDispatch();

  const { status: addressStatus, value: addressValue } = useAppSelector(
    (state) => state.representativeAddress
  );

  const { value: kybValue } = useAppSelector((state) => state.kybGetById);

  const { status: statesStatus, value: statesValue } = useAppSelector(
    (state) => state.statesFetch
  );

  const { status: citiesStatus, value: citiesValue } = useAppSelector(
    (state) => state.citiesFetch
  );

  const initialValues = {
    streetAddress: '',
    landmark: '',
    lga: '',
    city: '',
    state: '',
    proofOfAddressType: '',
    proofOfResidentialAddress: null,
  };

  const validationSchema = Yup.object().shape({
    streetAddress: addressValidation,
    landmark: Yup.string()
      .min(3, 'Landmark must be at least 3 characters')
      .max(100, 'Landmark cannot exceed 100 characters')
      .required('Landmark is required'),
    city: Yup.string().required('LGA is required'),
    state: Yup.string().required('State is required'),
    proofOfAddressType: Yup.string().required(
      'Proof Of Address Type is required'
    ),
    proofOfResidentialAddress: fileValidation.required(
      'Proof of Address is required'
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      appDispatch(
        representativeAddressAsync({
          streetAddress: values.streetAddress,
          state:
            statesValue?.data?.find((state) => state.id === values.state)
              ?.name ?? '',
          city:
            citiesValue?.data?.cities?.find((city) => city.id === values.city)
              ?.name ?? '',
          landmark: values.landmark,
          proofOfAddressType: values.proofOfAddressType,
          proofOfAddress: await convertFileToBase64(
            values.proofOfResidentialAddress
          ),
        })
      );
    },
  });

  const {
    errors,
    touched,
    values,
    isValid,
    setFieldValue,
    setFieldTouched,
    getFieldProps,
    handleSubmit,
  } = formik;

  useEffect(() => {
    appDispatch(getStatesAsync());
  }, [appDispatch]);

  const addressErrorMessage = useGetUIErrorMessage({
    errors: addressValue?.errors,
    message: addressValue?.message,
    customMessage: '',
  });

  const handleStateChange = (stateId: string) => {
    if (stateId === 'retry') {
      appDispatch(getStatesAsync());
    } else if (stateId === '') {
      setFieldValue('state', stateId);
    } else {
      setFieldValue('state', stateId);

      setFieldValue('city', '');

      appDispatch(resetCities());

      appDispatch(getCitiesAsync(stateId));
    }
  };

  const handleCityChange = ({
    stateId,
    cityId,
  }: {
    stateId: string;
    cityId: string;
  }) => {
    if (cityId === 'retry') {
      appDispatch(getCitiesAsync(stateId));
    } else if (cityId) {
      setFieldValue('city', cityId);
    }
  };

  const handleProofOfAddressChange = (file: File) => {
    setFieldValue('proofOfResidentialAddress', file);
  };

  useEffect(() => {
    if (
      addressStatus === 'success' &&
      kybValue?.data?.addressVerificationStatus !== 'rejected'
    ) {
      sessionStorage.setItem('submittedAddress', 'yes');
    }
  }, [addressStatus, kybValue?.data?.addressVerificationStatus]);

  const submittedAddressFromStorage =
    sessionStorage.getItem('submittedAddress');

  if (addressStatus === 'failed') {
    return (
      <>
        <Box position="relative" zIndex="2" height="80vh" background="#fff">
          <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
            <StatusBox
              variant="error"
              title="Failed to submit address details"
              px={4}
            >
              {addressErrorMessage}
            </StatusBox>
          </AbsoluteCenter>
        </Box>
        <PrimaryButton
          onClick={() => {
            appDispatch(resetRepresentativeAddress());
          }}
          data-testid="retry-failed-address-button"
          w="full"
        >
          Retry
        </PrimaryButton>
      </>
    );
  }

  if (
    addressStatus === 'success' ||
    submittedAddressFromStorage ||
    (kybValue?.data &&
      kybValue?.data?.addressVerificationStatus !== 'pending' &&
      kybValue?.data?.addressVerificationStatus !== 'rejected')
  ) {
    return (
      <>
        <Box position="relative" zIndex="2" height="80vh" background="#fff">
          <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
            <StatusBox
              px={4}
              variant="inProgress"
              title="Address has been submitted."
            >
              {submittedAddressFromStorage
                ? 'Your address verification is in progress'
                : addressErrorMessage}
            </StatusBox>
          </AbsoluteCenter>
        </Box>
        {goToNextStep ? (
          <PrimaryButton
            w="full"
            top="initial"
            right="initial"
            onClick={() => {
              goToNextStep();
            }}
            data-testid="success-address-continue-button"
          >
            Continue
          </PrimaryButton>
        ) : (
          <PrimaryButton
            w="full"
            top="initial"
            right="initial"
            data-testid="dismiss-button"
          >
            Dismiss
          </PrimaryButton>
        )}
      </>
    );
  }

  return (
    <Box>
      <Box mt={4} mb={6}>
        <Text fontSize="1.5rem" color="#000" mb={1}>
          Residential Address Information
        </Text>
        <Text color="gray.300" fontSize=".875rem" lineHeight="171.5%">
          Tell us more about your residential address
        </Text>
      </Box>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column">
            <Stack spacing={8} flex="1">
              <FormControl
                id="streetAddress"
                isInvalid={Boolean(
                  touched.streetAddress && errors.streetAddress
                )}
              >
                <FormLabel>Street Address</FormLabel>
                <Input
                  as="textarea"
                  autoComplete="street-address"
                  placeholder="Enter Street Address"
                  {...getFieldProps('streetAddress')}
                  sx={{
                    pt: 4,
                  }}
                />
                <CustomFormErrorMessage>
                  {touched.streetAddress && errors.streetAddress}
                </CustomFormErrorMessage>
              </FormControl>

              <FormControl
                id="state"
                isInvalid={Boolean(touched.state && errors.state)}
              >
                <FormLabel>State</FormLabel>
                <CustomDropdownMenu
                  searchable
                  isLoading={statesStatus === 'loading'}
                  dropdownHeader="Select State"
                  value={values.state}
                  options={
                    statesValue?.data?.map((state) => ({
                      label: state?.name ?? '',
                      value: state?.id ?? '',
                    })) ?? []
                  }
                  onSelect={(value) => handleStateChange(value)}
                  fieldValue="state"
                  placeholder="Select State"
                  setFieldValue={setFieldValue}
                  menuWidth="450px"
                />
                <CustomFormErrorMessage>
                  {touched.state && errors.state}
                </CustomFormErrorMessage>
              </FormControl>

              <FormControl
                id="city"
                isInvalid={Boolean(touched.city && errors.city)}
              >
                <FormLabel>LGA</FormLabel>
                <CustomDropdownMenu
                  searchable
                  isLoading={citiesStatus === 'loading'}
                  dropdownHeader="Select City"
                  value={values.city}
                  onSelect={(value) =>
                    handleCityChange({
                      stateId: values.state,
                      cityId: value,
                    })
                  }
                  options={
                    citiesValue?.data?.cities?.map((city) => ({
                      label: city?.name ?? '',
                      value: city?.id ?? '',
                    })) ?? []
                  }
                  fieldValue="city"
                  placeholder="Select City"
                  setFieldValue={setFieldValue}
                  menuWidth="450px"
                />
                <CustomFormErrorMessage>
                  {touched.city && errors.city}
                </CustomFormErrorMessage>
              </FormControl>

              <FormControl
                id="landmark"
                isInvalid={Boolean(touched.landmark && errors.landmark)}
              >
                <FormLabel>Landmark</FormLabel>
                <Input
                  maxLength={100}
                  placeholder="Enter Landmark"
                  {...getFieldProps('landmark')}
                />
                <CustomFormErrorMessage>
                  {touched.landmark && errors.landmark}
                </CustomFormErrorMessage>
              </FormControl>

              <FormControl
                id="proofOfAddressType"
                isInvalid={Boolean(
                  touched.proofOfAddressType && errors.proofOfAddressType
                )}
              >
                <FormLabel>Proof Of Address Type</FormLabel>
                <CustomDropdownMenu
                  dropdownHeader="Select Proof Of Address Type"
                  value={values.proofOfAddressType}
                  options={[
                    { label: 'Utility Bill', value: 'Utility Bill' },
                    { label: 'Bank Statement', value: 'Bank Statement' },
                    {
                      label: 'Government Issued Document',
                      value: 'Government Issued Document',
                    },
                    {
                      label: 'Estate or Service Apartment Receipt',
                      value: 'Estate or Service Apartment Receipt',
                    },
                    {
                      label: 'Sworn Affidavit Confirming Address',
                      value: 'Sworn Affidavit Confirming Address',
                    },
                    {
                      label:
                        'Declaration of Address Duly Notarized by a Notary Public',
                      value:
                        'Declaration of Address Duly Notarized by a Notary Public',
                    },
                    {
                      label: 'Land Use Charge Receipt',
                      value: 'Land Use Charge Receipt',
                    },
                    {
                      label: 'Executed Tenancy Agreement',
                      value: 'Executed Tenancy Agreement',
                    },
                  ]}
                  fieldValue="proofOfAddressType"
                  placeholder="Select Proof Of Address Type"
                  setFieldValue={setFieldValue}
                  menuWidth="450px"
                />
                <CustomFormErrorMessage>
                  {touched.proofOfAddressType && errors.proofOfAddressType}
                </CustomFormErrorMessage>
              </FormControl>

              <FormControl
                id="proofOfResidentialAddress"
                isInvalid={Boolean(
                  touched.proofOfResidentialAddress &&
                    errors.proofOfResidentialAddress
                )}
              >
                <FormLabel>Proof Of Address</FormLabel>
                <CustomFileInput
                  onChange={handleProofOfAddressChange}
                  fieldName="proofOfResidentialAddress"
                  isInvalid={Boolean(
                    touched.proofOfResidentialAddress &&
                      errors.proofOfResidentialAddress
                  )}
                  value={values.proofOfResidentialAddress}
                  accept=".png, .jpg, .jpeg, .pdf"
                  setFieldTouched={setFieldTouched}
                />
                <CustomFormErrorMessage>
                  {touched.proofOfResidentialAddress &&
                    errors.proofOfResidentialAddress}
                </CustomFormErrorMessage>
              </FormControl>

              <Box
                background="ash.100"
                border="1px solid #ebedef"
                borderRadius="8px"
                padding="12px 16px"
                fontSize=".8125rem"
                lineHeight="154%"
                letterSpacing="-0.12px"
                color="gray.200"
              >
                <Text fontWeight="500">
                  Proof of address can be any of these documents:
                </Text>
                <UnorderedList>
                  <ListItem>Utility bill for services to the address.</ListItem>
                  <ListItem>Bank statement showing current address.</ListItem>
                  <ListItem>Tax assessment.</ListItem>
                </UnorderedList>
              </Box>
            </Stack>

            <PrimaryButton
              type="submit"
              isDisabled={!isValid}
              isLoading={addressStatus === 'loading'}
              w="full"
              marginTop="24px"
              data-testid="verify-address-button"
            >
              Save and Continue
            </PrimaryButton>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
}
