import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IGetAllGiftCardsRequest {
  page?: number;
  limit?: number;
}

export interface IGetAllGiftCardsResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    name?: string;
    img_url?: string;
    gift_card_category_id?: string;
    image_thumbnail?: string;
    color_code?: string;
    country_code?: string;
    created_at?: string;
    updated_at?: string;
  }[];
  total_count?: number;
  code?: number;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetAllGiftCardsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getAllGiftCardsAsync = createAsyncThunk(
  'giftCards/allGiftCards',
  async (
    { page = 1, limit = 100 }: IGetAllGiftCardsRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get(
        `/gift-card/providers/products?page=${page}&limit=${limit}`,
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const allGiftCardsGetSlice = createSlice({
  name: 'giftCards/allGiftCards/get',
  initialState,
  reducers: {
    resetGetAllGiftCards: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllGiftCardsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getAllGiftCardsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetAllGiftCardsResponse;
      })
      .addCase(getAllGiftCardsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetAllGiftCardsResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetAllGiftCards } = allGiftCardsGetSlice.actions;

export default allGiftCardsGetSlice.reducer;
