import { Button, ButtonProps } from '@chakra-ui/react';

interface DangerButtonProps extends ButtonProps {
  children: string;
  sx?: React.CSSProperties;
}

const DangerButton: React.FC<DangerButtonProps> = ({
  children,
  sx,
  ...rest
}) => {
  return (
    <Button
      sx={{
        background: 'red.600',
        border: '1px solid #c63434',
        boxShadow: 'box-shadow: inset 0px 2px 0px rgba(234, 234, 234, 0.25)',
        height: '48px',
        borderRadius: '12px',
        color: '#fff',
        fontWeight: '400',
        lineHeight: '144%',
        letterSpacing: '-0.12px',
        '&:disabled': {
          color: 'ash.600',
          opacity: 0.8,
        },
        '&:hover': {
          background: 'red.700',
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default DangerButton;
