import { Navigate } from 'react-router-dom';

import InactivityDetector from '../components/utils/InactivityDetector';
import RefreshTokenTimer from '../components/utils/RefreshTokenTimer';
import LivenessVerificationStatus from '../modules/LivenessVerificationStatus';
import { useAppSelector } from '../store/hooks';
interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { value } = useAppSelector((state) => state.auth);

  if (!value?.data?.token?.accessToken && navigator.onLine) {
    return <Navigate to="/auth" replace />;
  }

  return (
    <InactivityDetector>
      <RefreshTokenTimer />
      <LivenessVerificationStatus />
      {children}
    </InactivityDetector>
  );
};

export default PrivateRoute;
