import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';

interface ICheckIfUserPhoneExistsRequest {
  phone: string;
}

interface ICheckIfUserPhoneExistsResponse {
  status?: boolean;
  message?: string;
  data?: {
    exists?: boolean;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<ICheckIfUserPhoneExistsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const checkIfUserPhoneExistsAsync = createAsyncThunk(
  'userPhoneExists/phone',
  async (values: ICheckIfUserPhoneExistsRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.get<ICheckIfUserPhoneExistsResponse>(
        `/user/phonenumber/${values.phone}/exists`
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<ICheckIfUserPhoneExistsResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const userExistsCheckSlice = createSlice({
  name: 'userPhoneExists/phone',
  initialState,
  reducers: {
    resetCheckExistingUser(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkIfUserPhoneExistsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(checkIfUserPhoneExistsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as ICheckIfUserPhoneExistsResponse;
      })
      .addCase(checkIfUserPhoneExistsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as ICheckIfUserPhoneExistsResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetCheckExistingUser } = userExistsCheckSlice.actions;

export default userExistsCheckSlice.reducer;
