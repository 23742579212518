import { Box, BoxProps, Text } from '@chakra-ui/react';

interface CustomBadgeProps extends BoxProps {
  children: string;
}

export default function CustomBadge({ children, ...rest }: CustomBadgeProps) {
  return (
    <Box padding="8px 12px" background="#E6E8E8" borderRadius="32px" {...rest}>
      <Text
        fontWeight={500}
        fontSize=".5625rem"
        lineHeight="167%"
        textAlign="center"
        letterSpacing="0.41px"
        textTransform="uppercase"
      >
        {children}
      </Text>
    </Box>
  );
}
