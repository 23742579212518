import { Select, SelectProps } from '@chakra-ui/react';

interface CustomOptionSelectorProps extends SelectProps {
  options: string[] | number[];
  selectedOption: string | number;
  setSelectedOption: (option: string | number) => void;
  labels?: string[];
  size?: 'medium' | 'large';
}

export default function CustomOptionSelectorSelectComponent({
  options,
  selectedOption,
  setSelectedOption,
  labels,
  size = 'medium',
  ...rest
}: CustomOptionSelectorProps) {
  let height, borderRadius, fontSize;

  if (size === 'medium') {
    height = '24px';
    borderRadius = '12.5px';
    fontSize = '12px';
  } else if (size === 'large') {
    height = '44px';
    borderRadius = '44px';
    fontSize = '14px';
  }

  return (
    <Select
      borderColor="#e0e3e6"
      color="gray.200"
      maxWidth="max-content"
      minH={height}
      h={height}
      sx={{
        ':focus': {
          fontSize,
          borderRadius,
        },
        ':not(:placeholder-shown)': {
          fontSize,
          borderRadius,
          padding: '0 35px 0 15px',
        },
        ':not(:focus)': {
          borderRadius,
        },
      }}
      textTransform="capitalize"
      onChange={(e) => setSelectedOption(e.target.value)}
      {...rest}
    >
      {options.map((option, index) => (
        <option key={index} value={option}>
          {labels?.[index] ?? option}
        </option>
      ))}
    </Select>
  );
}
