import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';

interface IPayBillRequest {
  pin: string;
  billCategory: string;
  customerId: string;
  amount?: number;
  billItemId: string;
  customerAccountNo: number;
  customerName: string;
  billProvider: string;
  phoneNumber?: string;
  emailAddress?: string;
  referenceId?: string;
  customerAddress?: string;
}

interface IPayBillResponse {
  status?: boolean;
  message?: string;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IPayBillResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const payBillAsync = createAsyncThunk(
  'bill/pay',
  async (values: IPayBillRequest, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `BearerToken ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.post(
        'billers/postBill',
        { ...values, pin: values.pin.toString() },
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IPayBillResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
      });

      if (error.response?.status === 401) {
        dispatch(
          showAlert({
            status: 'error',
            type: 'drawer',
            description:
              error?.response?.data?.message ??
              'Your PIN is invalid. Please check and try again',
          })
        );
      } else {
        dispatch(
          showAlert({
            status: 'error',
            type: 'drawer',
            description: errorMessage,
          })
        );
      }

      return rejectWithValue(error.response?.data);
    }
  }
);

export const billsPaymentSlice = createSlice({
  name: 'bill/pay',
  initialState,
  reducers: {
    resetPayBill(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(payBillAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(payBillAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IPayBillResponse;
      })
      .addCase(payBillAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IPayBillResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetPayBill } = billsPaymentSlice.actions;

export default billsPaymentSlice.reducer;
