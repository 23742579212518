/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Adapts a dynamic error from the sever into an array of strings recursively
 * @examples
 * 1. { field_error: 'This is a string' } returns ['This is a string']
 * 2. { field_error: { field_name: 'This is an object' } } returns ['This is an object']
 * 3. { field_errors: ['This is an array'] } returns ['This is an array']
 * 4. { field_errors: [{ field1: 'Object1', field2: 'Object2' }, 'String3', ['Array4']] } returns ['Object1', 'Object2', 'String3', 'Array4']
 *
 * @returns {function} a function which returns an array of strings
 */
const useServerErrorAdapter = () => {
  const serverErrorAdapter = (err: unknown) => {
    const result: string[] = [];

    helper(err);

    function helper(helperInput: unknown): any {
      if (typeof helperInput === 'string' || typeof helperInput === 'number') {
        result.push(String(helperInput));
      }

      if (Array.isArray(helperInput)) {
        if (helperInput.length <= 0) return;
        return helper(helperInput[0]) + helper(helperInput.slice(1));
      }

      if (
        typeof helperInput === 'object' &&
        !Array.isArray(helperInput) &&
        helperInput !== null
      ) {
        return helper(
          Object.values(helperInput)?.[1] ?? Object.values(helperInput)?.[0]
        );
      }
    }

    return result;
  };

  return serverErrorAdapter;
};

export default useServerErrorAdapter;
