import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { IPermissionByModule } from '../../../types/permission';
import { RootState } from '../../';

interface IGetPermissionsResponse {
  status?: boolean;
  message?: string;
  data?: IPermissionByModule[];
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetPermissionsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getPermissionsAsync = createAsyncThunk(
  'permissions',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId =
        localStorage.getItem('businessId') ||
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get<IGetPermissionsResponse>(
        '/permissions',
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const permissionsGetSlice = createSlice({
  name: 'permissions/get',
  initialState,
  reducers: {
    resetGetPermissions: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPermissionsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getPermissionsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetPermissionsResponse;
      })
      .addCase(getPermissionsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetPermissionsResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetPermissions } = permissionsGetSlice.actions;

export default permissionsGetSlice.reducer;
