import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface ICreateBusinessDocumentRequest {
  businessId: string;
  registrationPeriod?: 'Before 2020' | 'After 2020' | '';
  businessType?: string;
  cacNumber?: string;
  tinNumber?: string;
  scumlRegistered?: boolean;
  document?: {
    cac2a?: string;
    cac3?: string;
    cac7a?: string;
    cacCertificate?: string;
    addressDocument?: string;
    scumlCertificate?: string;
    tinCertificate: string;
    statusReport?: string;
    boardResolutionDocument?: string;
    formBN1?: string;
    memart?: string;
  };
  registeredBusiness: boolean;
}

interface ICreateBusinessDocumentResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    businessId?: string;
    cacNumber?: string;
    tinNumber?: string;
    registrationPeriod?: string;
    registrationDate?: string;
    scumlRegistered?: boolean;
    businessType?: string;
    approvalStatus?: string;
    approvedAt?: string;
    cacVerificationStatus?: string;
    tinVerificationStatus?: string;
    addressVerificationStatus?: string;
    members: [
      {
        id: string;
        userId: string;
        kybId: string;
        businessId: string;
        documentId: string;
        firstName: string;
        lastName: string;
        gender: string;
        phoneNumber: string;
        dateOfBirth: string;
        email: string;
        bvn: string;
        isBusinessOwner: string;
        bvnVerificationStatus: string;
        type: string;
      }
    ];
    documents: [];
    createdAt: string;
    updatedAt: string;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<ICreateBusinessDocumentResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const createBusinessDocumentAsync = createAsyncThunk(
  'businessDocument/create',
  async (
    values: ICreateBusinessDocumentRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };

      const { data } = await api.post('/business-verification', values, config);

      localStorage.setItem('userHasAccount', 'yes');

      return data;
    } catch (err) {
      const error = err as AxiosError<ICreateBusinessDocumentResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const businessDocumentCreationSlice = createSlice({
  name: 'businessDocument/create',
  initialState,
  reducers: {
    resetBusinessDocumentCreation(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBusinessDocumentAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(createBusinessDocumentAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as ICreateBusinessDocumentResponse;
      })
      .addCase(createBusinessDocumentAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as ICreateBusinessDocumentResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetBusinessDocumentCreation } =
  businessDocumentCreationSlice.actions;

export default businessDocumentCreationSlice.reducer;
