import { createContext, useContext, useState } from 'react';

interface IViewComplianceContextValue {
  viewStatus: boolean;
  setViewStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const ViewComplianceContext = createContext<
  IViewComplianceContextValue | undefined
>(undefined);

export function useViewComplianceContext() {
  const context = useContext(ViewComplianceContext);
  if (!context) {
    throw new Error(
      'useViewComplianceContext must be used within a ViewComplianceProvider'
    );
  }
  return context;
}

export function ViewComplianceProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [viewStatus, setViewStatus] = useState(false);

  return (
    <ViewComplianceContext.Provider value={{ viewStatus, setViewStatus }}>
      {children}
    </ViewComplianceContext.Provider>
  );
}
