import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';

interface IApproveRepresentativeResponse {
  status?: boolean;
  message?: string;
  data?: {
    provider?: string;
    verificationReference?: string;
    verificationChannel?: string;
    status?: string;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IApproveRepresentativeResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const createIdVerificationAsync = createAsyncThunk(
  'idVerification/create',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };

      const { data } = await api.post<IApproveRepresentativeResponse>(
        '/id-verification',
        { provider: 'metamap', verificationChannel: 'business' },
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IApproveRepresentativeResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
      });

      dispatch(
        showAlert({
          status: 'info',
          description: errorMessage,
          type: 'drawer',
        })
      );

      return rejectWithValue(error.response?.data);
    }
  }
);

export const idVerificationCreateSlice = createSlice({
  name: 'idVerification/create',
  initialState,
  reducers: {
    resetApproveRepresentative(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createIdVerificationAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(createIdVerificationAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload;
      })
      .addCase(createIdVerificationAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IApproveRepresentativeResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetApproveRepresentative } = idVerificationCreateSlice.actions;

export default idVerificationCreateSlice.reducer;
