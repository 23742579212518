import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IInviteRefereeByMailRequest {
  userId: string;
  emails: string[];
}

interface IInviteRefereeByMailResponse {
  status: string;
  message: string;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IInviteRefereeByMailResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const inviteRefereeByMailAsync = createAsyncThunk(
  'inviteRefereeByMail',
  async (
    values: IInviteRefereeByMailRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const accessToken = state.auth.value?.data?.token?.accessToken;
      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };
      const { data } = await api.post<IInviteRefereeByMailResponse>(
        '/referral/referrer/sendReferralEmail',
        values,
        config
      );
      return data;
    } catch (err) {
      const error = err as AxiosError<IInviteRefereeByMailResponse>;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const refereeMailInvitationSlice = createSlice({
  name: 'refereeMailInvitation',
  initialState,
  reducers: {
    resetInviteRefereeByMail(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(inviteRefereeByMailAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(inviteRefereeByMailAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload;
      })
      .addCase(inviteRefereeByMailAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IInviteRefereeByMailResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetInviteRefereeByMail } = refereeMailInvitationSlice.actions;

export default refereeMailInvitationSlice.reducer;
