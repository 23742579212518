import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';

interface IActivateTeamMemberResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    isActive?: boolean;
    createdAt?: string;
    updatedAt?: string;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IActivateTeamMemberResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const activateTeamMemberAsync = createAsyncThunk(
  'teamMember/activate',
  async (teamMemberId: string, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const businessId =
        localStorage.getItem('businessId') ||
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.put<IActivateTeamMemberResponse>(
        `team-members/${teamMemberId}/activate`,
        {},
        config
      );

      dispatch(
        showAlert({
          status: 'success',
          description: data?.message ?? 'Team Member activated successfully',
        })
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IActivateTeamMemberResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
      });

      dispatch(
        showAlert({
          status: 'error',
          description: errorMessage,
          type: 'drawer',
        })
      );

      return rejectWithValue(error.response?.data);
    }
  }
);

export const teamMemberActivateSlice = createSlice({
  name: 'teamMember/activate',
  initialState,
  reducers: {
    resetTeamMemberActivate(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(activateTeamMemberAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(activateTeamMemberAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IActivateTeamMemberResponse;
      })
      .addCase(activateTeamMemberAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IActivateTeamMemberResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetTeamMemberActivate } = teamMemberActivateSlice.actions;

export default teamMemberActivateSlice.reducer;
