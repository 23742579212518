import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { ICDDQuestion } from '../../../types/cddQuestions';
import { RootState } from '../..';

interface ISubmitCddQuestionsRequest {
  responses: {
    questionId: string;
    response: string;
  }[];
}

export interface ISubmitCddQuestionsResponse {
  status?: boolean;
  message?: string;
  data?: ICDDQuestion[];
  errors?: IApiResponseError[];
}

const initialState: IApiState<ISubmitCddQuestionsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const submitCddQuestionsAsync = createAsyncThunk(
  'kyb/cddQuestions/submit',
  async (
    values: ISubmitCddQuestionsRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };

      const userId = state?.auth?.value?.data?.user?.id;

      const { data } = await api.post(
        `/cdd`,
        { ...values, userId, businessId: null },
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const cddQuestionsSubmitSlice = createSlice({
  name: 'kyb/cddQuestions/get',
  initialState,
  reducers: {
    resetSubmitCddQuestions: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitCddQuestionsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(submitCddQuestionsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as ISubmitCddQuestionsResponse;
      })
      .addCase(submitCddQuestionsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as ISubmitCddQuestionsResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetSubmitCddQuestions } = cddQuestionsSubmitSlice.actions;

export default cddQuestionsSubmitSlice.reducer;
