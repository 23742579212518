import { Box, Button, CircularProgress, Text, Tooltip } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { formatMilliSecondsToMmSs } from '../../helpers/utilityFunctions';

interface ResendTimerProps {
  title: string;
  toolTipLabel: string;
  loading: boolean;
  resendAction: () => void;
  /**
   * countdown timer in milliseconds
   * @default 120000 (2minutes)
   */
  countdownTime?: number;
}

export default function ResendTimer({
  title,
  toolTipLabel,
  resendAction,
  loading = false,
  countdownTime = 120000,
}: ResendTimerProps) {
  const [timer, setTimer] = useState(0);

  const handleResendTimerClick = () => {
    resendAction();
    setTimer(countdownTime);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timer <= 0) {
        clearInterval(intervalId);
        return;
      }
      setTimer((prevTimer) => prevTimer - 1000);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer]);

  return (
    <Box minW="45px">
      {timer !== 0 ? (
        <Tooltip label={toolTipLabel} hasArrow>
          <Text
            color="richBlack.300"
            fontSize=".875rem"
            letterSpacing="-0.12px"
            lineHeight="200%"
          >
            {formatMilliSecondsToMmSs(timer)}
          </Text>
        </Tooltip>
      ) : loading ? (
        <>
          <CircularProgress isIndeterminate color="green.500" />
        </>
      ) : (
        <Button
          colorScheme="green"
          color="pMalachite.600"
          variant="link"
          isDisabled={timer > 0}
          onClick={handleResendTimerClick}
          fontWeight={500}
          fontSize=".875rem"
          _hover={{
            textDecoration: 'none',
          }}
        >
          {title}
        </Button>
      )}
    </Box>
  );
}
