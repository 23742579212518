import {
  Box,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { CheckCircleIcon, ChevronUpIcon } from '../../components/icons';
import { getTitleCase } from '../../helpers/utilityFunctions';
import useGetAvailableBalance from '../../hooks/useGetAvailableBalance';
import useGetBusinessDetails from '../../hooks/useGetBusinessDetails';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getAccountBalanceAsync } from '../../store/slices/account/getAccountBalanceSlice';
import { getAccountsAsync } from '../../store/slices/account/getAccountsSlice';
import { devAccessAsync } from '../../store/slices/auth/devAuthSlice';
import { getUserDetailsForBusinessAsync } from '../../store/slices/business/getUserDetailsForBusinessSlice';
import {
  getOutletsAsync,
  IGetOutletsResponse,
} from '../../store/slices/outlet/getOutlets';

interface IOutlet {
  outletName: string;
  accountNumber: string;
  accountName: string;
  commissionsAccountNumber: string;
}

export default function SidebarAccounts() {
  const dispatch = useAppDispatch();
  const { status: accountsStatus } = useAppSelector((state) => state.accounts);
  const { status: outletsStatus, value: outletsValue } = useAppSelector(
    (state) => state.getOutlets
  );
  const { status: userDetailsStatus, value: userDetailsValue } = useAppSelector(
    (state) => state.userDetailsForBusinessFetch
  );

  const outletFromStorage = localStorage.getItem('currentOutlet');
  const parsedOutletFromStorage = outletFromStorage
    ? JSON.parse(outletFromStorage)
    : null;

  const [activeOutlet, setActiveOutlet] = useState<
    Omit<IOutlet, 'accountName'>
  >(
    parsedOutletFromStorage ?? {
      outletName: 'Accounts',
      accountNumber: '',
    }
  );

  const [filteredOutlets, setFilteredOutlets] = useState<
    IGetOutletsResponse['data']
  >([]);

  useEffect(() => {
    dispatch(getAccountsAsync());
    dispatch(getOutletsAsync());
    dispatch(getUserDetailsForBusinessAsync());
    dispatch(getAccountBalanceAsync());
  }, [dispatch]);

  const businessId = localStorage.getItem('businessId');

  useEffect(() => {
    const developerIdInStorage = localStorage.getItem('developerId');
    const devTokenInStorage = localStorage.getItem('devTokenData');

    if (developerIdInStorage && !devTokenInStorage) {
      dispatch(devAccessAsync());
    }
  }, [businessId, dispatch]);

  useEffect(() => {
    if (!activeOutlet.accountNumber && outletsStatus === 'success') {
      setActiveOutlet({
        outletName: getTitleCase(filteredOutlets?.[0]?.name ?? 'Accounts'),
        accountNumber: filteredOutlets?.[0]?.account?.accountNumber ?? '',
        commissionsAccountNumber:
          filteredOutlets?.[0]?.account?.commissionsAccountNumber ?? '',
      });
    }
  }, [activeOutlet.accountNumber, filteredOutlets, outletsStatus]);

  const handleOutletChange = ({
    outletName,
    accountNumber,
    accountName,
    commissionsAccountNumber,
  }: IOutlet) => {
    const outlet = { outletName, accountNumber, commissionsAccountNumber };

    localStorage.removeItem('availableBalance');

    setActiveOutlet(outlet);
    localStorage.setItem('currentOutlet', JSON.stringify(outlet));
    localStorage.setItem('accountNumber', accountNumber);
    localStorage.setItem('accountName', accountName);
    localStorage.setItem('commissionsAccountNumber', commissionsAccountNumber);

    window.location.reload();
  };

  const availableBalanceInNaira = useGetAvailableBalance();

  const accountNumberFromStorage = localStorage.getItem('accountNumber') ?? '';

  useEffect(() => {
    if (availableBalanceInNaira === null) dispatch(getAccountBalanceAsync());
  }, [availableBalanceInNaira, dispatch]);

  const activeOutletStyle: React.CSSProperties = {
    background: 'gray.400',
    fontWeight: '500',
    color: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '2',
  };

  const isActive = (accountNumber?: string) =>
    accountNumber === activeOutlet.accountNumber;

  const businessDetails = useGetBusinessDetails();

  const isBusinessOwner =
    businessDetails?.creatorType?.toLowerCase() === 'owner';

  useEffect(() => {
    if (outletsStatus === 'success') {
      if (isBusinessOwner) {
        setFilteredOutlets(outletsValue?.data);
      } else if (userDetailsStatus === 'success') {
        if (userDetailsValue?.data?.outlets && outletsValue?.data) {
          const filtered = userDetailsValue?.data?.outlets.filter(
            (userOutlet) =>
              outletsValue?.data?.some(
                (outlet) => outlet?.id === userOutlet?.id
              )
          );
          setFilteredOutlets(filtered);
        } else {
          setFilteredOutlets([]);
        }
      }
    }
  }, [
    isBusinessOwner,
    outletsStatus,
    outletsValue?.data,
    userDetailsStatus,
    userDetailsValue?.data?.outlets,
  ]);

  return outletsStatus === 'success' ? (
    <Box
      position="fixed"
      bottom="2"
      bg="#FAFAFA"
      w={{ base: '100%', md: '240px' }}
      pt="5"
      pb="3"
    >
      <Popover>
        <PopoverTrigger>
          <Box
            h="68px"
            mx={6}
            py={3}
            px={4}
            bg="#1A1C1F"
            borderRadius="8px"
            cursor="pointer"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              gap={2}
              alignItems="center"
            >
              <Box>
                <Text
                  fontSize=".875rem"
                  lineHeight="150%"
                  fontWeight={700}
                  noOfLines={1}
                  color="#fff"
                  mb="0.5"
                >
                  {getTitleCase(activeOutlet?.outletName)}
                </Text>
                <Skeleton
                  height={
                    !!accountNumberFromStorage || accountsStatus === 'loading'
                      ? '21px'
                      : ''
                  }
                  isLoaded={
                    !!accountNumberFromStorage || accountsStatus !== 'loading'
                  }
                  borderRadius="4px"
                  startColor="gray.300"
                  endColor="gray.400"
                >
                  <Text
                    fontSize=".875rem"
                    fontWeight={400}
                    color="gray.300"
                    noOfLines={1}
                  >
                    {activeOutlet?.accountNumber}
                  </Text>
                </Skeleton>
              </Box>

              <IconButton
                variant="ghost"
                _hover={{ background: 'transparent' }}
                icon={<ChevronUpIcon />}
                aria-label="Select Outlet"
              />
            </Box>
          </Box>
        </PopoverTrigger>
        <PopoverContent
          borderRadius="8px"
          bg="#1A1C1F"
          p="2"
          pb="1.5"
          width={{ base: '88vw', sm: '93vw', md: '192px' }}
          maxH="50vh"
          overflowY="scroll"
        >
          <Box
            fontSize=".75rem"
            lineHeight="200%"
            color="gray.200"
            fontWeight={400}
          >
            {filteredOutlets?.map((outlet) => (
              <Box key={outlet?.id}>
                <Tooltip
                  display={outlet.isActive ? 'none' : 'inherit'}
                  label="Outlet is disabled"
                  hasArrow
                >
                  <Box
                    px="2"
                    py="1"
                    borderRadius="4px"
                    sx={{
                      ...(isActive(outlet?.account?.accountNumber) &&
                        activeOutletStyle),
                    }}
                    transition="all 0.2s"
                    cursor={outlet.isActive ? 'pointer' : 'not-allowed'}
                    _hover={{ background: 'gray.400', color: '#fff' }}
                    onClick={() => {
                      if (outlet.isActive) {
                        handleOutletChange({
                          outletName: outlet?.name ?? '',
                          accountNumber: outlet?.account?.accountNumber ?? '',
                          accountName: outlet?.account?.accountName ?? '',
                          commissionsAccountNumber:
                            outlet?.account?.commissionsAccountNumber ?? '',
                        });
                      }
                    }}
                    mt="1px"
                  >
                    {outlet?.name}
                    <CheckCircleIcon
                      sx={{
                        display: isActive(outlet.account?.accountNumber)
                          ? 'initial'
                          : 'none',
                      }}
                    />
                  </Box>
                </Tooltip>
              </Box>
            ))}
          </Box>
        </PopoverContent>
      </Popover>
    </Box>
  ) : null;
}
