import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';

interface IDeleteBusinessResponse {
  status?: boolean;
  message?: string;
  data?: unknown;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IDeleteBusinessResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const deleteBusinessAsync = createAsyncThunk(
  'business/delete',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };

      const businessId =
        localStorage.getItem('businessId') ??
        state.kybGetById.value?.data?.businessId;

      const { data } = await api.delete<IDeleteBusinessResponse>(
        `/business/${businessId}`,
        config
      );

      localStorage.removeItem('businessId');
      localStorage.removeItem('businessName');

      window.location.reload();

      return data;
    } catch (err) {
      const error = err as AxiosError<IDeleteBusinessResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
        customMessage: 'Failed to submit business details, try again',
      });

      dispatch(
        showAlert({
          status: 'error',
          description: errorMessage,
          type: 'drawer',
        })
      );

      return rejectWithValue(error.response?.data);
    }
  }
);

export const businessDeletionSlice = createSlice({
  name: 'business/delete',
  initialState,
  reducers: {
    resetBusinessDeletion(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteBusinessAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(deleteBusinessAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IDeleteBusinessResponse;
      })
      .addCase(deleteBusinessAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IDeleteBusinessResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetBusinessDeletion } = businessDeletionSlice.actions;

export default businessDeletionSlice.reducer;
