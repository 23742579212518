import { createContext, useMemo, useReducer } from 'react';

import { decryptData, encryptData } from '../helpers/encryption';
import { IUserOnboardingValues } from '../types/user';

type TUserDetails = Pick<
  IUserOnboardingValues,
  'firstName' | 'lastName' | 'gender' | 'dateOfBirth'
>;

type TUserEmail = Pick<IUserOnboardingValues, 'email' | 'emailVerificationId'>;

type TUserPhoneNumber = Pick<
  IUserOnboardingValues,
  'phoneNumber' | 'phoneNumberVerificationId'
>;

type TOnboardingAction =
  | {
      type: 'UPDATE_USER_DETAILS';
      payload: TUserDetails;
    }
  | {
      type: 'UPDATE_USER_EMAIL';
      payload: TUserEmail;
    }
  | {
      type: 'UPDATE_USER_PHONE_NUMBER';
      payload: TUserPhoneNumber;
    }
  | {
      type: 'UPDATE_USER_LIVENESS_VERIFICATION';
      payload: string;
    }
  | { type: 'UPDATE_AGGREGATOR_CODE'; payload: string }
  | { type: 'RESET_ONBOARDING_STATE' };

interface IOnboardingProps {
  children: React.ReactNode;
}

interface IOnboardingContext {
  state: IUserOnboardingValues;
  dispatch: React.Dispatch<TOnboardingAction>;
}

const userDetailsFromLocalStorage = localStorage.getItem('userDetails');
const decryptedUserDetails = userDetailsFromLocalStorage
  ? (decryptData(userDetailsFromLocalStorage) as TUserDetails)
  : null;

const userEmailFromLocalStorage = localStorage.getItem('userEmail');
const decryptedUserEmail = userEmailFromLocalStorage
  ? (decryptData(userEmailFromLocalStorage) as TUserEmail)
  : { email: '', emailVerificationId: '' };

const userPhoneNumberFromLocalStorage = localStorage.getItem('userPhoneNumber');
const decryptedUserPhoneNumber = userPhoneNumberFromLocalStorage
  ? (decryptData(userPhoneNumberFromLocalStorage) as TUserPhoneNumber)
  : { phoneNumber: '', phoneNumberVerificationId: '' };

const userLivenessVerificationIdFromStorage = localStorage.getItem(
  'userLivenessVerificationId'
);
const decryptedUserLivenessVerificationId =
  userLivenessVerificationIdFromStorage
    ? (decryptData(userLivenessVerificationIdFromStorage) as string)
    : '';
const aggregatorCodeFromLocalStorage =
  localStorage.getItem('aggregatorCode') ?? undefined;

const initialState: IUserOnboardingValues = {
  dateOfBirth: decryptedUserDetails?.dateOfBirth ?? '',
  email: decryptedUserEmail.email,
  emailVerificationId: decryptedUserEmail.emailVerificationId,
  firstName: decryptedUserDetails?.firstName ?? '',
  lastName: decryptedUserDetails?.lastName ?? '',
  gender: decryptedUserDetails?.gender ?? '',
  livenessVerificationId: decryptedUserLivenessVerificationId,
  phoneNumber: decryptedUserPhoneNumber.phoneNumber,
  phoneNumberVerificationId: decryptedUserPhoneNumber.phoneNumberVerificationId,
  aggregatorCode: aggregatorCodeFromLocalStorage,
};

const reducer = (state: IUserOnboardingValues, action: TOnboardingAction) => {
  switch (action.type) {
    case 'UPDATE_USER_DETAILS': {
      localStorage.setItem('userDetails', encryptData(action.payload));

      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        gender: action.payload.gender,
        dateOfBirth: action.payload.dateOfBirth,
      };
    }

    case 'UPDATE_USER_EMAIL': {
      localStorage.setItem('userEmail', encryptData(action.payload));

      return {
        ...state,
        email: action.payload.email,
        emailVerificationId: action.payload.emailVerificationId,
      };
    }

    case 'UPDATE_USER_PHONE_NUMBER': {
      localStorage.setItem('userPhoneNumber', encryptData(action.payload));

      return {
        ...state,
        phoneNumber: action.payload.phoneNumber,
        phoneNumberVerificationId: action.payload.phoneNumberVerificationId,
      };
    }

    case 'UPDATE_USER_LIVENESS_VERIFICATION': {
      localStorage.setItem(
        'userLivenessVerificationId',
        encryptData(action.payload)
      );

      return {
        ...state,
        livenessVerificationId: action.payload,
      };
    }

    case 'UPDATE_AGGREGATOR_CODE': {
      localStorage.setItem('aggregatorCode', action.payload);

      return {
        ...state,
        aggregatorCode: action.payload,
      };
    }

    case 'RESET_ONBOARDING_STATE': {
      localStorage.removeItem('userDetails');
      localStorage.removeItem('userEmail');
      localStorage.removeItem('userPhoneNumber');
      localStorage.removeItem('userLivenessVerificationId');
      localStorage.removeItem('aggregatorCode');

      return initialState;
    }

    default:
      return state;
  }
};

const OnboardingContext = createContext<IOnboardingContext>({
  state: initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
});

function OnboardingProvider({ children }: IOnboardingProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <OnboardingContext.Provider value={contextValue}>
      {children}
    </OnboardingContext.Provider>
  );
}

export { OnboardingContext, OnboardingProvider };
