import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../../api/axios';
import { clearStorage } from '../../../helpers/storage';
import { RootState } from '../..';
import { resetStore } from '../../resetStore';
import { resetSignIn } from './authSlice';

// Sign Out and Reset all states
export const signOut = createAsyncThunk(
  'auth/signOut',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };

      await api.delete('/auth/logout', config);
    } catch (err) {
      // Do nothing
    } finally {
      clearStorage();
      dispatch(resetStore());
      dispatch(resetSignIn());
    }
  }
);
