import { AlertStatus } from '@chakra-ui/react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppToast {
  isOpen: boolean;
  status: AlertStatus;
  description: string;
  type?: 'drawer' | 'window';
  title?: string;
  duration?: number | null;
}

const initialState: AppToast = {
  isOpen: false,
  status: 'loading',
  description: '',
  type: 'window',
  title: undefined,
  duration: 6500,
};

export const appToastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showAlert: (state, action: PayloadAction<Omit<AppToast, 'isOpen'>>) => {
      state.isOpen = true;
      state.status = action.payload.status;
      state.description = action.payload.description;
      state.type = action.payload?.type;
      state.title = action.payload?.title;
      state.duration = action.payload?.duration;
    },
    hideAlert: (state) => {
      state.isOpen = false;
      state.status = 'info';
      state.description = '';
      state.type = 'window';
      state.title = undefined;
      state.duration = 6500;
    },
  },
});

export const { showAlert, hideAlert } = appToastSlice.actions;

export default appToastSlice.reducer;
