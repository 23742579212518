const colors = {
  ash: {
    100: '#f9fafa',
    200: '#ebedef',
    300: '#e0e3e6',
    400: '#d4d9dd',
    600: '#7b828e',
  },
  gray: {
    100: '#c9cfd4',
    200: '#7b828e',
    300: '#656b76',
    400: '#50555E',
    500: '#1a1c1f',
    600: '#ebedef',
  },
  green: {
    100: '#ebfff0',
    150: '#d0f7d4',
    200: '#a8eeaa',
    500: '#14cc52',
    600: '#00c707',
    700: '#009f06',
  },
  red: {
    100: '#ffeceb',
    500: '#e54839',
    600: '#db4343',
    700: '#c63434',
  },
  pMalachite: {
    50: '#e8faee',
    100: '#b6efc9',
    400: '#43D675',
    500: '#14CC52',
    600: '#12ba4b',
  },
  richBlack: {
    200: '#8a9396',
    300: '#546266',
    400: '#334448',
    500: '#00151a',
  },
  chryslerBlue: {
    50: '#eee8fa',
    100: '#C9B6EF',
    400: '#7543D6',
  },
  neutral: {
    100: '#fff',
    800: '#1B1A23',
  },
  ochre: {
    50: '#FAF3E8',
    200: '#E8C793',
  },
  primaryGreen: '#14cc52',
};

export default colors;
