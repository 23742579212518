import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { AppLayout } from '../layouts';
import AppRoutesIndex from './AppRoutesIndex';
import Loadable from './Loadable';
import PrivateRoute from './PrivateRoute';

// Lazy Imports
const AppHomepage = Loadable(lazy(() => import('../pages/AppHomepage')));
// const NotFoundPage = Loadable(lazy(() => import('../pages/NotFoundPage')));
const SettingsPage = Loadable(lazy(() => import('../pages/SettingsPage')));
const DashboardPage = Loadable(lazy(() => import('../pages/DashboardPage')));
const TransfersPage = Loadable(lazy(() => import('../pages/TransfersPage')));
const AirtimePage = Loadable(lazy(() => import('../pages/AirtimePage')));
const BillPaymentPage = Loadable(
  lazy(() => import('../pages/BillPaymentPage'))
);
const TransactionsPage = Loadable(
  lazy(() => import('../pages/TransactionsPage'))
);
const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
const CommissionPage = Loadable(lazy(() => import('../pages/CommissionPage')));
const BulkPaymentPage = Loadable(
  lazy(() => import('../pages/BulkPaymentPage'))
);
const ControlCenterPage = Loadable(
  lazy(() => import('../pages/ControlCenterPage'))
);
const ReferralPage = Loadable(lazy(() => import('../pages/ReferralPage')));
const ControlCenterMerchantPage = Loadable(
  lazy(() => import('../pages/ControlCenterMerchantPage'))
);
const FlightsPage = Loadable(lazy(() => import('../pages/FlightsPage')));
const BulkTransfersHistoryInfoPage = Loadable(
  lazy(() => import('../pages/BulkTransfersHistoryInfoPage'))
);
const GiftCardsPage = Loadable(lazy(() => import('../pages/GiftCardsPage')));
const FlightListingsPage = Loadable(
  lazy(() => import('../pages/FlightListingsPage'))
);
const GiftCardsAllItemsPage = Loadable(
  lazy(() => import('../pages/GiftCardsAllItemsPage'))
);
const DeveloperHomePage = Loadable(
  lazy(() => import('../pages/DeveloperHomePage'))
);
const VirtualAccountsOverviewPage = Loadable(
  lazy(() => import('../pages/VirtualAccountsOverviewPage'))
);
const VirtualTransactionsPage = Loadable(
  lazy(() => import('../pages/VirtualTransactionsPage'))
);
const VirtualAccountInformationPage = Loadable(
  lazy(() => import('../pages/VirtualAccountInformationPage'))
);
const VirtualTransactionsInformationPage = Loadable(
  lazy(() => import('../pages/VirtualTransactionsInformationPage'))
);
// const BettingOverviewPage = Loadable(
//   lazy(() => import('../pages/BettingOverviewPage'))
// );
const WalletFundingPage = Loadable(
  lazy(() => import('../pages/WalletFundingPage'))
);
const BeneficiariesPage = Loadable(
  lazy(() => import('../pages/ManageBeneficiariesPage'))
);

const ProductFeaturesPage = Loadable(
  lazy(() => import('../pages/ProductFeaturesPage'))
);

const CompliancePage = Loadable(lazy(() => import('../pages/CompliancePage')));

const AddDirectorPage = Loadable(
  lazy(() => import('../pages/AddDirectorPage'))
);

// Route Object
const AppRoutes: RouteObject = {
  path: '/app',
  element: (
    <PrivateRoute>
      <AppLayout />
    </PrivateRoute>
  ),
  children: [
    {
      path: '',
      element: <AppRoutesIndex />,
    },
    {
      path: 'get-started',
      element: <AppHomepage />,
    },
    {
      path: 'product-features',
      element: <ProductFeaturesPage />,
    },
    {
      path: 'compliance',
      element: <CompliancePage />,
    },
    {
      path: 'dashboard',
      element: <DashboardPage />,
    },
    {
      path: 'dashboard/add-director',
      element: <AddDirectorPage />,
    },
    {
      path: 'earnings',
      element: <CommissionPage />,
    },
    {
      path: 'settings',
      element: <SettingsPage />,
    },
    {
      path: 'transfers',
      element: <TransfersPage />,
    },
    {
      path: 'transfers/bulk-payment',
      element: <BulkPaymentPage />,
    },
    {
      path: 'transfers/bulk-transfer-info',
      element: <BulkTransfersHistoryInfoPage />,
    },
    {
      path: 'transfers/beneficiaries',
      element: <BeneficiariesPage />,
    },
    {
      path: 'transactions',
      element: <TransactionsPage />,
    },
    {
      path: 'airtime',
      element: <AirtimePage />,
    },
    {
      path: 'bill-payment',
      element: <BillPaymentPage />,
    },
    {
      path: 'control-center',
      element: <ControlCenterPage />,
    },
    {
      path: 'control-center/merchant/:merchantId',
      element: <ControlCenterMerchantPage />,
    },
    {
      path: 'referral',
      element: <ReferralPage />,
    },
    {
      path: 'flights',
      element: <FlightsPage />,
    },
    {
      path: 'flights/flight-listings',
      element: <FlightListingsPage />,
    },
    {
      path: 'gift-cards',
      element: <GiftCardsPage />,
    },
    {
      path: 'gift-cards/all-gift-cards',
      element: <GiftCardsAllItemsPage />,
    },
    {
      path: 'developer/virtual-accounts/overview',
      element: <DeveloperHomePage />,
    },
    {
      path: 'developer/opt-in/virtual-accounts',
      element: <VirtualAccountsOverviewPage />,
    },
    {
      path: 'developer/virtual-accounts/transactions',
      element: <VirtualTransactionsPage />,
    },
    {
      path: 'developer/opt-in/virtual-accounts/account/:accountId',
      element: <VirtualAccountInformationPage />,
    },
    {
      path: 'developer/virtual-accounts/transactions/transaction/:transactionId',
      element: <VirtualTransactionsInformationPage />,
    },
    // {
    //   path: 'developer/betting/overview',
    //   element: <BettingOverviewPage />,
    // },
    {
      path: 'developer/betting/wallet',
      element: <WalletFundingPage />,
    },
    {
      path: '*',
      element: <ComingSoonPage />,
    },
  ],
};

export default AppRoutes;
