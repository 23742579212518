import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { devApi, prodDevApi } from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IGetBusinessByIdRequest {
  id: string;
}

export interface IGetBusinessByIdResponse {
  status?: boolean;
  message?: string;
  statusCode?: string;
  data?: {
    testSecretKey?: string;
    liveSecretKey?: string;
    id?: string;
    name?: string;
    isActivated?: boolean;
    internalBusinessId?: string;
    customerId?: string;
    collectionAccountNumber?: string;
    apiKey?: string;
    testWebhookUrl?: string;
    liveWebhookUrl?: string;
    status?: string;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetBusinessByIdResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getBusinessByIdAsync = createAsyncThunk(
  'businesses/id',
  async ({ id }: IGetBusinessByIdRequest, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const devTokenInStorage = localStorage.getItem('devTokenData');

      const parsedDevTokenInStorage = devTokenInStorage
        ? JSON.parse(devTokenInStorage)
        : null;

      const businessId = localStorage.getItem('developerId');

      const environment = state.environment?.value;

      const apiInstance = environment === 'live' ? prodDevApi : devApi;

      const authToken =
        environment === 'live' ? accessToken : parsedDevTokenInStorage;

      const config = {
        headers: {
          Authorization: `Token ${authToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await apiInstance.get(
        `/internal/businesses/${id}`,
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const businessGetSlice = createSlice({
  name: 'businesses/getById',
  initialState,
  reducers: {
    resetGetBusinessById: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBusinessByIdAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getBusinessByIdAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetBusinessByIdResponse;
      })
      .addCase(getBusinessByIdAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetBusinessByIdResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetBusinessById } = businessGetSlice.actions;

export default businessGetSlice.reducer;
