import { Button, ButtonProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

interface PillButtonProps extends ButtonProps {
  children: React.ReactNode;
  background?: string;
  color?: string;
  sx?: React.CSSProperties;
}

const PillButton = forwardRef<HTMLButtonElement, PillButtonProps>(
  ({ children, background = '#14cc52', color = 'white', sx, ...rest }, ref) => {
    return (
      <Button
        sx={{
          fontFamily: 'Aeonik',
          borderRadius: '32px',
          fontWeight: '500',
          fontSize: '12px',
          lineHeight: '133%',
          background: background,
          color: color,
          '&:disabled': {
            background: '#e6e8e8',
            color: 'gray.500',
            opacity: 1,
            border: 'initial',
          },
          '&:hover': {
            background: background,
            filter: 'saturate(1.2)',
          },
          ...sx,
        }}
        ref={ref}
        {...rest}
      >
        {children}
      </Button>
    );
  }
);

export default PillButton;
