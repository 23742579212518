import {
  AbsoluteCenter,
  Box,
  FormControl,
  FormLabel,
  Image,
  ModalCloseButton,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import InvitedTeamAvatar from '../../../assets/images/invited-team-avatar.svg';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  approveRepresentativeAsync,
  resetApproveRepresentative,
} from '../../../store/slices/kyb/approveRepresentativeSlice';
import { getRepresentativeDetailsAsync } from '../../../store/slices/kyb/getRepresentativeDetailsSlice';
import { PrimaryButton } from '../CustomButtons';
import { CustomDropdownMenu } from '../CustomDropdown';
import { CustomModalFooter } from '../CustomModal';
import { AnimatedLogo, CustomFormErrorMessage, StatusBox } from '../Misc';

export default function RepresentativeCardDetails() {
  const dispatch = useAppDispatch();

  const { value: getRepValue } = useAppSelector(
    (state) => state.getRepresentativeDetails
  );

  const { status: approvalStatus, value: approvalValue } = useAppSelector(
    (state) => state.approveRepresentative
  );

  const { value: kybValue } = useAppSelector((state) => state.kybGetById);

  const errorMessage = useGetUIErrorMessage({
    message: approvalValue?.message,
    errors: approvalValue?.errors,
  });

  const initialValues = {
    approval: '',
  };

  const validationSchema = Yup.object().shape({
    approval: Yup.string().required('Select an option'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        approveRepresentativeAsync({
          action: values.approval,
          businessId: getRepValue?.data?.businessId ?? '',
          representativeId: getRepValue?.data?.businessMemberId ?? '',
        })
      );
    },
  });

  const { errors, touched, isValid, values, setFieldValue, handleSubmit } =
    formik;

  const hasCompletedKYB =
    kybValue?.data?.bvnVerificationStatus === 'approved' &&
    kybValue?.data?.ninVerificationStatus === 'approved';

  if (approvalStatus === 'loading') {
    return (
      <Box minH="330px">
        <AbsoluteCenter>
          <AnimatedLogo />
        </AbsoluteCenter>
      </Box>
    );
  }

  if (approvalStatus === 'success') {
    return (
      <>
        <Box minH="330px" mb="82px">
          <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
            <StatusBox
              variant="success"
              title="Representative status confirmed successfully."
            >
              Representative status has been completed successfully and a
              notification mail has been sent to complete account setup
            </StatusBox>
          </AbsoluteCenter>
        </Box>
        <CustomModalFooter>
          <PrimaryButton
            as={ModalCloseButton}
            w="full"
            top="initial"
            right="initial"
            onClick={() => {
              dispatch(resetApproveRepresentative());
              dispatch(getRepresentativeDetailsAsync());
            }}
          >
            Dismiss
          </PrimaryButton>
        </CustomModalFooter>
      </>
    );
  }

  if (approvalStatus === 'failed') {
    return (
      <>
        <Box minH="330px" mb="82px">
          <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
            <StatusBox variant="error" title="Failed to add representative.">
              {errorMessage}
            </StatusBox>
          </AbsoluteCenter>
        </Box>
        <CustomModalFooter>
          <PrimaryButton
            onClick={() => dispatch(resetApproveRepresentative())}
            w="full"
          >
            Retry
          </PrimaryButton>
        </CustomModalFooter>
      </>
    );
  }

  return (
    <Box>
      <Box mb={8}>
        <Text fontSize="1rem" lineHeight="150%" color="#000" mb={1}>
          Representative
        </Text>
        <Text fontSize=".875rem" lineHeight="171.429%" color="gray.300">
          Confirm representative information and proceed to next action
        </Text>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        gap={4}
        mb={10}
        p="8"
        bg="ash.100"
        borderRadius="16px"
      >
        <Box>
          <Image src={InvitedTeamAvatar} alt="" boxSize="48px" />
        </Box>
        <Stack spacing={1}>
          <Text
            fontWeight={500}
            fontSize="1rem"
            lineHeight="150%"
            letterSpacing="-0.12px"
          >
            {getRepValue?.data?.firstName} {getRepValue?.data?.lastName}
          </Text>
          <Text
            fontSize=".875rem"
            lineHeight="150%"
            letterSpacing="-0.12px"
            color="gray.200"
            maxW="293px"
          >
            {getRepValue?.data?.memberType}
          </Text>
        </Stack>
      </Box>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box mb={2}>
            <FormControl
              id="packageName"
              isInvalid={Boolean(touched.approval && errors.approval)}
            >
              <FormLabel>
                Are you sure want to make this person a Representative?
              </FormLabel>
              <Tooltip
                hasArrow
                display={!hasCompletedKYB ? 'inherit' : 'none'}
                label="Complete ID Verification (BVN and NIN Verification) to Continue"
              >
                <CustomDropdownMenu
                  value={values.approval}
                  options={[
                    {
                      label: 'Yes, allow as representative to the business',
                      value: 'approve',
                    },
                    {
                      label:
                        'No, do not allow as a representative to the business',
                      value: 'decline',
                    },
                  ]}
                  fieldValue="approval"
                  placeholder="Select an option"
                  setFieldValue={setFieldValue}
                  menuWidth="540px"
                />
              </Tooltip>
              <CustomFormErrorMessage>
                {touched.approval && errors.approval}
              </CustomFormErrorMessage>
            </FormControl>
          </Box>

          <CustomModalFooter>
            <PrimaryButton width="full" isDisabled={!isValid} type="submit">
              Confirm
            </PrimaryButton>
          </CustomModalFooter>
        </Form>
      </FormikProvider>
    </Box>
  );
}
