import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiState } from '../../../types/api';
import { ITransactionHistory } from '../../../types/transactionHistory';
import { RootState } from '../..';

interface IGetTransactionHistoryRequest {
  pageNumber?: number;
  pageSize?: number;
  searchParams: {
    /** yyyy-mm-dd */
    startDate: string;
    /** yyyy-mm-dd */
    endDate: string;
    searchField: string;
  };
}

interface IGetTransactionHistoryResponse {
  status?: boolean;
  message?: string;
  totalCount?: number;
  data?: ITransactionHistory[];
}

const initialState: IApiState<IGetTransactionHistoryResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getTransactionHistoryAsync = createAsyncThunk(
  'transactionHistory/get',
  async (
    {
      pageNumber = 0,
      pageSize = 10,
      searchParams: { startDate, endDate, searchField },
    }: IGetTransactionHistoryRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accountNumber = localStorage.getItem('accountNumber');

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.post(
        '/transaction-history',
        {
          pageNumber,
          pageSize,
          searchParams: {
            StartDate: startDate,
            EndDate: endDate,
            SearchField: searchField,
            AccountNumber: accountNumber,
          },
        },
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IGetTransactionHistoryResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const transactionHistoryFetch = createSlice({
  name: 'transactionHistory/fetch',
  initialState,
  reducers: {
    resetTransactionHistory: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionHistoryAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getTransactionHistoryAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetTransactionHistoryResponse;
      })
      .addCase(getTransactionHistoryAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetTransactionHistoryResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetTransactionHistory } = transactionHistoryFetch.actions;
export default transactionHistoryFetch.reducer;
