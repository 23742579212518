import { useEffect, useState } from 'react';

import { useAppSelector } from '../store/hooks';

export default function useGetAvailableBalance() {
  const { value: acctBalValue } = useAppSelector(
    (state) => state.accountBalance
  );

  const availableBalanceFromStorage = localStorage.getItem('availableBalance');

  const parsedAvailableBalance = availableBalanceFromStorage
    ? (JSON.parse(availableBalanceFromStorage) as number)
    : null;

  const availableBalance = acctBalValue?.data?.availableBalance;

  const [availableBalanceState, setAvailableBalanceState] = useState(
    parsedAvailableBalance
  );

  useEffect(() => {
    if (availableBalance !== undefined) {
      localStorage.setItem(
        'availableBalance',
        JSON.stringify(availableBalance)
      );

      setAvailableBalanceState(availableBalance);
    }
  }, [availableBalance]);

  const availableBalanceInNaira =
    availableBalanceState !== null ? availableBalanceState / 100 : null;

  return availableBalanceInNaira;
}
