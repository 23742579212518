import { AbsoluteCenter, Box, Heading, Image, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import ErrorBucketImage from '../assets/images/error-bucket.svg';
import { Page, PillButton } from '../components/custom';

export default function ErrorPage() {
  const navigate = useNavigate();

  const refreshPage = () => {
    navigate(0);
  };

  return (
    <Page title="Oops | Merchant Banking">
      <Box pos="relative" height="100%">
        <AbsoluteCenter w="100%">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            maxW="354px"
            mx="auto"
            mb="4"
          >
            <Image
              src={ErrorBucketImage}
              alt=""
              width={354}
              height={354}
              mb="6"
              mx="auto"
            />
            <Heading
              fontSize={{ base: '1.5rem', md: '2.8125rem' }}
              lineHeight="128.89%"
              textAlign="center"
              fontWeight={400}
              mb={3}
            >
              Whoops!
            </Heading>

            <Text
              fontSize={{ base: '.875rem', md: '16px' }}
              lineHeight="150%"
              color="gray.300"
              textAlign="center"
              maxW="337px"
              mb={8}
            >
              Thanks for your patience while we get it fixed. You may also
              refresh the page or try again later
            </Text>

            <PillButton
              px="8"
              py="4"
              h="52px"
              width="100%"
              maxWidth="149px"
              onClick={refreshPage}
              background="#001419"
            >
              Refresh Page
            </PillButton>
          </Box>
        </AbsoluteCenter>
      </Box>
    </Page>
  );
}
