import Avatar from "./Avatar";
import Divider from "./Divider";
import FocusableElement from "./FocusableElement";
import FormLabel from "./FormLabel";
import Input from "./Input";
import Menu from "./Menu";
import Select from "./Select";
import Stepper from "./Stepper";
import Switch from "./Switch";
import Tabs from "./Tabs";

const components = {
  Avatar,
  Divider,
  FocusableElement,
  FormLabel,
  Input,
  Menu,
  Select,
  Stepper,
  Switch,
  Tabs,
};

export default components;
