import { SkeletonText, SkeletonTextProps } from "@chakra-ui/react";

interface CustomTableSkeletonTextProps extends SkeletonTextProps {
  children: React.ReactNode;
  isLoaded?: boolean;
}

export default function CustomTableSkeletonText({
  children,
  isLoaded,
}: CustomTableSkeletonTextProps) {
  return (
    <SkeletonText
      isLoaded={isLoaded}
      fadeDuration={1}
      noOfLines={2}
      skeletonHeight={2}
      endColor="#f9fafa"
    >
      {children}
    </SkeletonText>
  );
}
