import { Box, Image, ImageProps } from "@chakra-ui/react";
import { motion } from "framer-motion";

import Logo from "../../../assets/images/Logo.svg";

interface AnimatedLogoProps extends ImageProps {
  /**
   * @default 88
   */
  width?: number;
  /**
   * @default 85
   */
  height?: number;
}

const AnimatedLogo: React.FC<AnimatedLogoProps> = ({
  width = 88,
  height = 85,
  ...rest
}) => {
  const imageVariants = {
    initial: { opacity: 0.4 },
    animate: {
      opacity: 1,
      transition: { ease: "easeInOut", duration: 2, repeat: Infinity },
    },
  };

  return (
    <Box
      as={motion.div}
      initial="initial"
      animate="animate"
      variants={imageVariants}
    >
      <Image src={Logo} width={width} height={height} margin="auto" {...rest} />
    </Box>
  );
};

export default AnimatedLogo;
