import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { devApi } from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { IUserToken } from '../../../types/user';
import { showAlert } from '../appToast/appToastSlice';

interface IDevResponseData {
  token?: IUserToken;
}

interface IDevResponse {
  status?: boolean;
  message?: string;
  data?: IDevResponseData;
  errors?: IApiResponseError[];
}

const devTokenInStorage = localStorage.getItem('devTokenData');

const devAuthData = devTokenInStorage
  ? ({
      status: true,
      message: '',
      data: { token: { devTokenInStorage } },
      errors: [],
    } as IDevResponse)
  : null;

const initialState: IApiState<IDevResponse> = {
  value: devAuthData,
  status: 'idle',
  error: '',
};

export const devAccessAsync = createAsyncThunk(
  'auth/devPlatform',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const userId = localStorage.getItem('userId');
      const businessId = localStorage.getItem('businessId');

      const apiInstance = devApi;

      if (!userId || !businessId) {
        throw new Error('User ID or Business ID is missing');
      }

      const { data } = await apiInstance.post<IDevResponse>(
        'auth/generate-token-for-business',
        {
          userId: `dev${userId}`,
          businessId,
        }
      );

      const devTokenData = data.data?.token?.accessToken;

      if (devTokenData) {
        localStorage.setItem('devTokenData', JSON.stringify(devTokenData));
      }

      return data;
    } catch (err) {
      const error = err as AxiosError<IDevResponse>;

      dispatch(
        showAlert({
          status: 'error',
          description:
            error?.response?.data?.message ??
            'An error occurred while trying to fetch your access token, try again',
        })
      );

      return rejectWithValue(error.response?.data);
    }
  }
);

export const devAuthSlice = createSlice({
  name: 'devAuth',
  initialState,
  reducers: {
    resetDevAccess: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
    updateDevAuthToken: (
      state,
      action: PayloadAction<IUserToken | undefined>
    ) => {
      state.value = {
        ...state.value,
        data: { ...state.value?.data, token: action?.payload },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(devAccessAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(devAccessAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload;
      })
      .addCase(devAccessAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IDevResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetDevAccess, updateDevAuthToken } = devAuthSlice.actions;

export default devAuthSlice.reducer;
