import './assets/fonts/Switzer/Fonts/WEB/css/switzer.css';
import './assets/fonts/Aeonik/aeonik.css';
import './assets/fonts/Reckless/reckless.css';
import 'react-datepicker/dist/react-datepicker.css';

import { ChakraProvider } from '@chakra-ui/react';

import AppToast from './components/notification/AppToast';
import { ErrorBoundary, ScrollToTop } from './components/utils';
import { ViewComplianceProvider } from './contexts/ViewComplianceContext';
import Routes from './routes';
import theme from './theme';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <ViewComplianceProvider>
        <ErrorBoundary>
          <ScrollToTop />
          <AppToast />
          <Routes />
        </ErrorBoundary>
      </ViewComplianceProvider>
    </ChakraProvider>
  );
}

export default App;
