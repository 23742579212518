import { Box, Progress } from "@chakra-ui/react";
import React from "react";

const Loader: React.FC = () => (
  <Box
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 2001,
      width: "100%",
      "& > * + *": {
        marginTop: 16,
      },
    }}
  >
    <Progress size="xs" isIndeterminate colorScheme="green" />
  </Box>
);

export default Loader;
