import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IGetKycRequest {
  userId: string;
  accessToken?: string;
}

interface IGetKycResponse {
  success?: boolean;
  message?: string;
  data?: {
    id?: string;
    userId?: string;
    tier?: number;
    bvn?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    dateOfBirth?: string;
    idType?: string;
    idNumber?: string;
    street?: string;
    lga?: string;
    state?: string;
    landmark?: string;
    verificationChannel?: string;
    bvnVerificationStatus?: string;
    idVerificationStatus?: string;
    addressVerificationStatus?: string;
    createdAt?: string;
    updatedAt?: string;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetKycResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getKycByIdAsync = createAsyncThunk(
  'kyc',
  async (
    { userId, accessToken: accessTokenFromParams }: IGetKycRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const config = {
        headers: {
          Authorization: `Token ${accessTokenFromParams ?? accessToken}`,
          'X-Verification-Channel': 'business',
        },
      };

      const { data } = await api.get<IGetKycResponse>(
        `kyc/user/${userId}`,
        config
      );

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const kycGetByIdSlice = createSlice({
  name: 'kyc/getById',
  initialState,
  reducers: {
    resetGetKycById: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getKycByIdAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getKycByIdAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetKycResponse;
      })
      .addCase(getKycByIdAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetKycResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetKycById } = kycGetByIdSlice.actions;

export default kycGetByIdSlice.reducer;
