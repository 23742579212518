import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IBank {
  name?: string;
  code?: string;
  logo?: string;
}

interface IGetBanksResponse {
  status?: string;
  message?: string;
  data?: IBank[];
  errors?: IApiResponseError[];
}

const bankListFromStorage = localStorage.getItem('bankList');

const parsedBankList = bankListFromStorage
  ? (JSON.parse(bankListFromStorage) as IGetBanksResponse)
  : null;

const initialState: IApiState<IGetBanksResponse> = {
  value: parsedBankList,
  status: 'idle',
  error: '',
};

export const getBanksAsync = createAsyncThunk(
  'banks/get',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };

      if (bankListFromStorage) {
        return JSON.parse(bankListFromStorage) as IGetBanksResponse;
      } else {
        const { data } = await api.get('/banks', config);
        localStorage.setItem('bankList', JSON.stringify(data));

        return data;
      }
    } catch (err) {
      const error = err as AxiosError<IGetBanksResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const banksFetch = createSlice({
  name: 'banks/get',
  initialState,
  reducers: {
    resetBanks: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBanksAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getBanksAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetBanksResponse;
      })
      .addCase(getBanksAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetBanksResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetBanks } = banksFetch.actions;

export default banksFetch.reducer;
