import { Box, BoxProps } from '@chakra-ui/react';

interface CustomModalFooterProps extends BoxProps {
  children: React.ReactNode;
}

export default function CustomModalFooter({
  children,
  ...rest
}: CustomModalFooterProps) {
  return (
    <Box
      bg="#fff"
      borderRadius="0px 0px 24px 24px"
      p="6"
      width="100%"
      pos="absolute"
      left="0"
      right="0"
      bottom="0"
      height="96px"
      overflowY="auto"
      {...rest}
    >
      {children}
    </Box>
  );
}
