import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { showAlert } from '../appToast/appToastSlice';
import { updateBusinessOwnerAsync } from '../kyb/updateBusinessOwnerSlice';

interface IRegisterUserRequest {
  businessId?: string;
  aggregatorCode?: string;
  email: string;
  emailVerificationId: string;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  livenessVerificationId: string;
  passcode: string;
  phoneNumber: string;
  phoneNumberVerificationId: string;
  isBusinessOwner: boolean;
}

interface IRegisterUserResponse {
  success?: boolean;
  message?: string;
  data?: {
    id?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    gender?: string;
    dateOfBirth?: string;
    requiresLivenessVerification?: boolean;
    livenessVerificationId?: string;
    livenessVerificationStatus?: string;
    lastLoginAt?: string;
    isBlocked?: boolean;
    createdAt?: string;
    updatedAt?: string;
    blockedAt?: string;
    hasPin?: boolean;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IRegisterUserResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const registerUserAsync = createAsyncThunk(
  'registerUser',
  async (values: IRegisterUserRequest, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.post<IRegisterUserResponse>(
        '/register',
        values
      );

      if (values.businessId) {
        dispatch(
          updateBusinessOwnerAsync({
            userId: data.data?.id,
            businessId: values.businessId,
            dateOfBirth: values.dateOfBirth,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            gender: values.gender,
            phoneNumber: values.phoneNumber,
          })
        );
      }

      return data;
    } catch (err) {
      const error = err as AxiosError<IRegisterUserResponse>;

      dispatch(
        showAlert({
          status: 'error',
          description:
            error.response?.data?.message ??
            'Failed to register your account, please try again',
        })
      );

      return rejectWithValue(error.response?.data);
    }
  }
);

export const registerUserSlice = createSlice({
  name: 'registerUser',
  initialState,
  reducers: {
    resetUserRegistration: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUserAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(registerUserAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IRegisterUserResponse;
      })
      .addCase(registerUserAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IRegisterUserResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetUserRegistration } = registerUserSlice.actions;

export default registerUserSlice.reducer;
