import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IGetAggregatorStatsResponse {
  status?: boolean;
  message?: string;
  data?: {
    totalMerchants: number;
    activeMerchants: number;
    inactiveMerchants: number;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetAggregatorStatsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getAggregatorStatsAsync = createAsyncThunk(
  'aggregatorStats',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get('aggregators/stats', config);

      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const aggregatorStatsGetSlice = createSlice({
  name: 'aggregatorStats/get',
  initialState,
  reducers: {
    resetGetAggregatorStats: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAggregatorStatsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getAggregatorStatsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetAggregatorStatsResponse;
      })
      .addCase(getAggregatorStatsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetAggregatorStatsResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetAggregatorStats } = aggregatorStatsGetSlice.actions;

export default aggregatorStatsGetSlice.reducer;
