import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IGetCommissionOverviewRequest {
  accountNumber: string;
  startDate: string;
  endDate: string;
}

interface IGetCommissionOverviewResponse {
  status?: boolean;
  message?: string;
  data?: {
    commissionsEarnedAnalysis?: {
      totalAmountEarned?: number;
      totalAmountEarnedPercentageChange?: string | number;
    };
    commissionsWithdrawnAnalysis?: {
      totalAmountWithdrawn?: number;
      totalAmountWithdrawnPercentageChange?: string | number;
    };
  };

  errors?: IApiResponseError;
}

const initialState: IApiState<IGetCommissionOverviewResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getCommissionOverviewAsync = createAsyncThunk(
  'commissionOverview/get',
  async (
    { accountNumber, startDate, endDate }: IGetCommissionOverviewRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get(
        `/commission/transactions/commissions-analysis/${accountNumber}?startDate=${startDate}&endDate=${endDate}`,
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IGetCommissionOverviewResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const commissionOverviewFetch = createSlice({
  name: 'commissionOverview/fetch',
  initialState,
  reducers: {
    resetCommissionOverview: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommissionOverviewAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getCommissionOverviewAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetCommissionOverviewResponse;
      })
      .addCase(getCommissionOverviewAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetCommissionOverviewResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetCommissionOverview } = commissionOverviewFetch.actions;
export default commissionOverviewFetch.reducer;
