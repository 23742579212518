import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';

import api from '../../../api/axios';
import { decryptData, encryptData } from '../../../helpers/encryption';
import {
  calculateTokenRefreshTime,
  convertMilliSecondsToDays,
} from '../../../helpers/utilityFunctions';
import { IApiResponseError, IApiState } from '../../../types/api';
import { IUserSignIn, IUserToken } from '../../../types/user';
import { showAlert } from '../appToast/appToastSlice';
import { setAccessTokenExpirationTime } from './accessTokenExpirationSlice';

interface ISignInCredentials {
  email: string;
  passcode: string;
}

interface ISignInResponseData {
  user?: IUserSignIn;
  token?: IUserToken;
}

interface ISignInResponse {
  status?: boolean;
  message?: string;
  data?: ISignInResponseData;
  errors?: IApiResponseError[];
}

const tokenCookie = Cookies.get('tokenData');
const parsedTokenCookie = tokenCookie
  ? (JSON.parse(tokenCookie) as IUserToken)
  : undefined;

const otherAuthData = localStorage.getItem('otherAuthData');
const parsedOtherAuthData = otherAuthData
  ? (decryptData(otherAuthData) as IUserSignIn)
  : undefined;

const authData =
  parsedTokenCookie && parsedOtherAuthData
    ? ({
        status: true,
        message: '',
        data: { token: { ...parsedTokenCookie }, ...parsedOtherAuthData },
        errors: [],
      } as ISignInResponse)
    : null;

const initialState: IApiState<ISignInResponse> = {
  value: authData,
  status: 'idle',
  error: '',
};

export const signInAsync = createAsyncThunk(
  'auth/signIn',
  async (
    { email, passcode }: ISignInCredentials,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await api.post<ISignInResponse>('/auth/login', {
        email: email.toLowerCase(),
        passcode,
      });

      const expiresInMilliseconds = data?.data?.token?.expiresIn;
      const expiresInDays = convertMilliSecondsToDays(expiresInMilliseconds);

      const tokenData = data.data?.token;

      const { token, ...otherAuthData } = data.data as ISignInResponseData;

      const encryptedAuthData = encryptData(otherAuthData);

      const currentUser = data.data?.user;

      Cookies.set('tokenData', JSON.stringify(tokenData), {
        expires: expiresInDays,
        sameSite: 'Strict',
        secure: true,
      });

      localStorage.clear();

      localStorage.setItem('otherAuthData', encryptedAuthData);

      localStorage.setItem('emailAddress', email.toLowerCase());
      localStorage.setItem('firstName', data.data?.user?.firstName ?? '');

      if (currentUser?.hasPin) {
        localStorage.setItem('userHasPin', 'true');
      }

      if (currentUser?.businesses?.[0]?.hasAccount) {
        localStorage.setItem('userHasAccount', 'true');
      }

      if (currentUser?.businesses?.[0]?.id) {
        localStorage.setItem('businessId', currentUser?.businesses?.[0]?.id);
      }

      if (currentUser?.businesses?.[0]?.name) {
        localStorage.setItem(
          'businessName',
          currentUser?.businesses?.[0]?.name
        );
      }

      if (currentUser?.id) {
        localStorage.setItem('userId', currentUser?.id);
      }

      dispatch(
        setAccessTokenExpirationTime(
          calculateTokenRefreshTime(expiresInMilliseconds)
        )
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<ISignInResponse>;

      dispatch(
        showAlert({
          status: 'error',
          description:
            error?.response?.data?.message ??
            'An error occurred while signing you in, try again',
        })
      );

      return rejectWithValue(error.response?.data);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetSignIn: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
    updateAuthToken: (state, action: PayloadAction<IUserToken | undefined>) => {
      state.value = {
        ...state.value,
        data: { ...state.value?.data, token: action?.payload },
      };
    },
    updatePin: (state, action: PayloadAction<boolean | undefined>) => {
      state.value = {
        ...state.value,
        data: { ...state.value?.data, user: { hasPin: action.payload } },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(signInAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload;
      })
      .addCase(signInAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as ISignInResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetSignIn, updateAuthToken, updatePin } = authSlice.actions;

export default authSlice.reducer;
