import { Box, IconButton, IconButtonProps } from "@chakra-ui/react";

import { ArrowLeftIcon } from "../../icons";

interface CustomDrawerBackButtonProps extends Partial<IconButtonProps> {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  notAllowed?: boolean;
}

export default function CustomDrawerBackButton({
  onClick,
  notAllowed,
}: CustomDrawerBackButtonProps) {
  const handleBackButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <>
      {notAllowed ? (
        <Box
          bg="#fff"
          boxSize="45px" // Cover the default back button
          position="absolute" // Place this button over the default back button
          top="12px"
          zIndex="2"
          mr={-20}
        />
      ) : (
        <IconButton
          onClick={handleBackButtonClick}
          aria-label="Go Back"
          variant="unstyled"
          icon={<ArrowLeftIcon />}
          position="absolute" // Place this button over the default back button
          top="12px"
          zIndex="2"
        />
      )}
    </>
  );
}
