import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IGetAggregatorByCodeRequest {
  aggregatorCode: string;
}

interface IGetAggregatorByCodeResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    category?: string;
    type?: string;
    name?: string;
    email?: string;
    address?: string;
    city?: string;
    state?: string;
    aggregatorCode?: string;
    hasAccount?: boolean;
    creatorId?: string;
    creatorType?: string;
    createdAt?: string;
    updatedAt?: string;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetAggregatorByCodeResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getAggregatorByCodeAsync = createAsyncThunk(
  'aggregator',
  async (
    { aggregatorCode }: IGetAggregatorByCodeRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get(
        `/aggregator/code/${aggregatorCode}`,
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const aggregatorGetSlice = createSlice({
  name: 'aggregator/get',
  initialState,
  reducers: {
    resetGetAggregatorByCode: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAggregatorByCodeAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getAggregatorByCodeAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetAggregatorByCodeResponse;
      })
      .addCase(getAggregatorByCodeAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetAggregatorByCodeResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetAggregatorByCode } = aggregatorGetSlice.actions;

export default aggregatorGetSlice.reducer;
