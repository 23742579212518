import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiState } from '../../../types/api';
import { IRoleData, IUserData } from '../../../types/userProfile';
import { RootState } from '../..';

export interface IUserProfileResponse {
  status?: boolean;
  message?: string;
  data?: {
    user?: IUserData;
    role?: IRoleData;
  };
}

const initialState: IApiState<IUserProfileResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getUserProfileAsync = createAsyncThunk(
  'userProfile/get',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const businessId =
        localStorage.getItem('businessId') ||
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `BearerToken ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };
      const { data } = await api.get('user/profile', config);

      return data;
    } catch (err) {
      const error = err as AxiosError<IUserProfileResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const userProfileFetch = createSlice({
  name: 'userProfile/fetch',
  initialState,
  reducers: {
    resetUserProfile: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfileAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getUserProfileAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IUserProfileResponse;
      })
      .addCase(getUserProfileAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetUserProfile } = userProfileFetch.actions;

export default userProfileFetch.reducer;
