import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { showAlert } from '../appToast/appToastSlice';

interface IRegisterRetailUserRequest {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  passcode: string;
  phoneNumber: string;
  dateOfBirth: string;
  gender: string;
  livenessVerificationId: string;
  livenessDocument: {
    selfiePhotoUrl: string;
    selfieFrameUrl: string;
    videoFrameUrl: string;
    selfiePhotoUrlHash: string;
    status: string;
    statusMessage: string;
  };
}

interface IRegisterRetailUserResponse {
  success?: boolean;
  message?: string;
  data?: {
    id?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    gender?: string;
    dateOfBirth?: string;
    requiresLivenessVerification?: boolean;
    livenessVerificationId?: string;
    livenessVerificationStatus?: string;
    lastLoginAt?: string;
    isBlocked?: boolean;
    createdAt?: string;
    updatedAt?: string;
    blockedAt?: string;
    hasPin?: boolean;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IRegisterRetailUserResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const registerRetailUserAsync = createAsyncThunk(
  'registerRetailUser',
  async (values: IRegisterRetailUserRequest, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.post<IRegisterRetailUserResponse>(
        '/register/retail-user',
        values
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IRegisterRetailUserResponse>;

      dispatch(
        showAlert({
          status: 'error',
          description:
            error.response?.data?.message ??
            'Failed to register your merchant account, please try again',
        })
      );

      return rejectWithValue(error.response?.data);
    }
  }
);

export const registerRetailUserSlice = createSlice({
  name: 'registerRetailUser',
  initialState,
  reducers: {
    resetRetailUserRegistration: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerRetailUserAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(registerRetailUserAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IRegisterRetailUserResponse;
      })
      .addCase(registerRetailUserAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IRegisterRetailUserResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetRetailUserRegistration } = registerRetailUserSlice.actions;

export default registerRetailUserSlice.reducer;
