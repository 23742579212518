import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import MinimalFooter from '../MinimalLayout/MinimalFooter';
import MinimalNavbar from '../MinimalLayout/MinimalNavbar';
import MinimalSideDesign from './MinimalSideDesign';

export default function MinimalSideLayout() {
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box width={{ base: '100%', lg: '55%' }}>
          <nav>
            <MinimalNavbar />
          </nav>
          <main style={{ maxWidth: '1728px', margin: 'auto' }}>
            <Outlet />
          </main>
          <footer>
            <MinimalFooter />
          </footer>
        </Box>
        <MinimalSideDesign />
      </Box>
    </>
  );
}
