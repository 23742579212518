import {
  AbsoluteCenter,
  Box,
  Center,
  FormControl,
  FormLabel,
  Input,
  ModalCloseButton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import {
  AnimatedLogo,
  CustomFormErrorMessage,
  PrimaryButton,
  StatusBox,
} from '../../../components/custom';
import { CustomDropdownMenu } from '../../../components/custom/CustomDropdown';
import CustomMultipleSelectionDropdown from '../../../components/custom/CustomDropdown/CustomMultipleSelectionDropdown';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getCddQuestionsAsync } from '../../../store/slices/kyb/getCDDQuestionsSlice';
import {
  resetSubmitCddQuestions,
  submitCddQuestionsAsync,
} from '../../../store/slices/kyb/submitCDDQuestionsSlice';
import { AccountForms } from './AccountSetupForms';

interface CDDQuestionsFormProps {
  setCurrentAccountSetupForm?: React.Dispatch<
    React.SetStateAction<AccountForms>
  >;
}

export default function CDDQuestionsForm({
  setCurrentAccountSetupForm,
}: CDDQuestionsFormProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { status: getCddStatus, value: getCddValue } = useAppSelector(
    (state) => state.getCDDQuestions
  );

  const { status: submitCddStatus, value: submitCddValue } = useAppSelector(
    (state) => state.submitCDDQuestions
  );

  const getCddErrorMessage = useGetUIErrorMessage({
    message: getCddValue?.message,
    errors: getCddValue?.errors,
    customMessage:
      'An error occurred while retrieving questions, kindly try again.',
  });

  const submitCddErrorMessage = useGetUIErrorMessage({
    message: submitCddValue?.message,
    errors: submitCddValue?.errors,
    customMessage:
      'An error occurred while submitting your cdd questions, kindly try again.',
  });

  useEffect(() => {
    dispatch(getCddQuestionsAsync());
  }, [dispatch]);

  const cddValue = useMemo(() => getCddValue?.data ?? [], [getCddValue?.data]);

  const questionsIds = cddValue.map((cdd) => cdd?.id ?? '');

  const checkDependency = (
    id?: string,
    dependencies?: string[]
  ): 'none' | 'initial' => {
    if (id) {
      const shouldDisplay = dependencies?.find((question) =>
        question.includes(values[id])
      );

      if (shouldDisplay) {
        return 'initial';
      } else {
        return 'none';
      }
    }

    return 'none';
  };

  const initialValues: { [key: string]: string } =
    questionsIds?.reduce((a, b) => ({ ...a, [b]: '' }), {}) ?? {};

  const validationSchema = Yup.object().shape({
    ...getCddValue?.data?.reduce((schema, cdd) => {
      const questionId = cdd.id ?? '';

      return {
        ...schema,
        [questionId]: Yup.lazy((value) =>
          (cdd.required && !cdd.dependent) ||
          checkDependency(cdd.dependency?.id, cdd.dependency?.options) ===
            'initial'
            ? Yup.string()
                .required(`${cdd.question} is required`)
                .max(100, 'Cannot exceed 100 characters')
            : Yup.string().max(100, 'Cannot exceed 100 characters')
        ),
      };
    }, {}),
  } as Record<string, Yup.StringSchema<string>>);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const filledValues = { ...initialValues, ...values };

      const responses = Object.keys(filledValues).map((key) => ({
        questionId: key,
        response: filledValues[key],
      }));

      dispatch(
        submitCddQuestionsAsync({
          responses,
        })
      );
    },
  });

  const {
    errors,
    touched,
    values,
    isValid,
    setFieldValue,
    getFieldProps,
    handleSubmit,
  } = formik;

  const handleMultipleSelectionSetFieldValue = (
    field: string,
    value: string[]
  ) => {
    setFieldValue(field, value.join(', '));
  };

  useEffect(() => {
    if (submitCddStatus === 'success') {
      sessionStorage.setItem('submittedCdd', 'yes');
    }
  }, [submitCddStatus]);

  const submittedCddFromStorage = sessionStorage.getItem('submittedCdd');

  if (getCddStatus === 'loading' || submitCddStatus === 'loading') {
    return (
      <Box position="relative" zIndex="2" height="80vh" background="#fff">
        <AbsoluteCenter>
          <AnimatedLogo />
        </AbsoluteCenter>
      </Box>
    );
  }

  if (getCddStatus === 'failed') {
    return (
      <>
        <Box position="relative" zIndex="2" height="80vh" background="#fff">
          <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
            <StatusBox variant="error" title="Failed to retrieve questions">
              {getCddErrorMessage}
            </StatusBox>
          </AbsoluteCenter>
        </Box>
        <PrimaryButton
          onClick={() => {
            dispatch(getCddQuestionsAsync());
          }}
          w="full"
          data-testid="retry-failed-cdd-button"
        >
          Retry
        </PrimaryButton>
      </>
    );
  }

  if (submitCddStatus === 'failed') {
    return (
      <>
        <Box position="relative" zIndex="2" height="80vh" background="#fff">
          <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
            <StatusBox variant="error" title="Failed to submit answers">
              {submitCddErrorMessage}
            </StatusBox>
          </AbsoluteCenter>
        </Box>
        <PrimaryButton
          onClick={() => {
            dispatch(resetSubmitCddQuestions());
          }}
          w="full"
          data-testid="retry-submit-cdd-failed-button"
        >
          Retry
        </PrimaryButton>
      </>
    );
  }

  if (submitCddStatus === 'success' || submittedCddFromStorage) {
    return (
      <>
        <Box minH="330px" mb="82px">
          <Center>
            <StatusBox variant="success" title="CDD Submitted Successfully.">
              {submittedCddFromStorage
                ? 'Your answers have been submitted'
                : submitCddErrorMessage}
            </StatusBox>
          </Center>
        </Box>
        {setCurrentAccountSetupForm ? (
          <PrimaryButton
            w="full"
            top="initial"
            right="initial"
            onClick={() => {
              setCurrentAccountSetupForm('address');
            }}
            data-testid="navigate-address-dismiss-button"
          >
            Dismiss
          </PrimaryButton>
        ) : (
          <PrimaryButton
            w="full"
            top="initial"
            right="initial"
            onClick={() => navigate('/app/get-started')}
            data-testid="navigate-get-started-button"
          >
            Dismiss
          </PrimaryButton>
        )}
      </>
    );
  }

  return (
    <>
      <Box mt={4} mb={6}>
        <Text fontSize="1.5rem" color="#000" mb={1}>
          More About You
        </Text>
        <Text color="gray.300" fontSize=".875rem" lineHeight="171.5%">
          Enhance your merchant banking experience. Share more insights about
          you.
        </Text>
      </Box>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column">
            <Stack spacing={8} flex="1">
              {cddValue.map((question, index) => {
                const dependencyVisibility = checkDependency(
                  question.dependency?.id,
                  question.dependency?.options
                );

                return (
                  <FormControl
                    isRequired={
                      (question.required && !question.dependent) ||
                      dependencyVisibility === 'initial'
                    }
                    key={question.id ?? index}
                    id={question.id ?? index.toString()}
                    isInvalid={Boolean(
                      touched[question.id ?? ''] && errors[question.id ?? '']
                    )}
                    sx={{
                      display: question.dependent
                        ? dependencyVisibility
                        : 'initial',
                    }}
                  >
                    <FormLabel>{question.question}</FormLabel>
                    {question.optionType === 'single-select' && (
                      <CustomDropdownMenu
                        value={values[question.id ?? '']}
                        options={
                          question.options?.map((option) => ({
                            label: option,
                            value: option,
                          })) ?? []
                        }
                        fieldValue={question.id ?? ''}
                        placeholder="Select an answer"
                        setFieldValue={setFieldValue}
                        menuWidth="450px"
                      />
                    )}

                    {question.optionType === 'text' && (
                      <Input
                        {...getFieldProps(question.id ?? '')}
                        placeholder="Your answer"
                        value={values[question?.id ?? ''] ?? ''}
                      />
                    )}

                    {question.optionType === 'multi-select' && (
                      <CustomMultipleSelectionDropdown
                        dropdownHeader="Select One or More answers"
                        values={
                          values[question.id ?? '']?.length
                            ? values[question.id ?? '']?.split(', ')
                            : []
                        }
                        options={
                          question.options?.map((option) => ({
                            label: option,
                            value: option,
                          })) ?? []
                        }
                        fieldValue={question.id ?? ''}
                        placeholder="Select One or More answers"
                        setFieldValue={handleMultipleSelectionSetFieldValue}
                        menuWidth="450px"
                      />
                    )}

                    <CustomFormErrorMessage>
                      {errors[question.id ?? '']}
                    </CustomFormErrorMessage>
                  </FormControl>
                );
              })}
            </Stack>

            <PrimaryButton
              data-testid="submit-cdd-button"
              type="submit"
              w="full"
              marginTop="24px"
            >
              {isValid ? 'Save and Continue' : 'Answer Required Questions'}
            </PrimaryButton>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
}
