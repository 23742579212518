import {
  FormErrorIcon,
  FormErrorMessage,
  FormErrorMessageProps,
} from "@chakra-ui/react";

interface CustomFormErrorMessageProps extends FormErrorMessageProps {
  children: React.ReactNode;
  /**
   * @default false
   */
  hideIcon?: boolean;
}

export default function CustomFormErrorMessage({
  children,
  hideIcon = false,
  ...rest
}: CustomFormErrorMessageProps) {
  return (
    <FormErrorMessage
      fontSize=".8125rem"
      lineHeight="20px"
      // pos="absolute"
      color="red.600"
      mb={-4}
      {...rest}
    >
      {hideIcon ? null : <FormErrorIcon />}
      {children}
    </FormErrorMessage>
  );
}
