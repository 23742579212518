import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  expiresIn: Number(Cookies.get("expiresIn")) ?? 0,
};

export const accessTokenExpirationSlice = createSlice({
  name: "auth/accessTokenExpiration",
  initialState,
  reducers: {
    setAccessTokenExpirationTime: (state, action) => {
      state.expiresIn = action.payload;

      Cookies.set("expiresIn", action.payload);
    },
    resetAccessTokenExpirationTime: (state) => {
      state.expiresIn = 0;

      Cookies.remove("expiresIn");
    },
  },
});

export const { setAccessTokenExpirationTime, resetAccessTokenExpirationTime } =
  accessTokenExpirationSlice.actions;
export default accessTokenExpirationSlice.reducer;
