import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiState } from '../../../types/api';
import { RootState } from '../..';

export interface ILimit {
  id?: string;
  name?: string;
  category?: string;
  code?: string;
  description?: string;
  value?: number;
  valueType?: string;
}

export interface IIndemnity {
  level?: number;
}

export interface ILimitData {
  limits?: ILimit[];
  indemnities?: IIndemnity[];
}
export interface ILimitByBusinessResponse {
  status?: boolean;
  message?: string;
  data?: ILimitData;
}

const initialState: IApiState<ILimitByBusinessResponse['data']> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getLimitByBusinessAsync = createAsyncThunk(
  'limitByBusiness/get',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState() as RootState;

      const accountNo = localStorage.getItem('accountNumber');

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `BearerToken ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const url = `transaction-limits/business/account/${accountNo}`;

      const { data } = await api.get(url, config);

      return data;
    } catch (err) {
      const error = err as AxiosError<ILimitByBusinessResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const limitByBusinessFetch = createSlice({
  name: 'limitByBusiness/fetch',
  initialState,
  reducers: {
    resetLimitByBusiness: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLimitByBusinessAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getLimitByBusinessAsync.fulfilled, (state, action) => {
        state.status = 'success';
        const payload = action.payload as ILimitByBusinessResponse;
        state.value = payload?.data || null;
      })
      .addCase(getLimitByBusinessAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetLimitByBusiness } = limitByBusinessFetch.actions;

export default limitByBusinessFetch.reducer;
