import { Box, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import RepresentativeCard from '../../components/custom/Representative/RepresentativeCard';
import AddDirectorBanner from '../../modules/Dashboard/AddDirectors/AddDirectorBanner';
import AdditionalDocumentCard from '../../modules/KYBSetup/AdditionalDocument/AdditionalDocumentCard';
import TransactionsApprovalCard from '../../modules/Settings/AccountManagement/TransactionMandatesApproval/PendingTransactionsForApproval/TransactionsApprovalCard';
import AppHeader from './AppHeader';
import AppSidebar, { DRAWER_WIDTH } from './AppSidebar';

export default function AppLayout() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      onClose(); // Close the mobile sidebar drawer on route change
    }, 300);
  }, [location, onClose]);

  return (
    <Box bg="#fafafa" display="flex" minHeight="100%" overflow="hidden">
      <Box>
        <AppHeader onOpen={onOpen} />
        <AppSidebar
          onClose={() => onClose}
          display={{ base: 'none', md: 'block' }}
        />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <AppSidebar onClose={onClose} />
          </DrawerContent>
        </Drawer>
      </Box>

      <Box
        as="main"
        ml={{ base: 0, md: DRAWER_WIDTH }}
        p={{ base: 4, md: '44px' }}
        pb="5rem"
        flexGrow={1}
        background="white"
        border="1px solid #ebedef"
        borderRadius={{ base: 0, md: '24px 0px 0px 24px' }}
        mt="80px"
        mb={{ base: 0, md: 6 }}
        overflow="auto"
        minH="100%"
      >
        <Box maxW="1728px" margin="auto">
          <RepresentativeCard />
          <AdditionalDocumentCard />
          {/* <AddDirectorBanner /> */}
          <TransactionsApprovalCard />
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
