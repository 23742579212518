import { Box, BoxProps } from "@chakra-ui/react";

interface CustomCardProps extends BoxProps {
  children: React.ReactNode;
}

const CustomCard: React.FC<CustomCardProps> = ({ children, ...rest }) => {
  return (
    <Box
      w="full"
      border="1px solid #ebedef"
      borderRadius="24px"
      padding={{ base: "30px", md: "64px" }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default CustomCard;
