import { Box, Image, Text } from '@chakra-ui/react';
import React from 'react';

import Ellipse from '../../assets/images/blackEllipse.svg';
import { DeepGreenCheckIcon } from '../../components/icons';

export default function MinimalSideDesign() {
  const details = [
    'Business account management',
    'Transaction Management and monitoring',
    'Lifestyle Management',
  ];

  return (
    <Box
      sx={{
        width: '45%',
        height: '95vh',
        margin: '20px',
        borderRadius: '24px',
        position: 'relative',
        background: `radial-gradient(farthest-side at 100% 0%, #EEE8FA, #C9B6EF 50%, #B6EFC9 , #E8FAEE)`,
        display: { base: 'none', lg: 'block' },
      }}
      data-testid="do-more-box"
    >
      <Box
        sx={{
          width: '70%',
          margin: '0 auto',
          marginTop: '15%',
        }}
      >
        <Text
          sx={{
            fontSize: { base: '1.25rem', md: '2.25rem' },
            lineHeight: '115.385%',
          }}
          data-testid="do-more-text"
        >
          Do more <br />
          for your Business <br /> with 9jaPay
        </Text>
        <Text
          sx={{ marginTop: '8px', color: 'richBlack.300' }}
          data-testid="do-more-subtext"
        >
          Unlock essential tools to drive your business growth and success.
        </Text>
        <Box sx={{ marginTop: '20px' }}>
          {details.map((detail, index) => {
            return (
              <Text
                key={index}
                sx={{ marginY: '8px', color: 'richBlack.300' }}
                data-testid={`${
                  detail?.split(' ')?.[0]?.toLowerCase() ?? index
                }-text`}
              >
                {' '}
                <DeepGreenCheckIcon /> {detail}
              </Text>
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          width: { base: '50%', lg: '70%', xl: '75%' },
          position: 'absolute',
          right: 0,
          bottom: 0,
        }}
      >
        <Image src={Ellipse} w="100%" data-testid="do-more-image" />
      </Box>
    </Box>
  );
}
