import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { IGetSingleFlightResponseData } from '../../../types/flight';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';

interface IGetSingleFlightRequest {
  selectionValue: string;
}

export interface IGetSingleFlightResponse {
  status?: boolean;
  message?: string;
  data?: IGetSingleFlightResponseData;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetSingleFlightResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getSingleFlightAsync = createAsyncThunk(
  'flights/getSingle',
  async (
    values: IGetSingleFlightRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.post<IGetSingleFlightResponse>(
        '/flights/single-flight',
        { selectionValue: values.selectionValue },
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IGetSingleFlightResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
      });

      dispatch(showAlert({ status: 'error', description: errorMessage }));

      return rejectWithValue(error.response?.data);
    }
  }
);

export const flightsSearchSlice = createSlice({
  name: 'flights/getSingle',
  initialState,
  reducers: {
    resetGetSingleFlight: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSingleFlightAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getSingleFlightAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetSingleFlightResponse;
      })
      .addCase(getSingleFlightAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetSingleFlightResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetSingleFlight } = flightsSearchSlice.actions;

export default flightsSearchSlice.reducer;
