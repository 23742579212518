import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { devApi, prodDevApi } from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IUpdateWebhookRequest {
  id: string;
  environment: string;
  webhook: string;
}

export interface IUpdateWebhookResponse {
  status?: string;
  message?: string;
  statusCode?: string;
  data?: {
    id?: string;
    name?: string;
    isActivated?: boolean;
    internalBusinessId?: string;
    customerId?: string;
    collectionAccountNumber?: string;
    apiKey?: string;
    liveWebhookUrl?: string;
    alias?: string;
    status?: string;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IUpdateWebhookResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const updateWebhookAsync = createAsyncThunk(
  'webhook/update',
  async (
    { id, environment, webhook }: IUpdateWebhookRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const devTokenInStorage = localStorage.getItem('devTokenData');

      const parsedDevTokenInStorage = devTokenInStorage
        ? JSON.parse(devTokenInStorage)
        : null;

      const businessId = localStorage.getItem('businessId');

      const environment = state.environment?.value;

      const apiInstance = environment === 'live' ? prodDevApi : devApi;

      const authToken =
        environment === 'live' ? accessToken : parsedDevTokenInStorage;

      const config = {
        headers: {
          Authorization: `Token ${authToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await apiInstance.put(
        `businesses/${id}/webhooks`,
        { environment: 'live', webhook },
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const webhookUpdateSlice = createSlice({
  name: 'webhook/update',
  initialState,
  reducers: {
    resetUpdateWebhook: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateWebhookAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(updateWebhookAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IUpdateWebhookResponse;
      })
      .addCase(updateWebhookAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IUpdateWebhookResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetUpdateWebhook } = webhookUpdateSlice.actions;

export default webhookUpdateSlice.reducer;
