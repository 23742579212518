import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';

interface IResetPasscodeRequest {
  resetToken: string;
  passcode: string;
}

interface IResetPasscodeResponse {
  status?: boolean;
  message?: string;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IResetPasscodeResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const resetPasscodeAsync = createAsyncThunk(
  'resetPasscode',
  async (
    { passcode, resetToken }: IResetPasscodeRequest,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.post(`/auth/passcode/reset`, {
        resetToken,
        passcode,
      });

      return data;
    } catch (err) {
      const error = err as AxiosError<IResetPasscodeResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const resetPasscodeSlice = createSlice({
  name: 'resetPasscode',
  initialState,
  reducers: {
    resetPasscodeReset: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPasscodeAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(resetPasscodeAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IResetPasscodeResponse;
      })
      .addCase(resetPasscodeAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IResetPasscodeResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetPasscodeReset } = resetPasscodeSlice.actions;

export default resetPasscodeSlice.reducer;
