import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';
import { getScheduledTransfersAsync } from './getScheduledTransfersSlice';

interface IUpdateScheduledTransferRequest {
  scheduledTransferId: string;
  amount: number;
}

interface IUpdateScheduledTransferResponse {
  status?: boolean;
  message?: string;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IUpdateScheduledTransferResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const updateScheduledTransferAsync = createAsyncThunk(
  'scheduledTransfer/update',
  async (
    values: IUpdateScheduledTransferRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId =
        localStorage.getItem('businessId') ||
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.put<IUpdateScheduledTransferResponse>(
        `/transfers/scheduled/${values.scheduledTransferId}`,
        values,
        config
      );

      dispatch(getScheduledTransfersAsync());

      dispatch(
        showAlert({
          status: 'success',
          description: data?.message ?? 'Scheduled transfer has been updated',
        })
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IUpdateScheduledTransferResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
      });

      dispatch(
        showAlert({
          status: 'error',
          description: errorMessage,
        })
      );

      return rejectWithValue(error.response?.data);
    }
  }
);

export const scheduledTransferUpdateSlice = createSlice({
  name: 'scheduledTransfer/update',
  initialState,
  reducers: {
    resetScheduledTransferUpdate(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateScheduledTransferAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(updateScheduledTransferAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IUpdateScheduledTransferResponse;
      })
      .addCase(updateScheduledTransferAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IUpdateScheduledTransferResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetScheduledTransferUpdate } =
  scheduledTransferUpdateSlice.actions;

export default scheduledTransferUpdateSlice.reducer;
