import { Highlight, HighlightProps } from "@chakra-ui/react";

export default function CustomHighlight({
  children,
  query,
  ...rest
}: HighlightProps) {
  return (
    <Highlight
      query={query}
      styles={{ px: "2", py: "1", rounded: "full", bg: "red.100" }}
      {...rest}
    >
      {children}
    </Highlight>
  );
}
