import { BoxProps, Th } from '@chakra-ui/react';

interface CustomThProps extends BoxProps {
  children?: React.ReactNode;
  isNumeric?: boolean;
}

export default function CustomTh({
  children,
  isNumeric,
  ...rest
}: CustomThProps) {
  return (
    <Th fontWeight="400" fontSize=".6875rem" lineHeight=".875rem" {...rest}>
      {children}
    </Th>
  );
}
