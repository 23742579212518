import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IGetTransactionsValues {
  page: number;
  limit?: number;
  search?: string;
  category?: string;
  accountNo?: number;
}

interface ITransaction {
  id?: string;
  aggregator_id?: string;
  aggregator_name?: string;
  biller_name?: string;
  biller_id?: string;
  aggregator_ref?: string;
  internal_ref?: string;
  customer_id?: string;
  customer_account_no?: string;
  internal_share?: number;
  aggregator_share?: number;
  commission_type?: string;
  recipient_number?: string;
  bill_category?: string;
  posting_status?: string;
  posting_transaction_id?: string;
  transaction_status?: string;
  aggregator_call_back_response?: string | null;
  is_reversed?: boolean | null;
  transaction_retry_count?: number | null;
  transaction_channel?: string;
  transaction_amount?: number;
  aggregator_response?: string | null;
  reversal_ref?: string | null;
  reversal_status?: string | null;
  reversal_retry_count?: number | null;
  updated_at?: string;
  created_at: string;
}

interface IGetBillTransactionsResponse {
  status?: boolean;
  message?: string;
  data?: {
    transactions: ITransaction[];
    page: number;
    dataSize: number;
    total: number;
  };
}

const initialState: IApiState<IGetBillTransactionsResponse['data']> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getBillTransactionsAsync = createAsyncThunk(
  'billTransactions/get',
  async (
    { page, limit = 10, category, accountNo, search }: IGetTransactionsValues,
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const state = getState() as RootState;

      const accountNo = localStorage.getItem('accountNumber');

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `BearerToken ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      let url = `billers/transactions?accountNo=${accountNo}`;

      if (category) {
        url += `&category=${category}`;
      }

      if (page) {
        url += `&page=${page}`;
      }

      if (limit) {
        url += `&limit=${limit}`;
      }

      if (search) {
        url += `&search=${encodeURIComponent(search)}`;
      }

      const { data } = await api.get(url, config);

      return data;
    } catch (err) {
      const error = err as AxiosError<IGetBillTransactionsResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const billTransactionsSlice = createSlice({
  name: 'bills/getTransactions',
  initialState,
  reducers: {
    resetGetBillTransactions: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBillTransactionsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getBillTransactionsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        const payload = action.payload as IGetBillTransactionsResponse;
        state.value = payload?.data || null;
        state.limit = payload?.data?.dataSize;
      })
      .addCase(getBillTransactionsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetGetBillTransactions } = billTransactionsSlice.actions;

export default billTransactionsSlice.reducer;
