import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { ITeamMember } from '../../../types/teamMember';
import { RootState } from '../..';

export interface IGetOutletsResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    account?: {
      businessId?: string;
      accountName?: string;
      accountNumber?: string;
      commissionsAccountNumber?: string;
      accountCategory?: string;
    };
    accountType?: string;
    name?: string;
    address?: string;
    city?: string;
    state?: string;
    isActive?: boolean;
    createdAt?: string;
    updatedAt?: string;
    teamMembers?: Array<ITeamMember>;
    outletImage?: string;
  }[];
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetOutletsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getOutletsAsync = createAsyncThunk(
  'outlets',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get('outlets', config);

      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const outletsGetSlice = createSlice({
  name: 'outlets/get',
  initialState,
  reducers: {
    resetGetOutlets: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOutletsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getOutletsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetOutletsResponse;
      })
      .addCase(getOutletsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetOutletsResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetOutlets } = outletsGetSlice.actions;

export default outletsGetSlice.reducer;
