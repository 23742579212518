import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';

interface IDeactivateTeamMemberResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    isActive?: boolean;
    createdAt?: string;
    updatedAt?: string;
  };

  errors?: IApiResponseError[];
}

const initialState: IApiState<IDeactivateTeamMemberResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const deactivateTeamMemberAsync = createAsyncThunk(
  'teamMember/deactivate',
  async (teamMemberId: string, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const businessId =
        localStorage.getItem('businessId') ||
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.put<IDeactivateTeamMemberResponse>(
        `/team-members/${teamMemberId}/deactivate`,
        {},
        config
      );

      dispatch(
        showAlert({
          status: 'success',
          description: data?.message ?? 'Team Member deactivated successfully',
        })
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IDeactivateTeamMemberResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
      });

      dispatch(
        showAlert({
          status: 'error',
          description: errorMessage,
          type: 'drawer',
        })
      );

      return rejectWithValue(error.response?.data);
    }
  }
);

export const teamMemberDeactivateSlice = createSlice({
  name: 'teamMember/deactivate',
  initialState,
  reducers: {
    resetTeamMemberDeactivate(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deactivateTeamMemberAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(deactivateTeamMemberAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IDeactivateTeamMemberResponse;
      })
      .addCase(deactivateTeamMemberAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IDeactivateTeamMemberResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetTeamMemberDeactivate } = teamMemberDeactivateSlice.actions;

export default teamMemberDeactivateSlice.reducer;
