import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

export interface IGetReferralCommissionStatsRequest {
  startDate: string;
  endDate: string;
  accountNumber: string;
}

interface IGetReferralCommissionStatsResponse {
  status: boolean;
  message: string;
  data: {
    totalCommissionValue: number;
    totalValue: number;
    totalVolume: number;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetReferralCommissionStatsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getReferralCommissionStatsAsync = createAsyncThunk(
  'getReferralCommissionStats',
  async (
    { startDate, endDate, accountNumber }: IGetReferralCommissionStatsRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const businessId =
        localStorage.getItem('businessId') ||
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get(
        `commission/transactions/merchant-referal-commissions-analysis/${accountNumber}?startDate=${startDate}&endDate=${endDate}`,
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IGetReferralCommissionStatsResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const getReferralCommissionStatsSlice = createSlice({
  name: 'getReferralCommissionStats',
  initialState,
  reducers: {
    resetReferralCommissionStats: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReferralCommissionStatsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getReferralCommissionStatsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetReferralCommissionStatsResponse;
      })
      .addCase(getReferralCommissionStatsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetReferralCommissionStatsResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetReferralCommissionStats } =
  getReferralCommissionStatsSlice.actions;
export default getReferralCommissionStatsSlice.reducer;
