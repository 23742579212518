import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IGetCustomersTransactionsRequest {
  page?: number;
  limit?: number;
}

export interface IGiftCardTransactionsData {
  id?: string;
  status?: string;
  customer_id?: string;
  customer_account_number?: string;
  recipient?: string;
  message?: string;
  created_at?: string;
  giftCard?: string;
  country?: string;
  amount?: string | number;
  metadata?: {
    currency?: string;
    price?: string | number;
    rate?: string | number;
    sender_name?: string;
  };
}

export interface IGetCustomersTransactionsResponse {
  status?: boolean;
  message?: string;
  data?: {
    record?: {
      reference?: string;
      transactions?: IGiftCardTransactionsData[];
    }[];
  };
  total_count?: number;
  code?: number;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetCustomersTransactionsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getCustomersTransactionsAsync = createAsyncThunk(
  'giftCards/customersTransactions',
  async (
    { page = 1, limit = 10 }: IGetCustomersTransactionsRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');
      const accountNumber = localStorage.getItem('accountNumber') ?? '';

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get(
        `/gift-card/transaction/customer/${accountNumber}?page=${page}&limit=${limit}`,
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const customersTransactionsGetSlice = createSlice({
  name: 'giftCards/customersTransactions/get',
  initialState,
  reducers: {
    resetGetCustomersTransactions: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomersTransactionsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getCustomersTransactionsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetCustomersTransactionsResponse;
      })
      .addCase(getCustomersTransactionsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetCustomersTransactionsResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetCustomersTransactions } =
  customersTransactionsGetSlice.actions;

export default customersTransactionsGetSlice.reducer;
