import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';

interface IUpdateBusinessOwnerRequest {
  userId?: string;
  businessId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: string;
  gender: string;
}

interface IUpdateBusinessOwnerResponse {
  success?: boolean;
  message?: string;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IUpdateBusinessOwnerResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const updateBusinessOwnerAsync = createAsyncThunk(
  'businessOwner/update',
  async (values: IUpdateBusinessOwnerRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.put<IUpdateBusinessOwnerResponse>(
        '/kyb/representative/update-business-owner',
        values
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IUpdateBusinessOwnerResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const businessOwnerEnableSlice = createSlice({
  name: 'businessOwner/update',
  initialState,
  reducers: {
    resetUpdateBusinessOwner(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateBusinessOwnerAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(updateBusinessOwnerAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload;
      })
      .addCase(updateBusinessOwnerAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IUpdateBusinessOwnerResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetUpdateBusinessOwner } = businessOwnerEnableSlice.actions;

export default businessOwnerEnableSlice.reducer;
