import {
  Box,
  Button,
  Grid,
  GridItem,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import TeamImage from '../../../assets/images/team-image-rep.svg';
import { CustomDrawer, CustomDrawerHeader } from '../../../components/custom';
import { NotificationDotIcon } from '../../../components/icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getDocumentRequestAsync } from '../../../store/slices/additionalDocument/getDocumentRequestSlice';
import AdditionalInformationSteps from './AdditionalInformationSteps';

export default function AdditionalDocumentCard() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { status, value } = useAppSelector((state) => state.getDocumentRequest);

  const { value: authValue } = useAppSelector((state) => state.auth);

  const showOnRoutes = ['/app/dashboard', '/app/get-started'];

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    dispatch(getDocumentRequestAsync());
  }, [dispatch]);

  const businessId = localStorage.getItem('businessId');

  const userHasAccount = authValue?.data?.user?.businesses?.find(
    (business) => business?.id === businessId
  )?.hasAccount;

  const hasPendingKybInfo =
    userHasAccount &&
    (authValue?.data?.user?.businessMember?.[0]?.hasCdd === false ||
      authValue?.data?.user?.businessMember?.[0]?.ninVerificationStatus ===
        'pending' ||
      authValue?.data?.user?.businessMember?.[0]?.addressVerificationStatus ===
        'pending' ||
      authValue?.data?.user?.businessMember?.[0]?.addressVerificationStatus ===
        'rejected');

  if (
    (status === 'success' &&
      !value?.data?.submitted &&
      value?.data?.names?.length) ||
    hasPendingKybInfo
  ) {
    return (
      <>
        <Box
          display={
            showOnRoutes.some((route) => location.pathname.includes(route))
              ? 'inherit'
              : 'none'
          }
          borderRadius="16px"
          border="1px solid #ab94f2"
          background="#CDBEFA"
          minH="104px"
          mb="62px"
          position="relative"
        >
          <NotificationDotIcon
            fill="#E54839"
            position="absolute"
            top="-10px"
            left={{ base: '18px', lg: '24px' }}
          />

          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }}
            gap={6}
            px={8}
            py={{ base: 4, lg: 'initial' }}
          >
            <GridItem
              ml="12px"
              w="100%"
              position="relative"
              backgroundImage={TeamImage}
              backgroundRepeat="no-repeat"
              backgroundSize="contain"
            />
            <GridItem
              w="100%"
              py={{ base: 0, lg: '6' }}
              mt={{ base: '-5', lg: 'initial' }}
            >
              <Text
                fontFamily="RecklessNeue"
                fontSize="1.125rem"
                fontWeight={700}
                lineHeight="2rem"
                mb={{ base: 0, lg: 2 }}
              >
                Additional Information Required
              </Text>
              <Text fontSize=".875rem" lineHeight="114.286%" noOfLines={2}>
                {value?.data?.reason ??
                  "You're almost there - just a few more details to enhance your 9jaPay experience."}
              </Text>
            </GridItem>
            <GridItem
              w="100%"
              py={{ base: 0, lg: '2rem' }}
              textAlign="right"
              alignSelf="center"
            >
              <Button
                bg="#001419"
                color="#fff"
                fontSize=".75rem"
                fontWeight={400}
                width={{ base: '100%', lg: 'initial' }}
                borderRadius={{ base: '8px', lg: '32px' }}
                p="12px 24px"
                onClick={onOpen}
              >
                Continue
              </Button>
            </GridItem>
          </Grid>
        </Box>

        <CustomDrawer
          isOpen={isOpen}
          onClose={onClose}
          drawerHeader="Additional Information"
        >
          <CustomDrawerHeader startsFromBeginning>
            Additional Information
          </CustomDrawerHeader>
          <AdditionalInformationSteps />
        </CustomDrawer>
      </>
    );
  }

  return null;
}
