import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';

interface IRequestResetPasscodeRequest {
  email: string;
}

interface IRequestResetPasscodeResponse {
  status?: boolean;
  message?: string;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IRequestResetPasscodeResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const requestResetPasscodeAsync = createAsyncThunk(
  'requestResetPasscode',
  async ({ email }: IRequestResetPasscodeRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.post('/auth/passcode/request-reset', {
        email,
      });

      return data;
    } catch (err) {
      const error = err as AxiosError<IRequestResetPasscodeResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const requestResetPasscodeSlice = createSlice({
  name: 'requestResetPasscode',
  initialState,
  reducers: {
    resetRequestPasscode: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestResetPasscodeAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(requestResetPasscodeAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IRequestResetPasscodeResponse;
      })
      .addCase(requestResetPasscodeAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IRequestResetPasscodeResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetRequestPasscode } = requestResetPasscodeSlice.actions;

export default requestResetPasscodeSlice.reducer;
