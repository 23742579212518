import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../store/hooks';
import { signOut } from '../store/slices/auth/signOutSlice';

interface SignOutRouteProps {
  children: React.ReactNode;
}

const SignOutRoute: React.FC<SignOutRouteProps> = ({ children }) => {
  const dispatch = useAppDispatch();

  const { value } = useAppSelector((state) => state.auth);

  const isSignedIn = value?.data?.token?.accessToken;

  useEffect(() => {
    if (isSignedIn) {
      dispatch(signOut());
    }
  }, [dispatch, isSignedIn]);

  return <>{children}</>;
};

export default SignOutRoute;
