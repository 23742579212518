import { Box, Text } from '@chakra-ui/react';

interface CustomDrawerHeaderProps {
  children: string;
  startsFromBeginning?: boolean;
}

export default function CustomDrawerHeader({
  children,
  startsFromBeginning,
}: CustomDrawerHeaderProps) {
  return (
    <Box
      position="absolute"
      top="16px"
      left={startsFromBeginning ? '24px' : '80px'}
      alignItems="center"
      background="#fff"
      zIndex={2}
      width={{ base: '300px', sm: '350px' }}
    >
      <Text
        fontWeight={500}
        fontSize="1.125rem"
        lineHeight="178%"
        fontFamily="Aeonik"
        noOfLines={1}
      >
        {children}
      </Text>
    </Box>
  );
}
