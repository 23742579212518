import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';

interface ICreatePhoneVerificationRequest {
  phoneNumber: string;
  type: string;
  deliveryType: string;
}

export interface ICreatePhoneVerificationResponse {
  success?: boolean;
  message?: string;
  data?: {
    id?: string;
    phoneNumber?: string;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<ICreatePhoneVerificationResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const createPhoneVerificationAsync = createAsyncThunk(
  'phoneVerification/create',
  async (
    { phoneNumber, type, deliveryType }: ICreatePhoneVerificationRequest,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.post('/verification/phonenumber', {
        phoneNumber,
        type,
        deliveryType,
      });

      return data;
    } catch (err) {
      const error = err as AxiosError<ICreatePhoneVerificationResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const phoneVerificationCreationSlice = createSlice({
  name: 'phoneVerification/create',
  initialState,
  reducers: {
    resetPhoneVerificationCreation: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPhoneVerificationAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(createPhoneVerificationAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as ICreatePhoneVerificationResponse;
      })
      .addCase(createPhoneVerificationAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as ICreatePhoneVerificationResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetPhoneVerificationCreation } =
  phoneVerificationCreationSlice.actions;

export default phoneVerificationCreationSlice.reducer;
