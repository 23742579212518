import { HStack, Text, Tooltip } from '@chakra-ui/react';

import { CheckIcon, ExclamationCircleIcon } from '../../icons';

export interface CustomFlagProps {
  flag?:
    | 'Match'
    | 'Lookup Failed'
    | 'Validation Error'
    | 'Duplicated'
    | 'Missing'
    | 'Incorrect'
    | 'Error'
    | '';
  info?: string;
}

export default function CustomFlag({ flag = '', info }: CustomFlagProps) {
  let Icon;

  if (flag === 'Match') {
    Icon = <CheckIcon />;
  } else if (flag === 'Lookup Failed') {
    Icon = <ExclamationCircleIcon />;
  } else if (flag === 'Duplicated') {
    Icon = <ExclamationCircleIcon fill="#6D45E5" />;
  } else if (flag === 'Missing' || flag === 'Incorrect') {
    Icon = <ExclamationCircleIcon fill="#39ACE5" />;
  } else if (flag === 'Error' || flag === 'Validation Error') {
    Icon = <ExclamationCircleIcon fill="#DB4343" />;
  }

  return (
    <Tooltip label={info} hasArrow sx={{ display: info ? 'inherit' : 'none' }}>
      <HStack spacing={1} alignItems="center">
        <Text fontSize=".75rem" lineHeight="200%">
          {flag}
        </Text>
        {Icon}
      </HStack>
    </Tooltip>
  );
}
