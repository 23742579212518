import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IDeleteBeneficiaryRequest {
  beneficiaryId: string;
  accountNumber: string;
}
interface IDeleteBeneficiaryResponse {
  status?: boolean;
  message?: string;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IDeleteBeneficiaryResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const deleteBeneficiaryAsync = createAsyncThunk(
  'beneficiary/delete',
  async (
    { beneficiaryId, accountNumber }: IDeleteBeneficiaryRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId =
        localStorage.getItem('businessId') ||
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
        data: { accountNumber },
      };

      const { data } = await api.delete<IDeleteBeneficiaryResponse>(
        `/beneficiaries/${beneficiaryId}/remove-account`,
        config
      );

      setTimeout(() => {
        window.location.reload();
      }, 2500);

      return data;
    } catch (err) {
      const error = err as AxiosError<IDeleteBeneficiaryResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const beneficiaryDeleteSlice = createSlice({
  name: 'beneficiary/delete',
  initialState,
  reducers: {
    resetBeneficiaryDelete(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteBeneficiaryAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(deleteBeneficiaryAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IDeleteBeneficiaryResponse;
      })
      .addCase(deleteBeneficiaryAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IDeleteBeneficiaryResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetBeneficiaryDelete } = beneficiaryDeleteSlice.actions;

export default beneficiaryDeleteSlice.reducer;
