import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IRepresentativeAddressRequest {
  streetAddress: string;
  landmark: string;
  city: string;
  state: string;
  proofOfAddress: string;
  proofOfAddressType: string;
}

interface IRepresentativeAddressResponse {
  success?: boolean;
  message?: string;
  data?: unknown;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IRepresentativeAddressResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const representativeAddressAsync = createAsyncThunk(
  'representativeAddress/create',
  async (
    values: IRepresentativeAddressRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };

      const { data } = await api.post(
        '/representative-address-verification',
        { ...values, lga: values.city, country: 'Nigeria' },
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IRepresentativeAddressResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const representativeAddressCreationSlice = createSlice({
  name: 'representativeAddress/create',
  initialState,
  reducers: {
    resetRepresentativeAddress: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(representativeAddressAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(representativeAddressAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IRepresentativeAddressResponse;
      })
      .addCase(representativeAddressAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IRepresentativeAddressResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetRepresentativeAddress } =
  representativeAddressCreationSlice.actions;

export default representativeAddressCreationSlice.reducer;
