import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import LandingLayout from '../layouts/LandingLayout';
import MinimalSideLayout from '../layouts/MinimalSideLayout';
import Loadable from './Loadable';
import PublicRoute from './PublicRoute';

// Lazy Imports

// Landing Page
const AuthLandingPage = Loadable(
  lazy(() => import('../pages/onboardingPages/AuthLandingPage'))
);

// Route Object
const LandingRoutes: RouteObject = {
  path: '/',
  element: (
    <PublicRoute>
      <MinimalSideLayout />
    </PublicRoute>
  ),
  children: [
    {
      index: true,
      element: <AuthLandingPage />,
    },
  ],
};

export default LandingRoutes;
