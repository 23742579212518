import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { IDirectReferralsCommission } from '../../../types/commission';
import { RootState } from '../..';

export interface IGetReferralsCommissionsRequest {
  commissionNumber: string;
  limit?: number;
}

export interface IGetReferralsCommissionsResponse {
  status?: boolean;
  message?: string;
  data?: {
    transactions?: IDirectReferralsCommission[];
    page: number;
    dataSize: number;
    total: number;
  };
  errors?: IApiResponseError;
}

const initialState: IApiState<IGetReferralsCommissionsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getReferralsCommissionsAsync = createAsyncThunk(
  'referralsCommissions/get',
  async (
    { commissionNumber, limit = 10 }: IGetReferralsCommissionsRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get(
        `/commission/transactions/${commissionNumber}?channel=merchant_retail&limit=${limit}`,
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IGetReferralsCommissionsResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const referralsCommissionsFetch = createSlice({
  name: 'referralsCommissions/fetch',
  initialState,
  reducers: {
    resetReferralsCommissions: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReferralsCommissionsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getReferralsCommissionsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetReferralsCommissionsResponse;
      })
      .addCase(getReferralsCommissionsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetReferralsCommissionsResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetReferralsCommissions } = referralsCommissionsFetch.actions;
export default referralsCommissionsFetch.reducer;
