import { extendTheme } from "@chakra-ui/react";

import colors from "./colors";
import components from "./components";
import fonts from "./fonts";
import shadows from "./shadows";
import styles from "./styles";

const theme = extendTheme({
  colors,
  components,
  fonts,
  shadows,
  styles,
});

export default theme;
