import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

const countriesFromStorage = sessionStorage.getItem('gift-card-countries');
const parsedCountriesFromStorage = countriesFromStorage
  ? JSON.parse(countriesFromStorage)
  : null;

export interface IGetCountriesResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    name?: string;
    code?: string;
    created_at?: string;
    updated_at?: string;
    currency_code?: string;
    currency_symbol?: string;
    flag_url?: string;
  }[];
  code?: number;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetCountriesResponse> = {
  value: parsedCountriesFromStorage,
  status: 'idle',
  error: '',
};

export const getCountriesAsync = createAsyncThunk(
  'giftCards/countries',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get(`/gift-card/countries`, config);

      sessionStorage.setItem('gift-card-countries', JSON.stringify(data));

      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const countriesGetSlice = createSlice({
  name: 'giftCards/countries/get',
  initialState,
  reducers: {
    resetGetCountries: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';

      sessionStorage.removeItem('gift-card-countries');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountriesAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getCountriesAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetCountriesResponse;
      })
      .addCase(getCountriesAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetCountriesResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetCountries } = countriesGetSlice.actions;

export default countriesGetSlice.reducer;
