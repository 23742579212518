import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { OnboardingProvider } from '../contexts/OnboardingContext';
import { MinimalLayout } from '../layouts';
import Loadable from './Loadable';
import PublicRoute from './PublicRoute';

// Lazy Imports
const LoginPage = Loadable(
  lazy(() => import('../pages/onboardingPages/LoginPage'))
);
const SignUpPage = Loadable(
  lazy(() => import('../pages/onboardingPages/SignUpPage'))
);
const NotFoundPage = Loadable(lazy(() => import('../pages/NotFoundPage')));
const CreateNewAccountPage = Loadable(
  lazy(() => import('../pages/onboardingPages/CreateNewAccountPage'))
);
const SignUpOTPPage = Loadable(
  lazy(() => import('../pages/onboardingPages/SignUpOTPPage'))
);
const VideoVerificationPage = Loadable(
  lazy(() => import('../pages/onboardingPages/VideoVerificationPage'))
);
const CreatePasscodePage = Loadable(
  lazy(() => import('../pages/onboardingPages/CreatePasscodePage'))
);
const CreateTransactionPinPage = Loadable(
  lazy(() => import('../pages/onboardingPages/CreateTransactionPinPage'))
);
const WelcomePage = Loadable(
  lazy(() => import('../pages/onboardingPages/WelcomePage'))
);

const RequestResetPasscodePage = Loadable(
  lazy(() => import('../pages/onboardingPages/RequestResetPasscodePage'))
);
const ResetPasscodePage = Loadable(
  lazy(() => import('../pages/onboardingPages/ResetPasscodePage'))
);
const TeamMemberVideoVerificationForm = Loadable(
  lazy(
    () => import('../modules/InvitedTeamMember/TeamMemberVideoVerificationForm')
  )
);
const TeamMemberPasscode = Loadable(
  lazy(() => import('../modules/InvitedTeamMember/TeamMemberPasscode'))
);
const LoginWithRetailPage = Loadable(
  lazy(() => import('../pages/onboardingPages/LoginWithRetailPage'))
);
const CreateMerchantPasscodePage = Loadable(
  lazy(() => import('../pages/onboardingPages/CreateMerchantPasscodePage'))
);
const RegisterPage = Loadable(
  lazy(() => import('../pages/onboardingPages/RegisterPage'))
);

const DeviceBindingPage = Loadable(
  lazy(() => import('../pages/onboardingPages/DeviceBindingPage'))
);

const ResetPasscodeMobilePage = Loadable(
  lazy(() => import('../pages/onboardingPages/ResetPasscodeMobilePage'))
);

// Route Object
const SignUpRoutes: RouteObject = {
  path: '/auth',
  element: (
    <PublicRoute>
      <OnboardingProvider>
        <MinimalLayout />
      </OnboardingProvider>
    </PublicRoute>
  ),
  children: [
    {
      index: true,
      element: <LoginPage />,
    },
    {
      path: '/auth/sign-up',
      children: [
        {
          index: true,
          // element: <SignUpPage />,
          element: <CreateNewAccountPage />,
        },
        {
          path: '/auth/sign-up/create-new-account',
          element: <CreateNewAccountPage />,
        },
        {
          path: '/auth/sign-up/otp',
          element: <SignUpOTPPage />,
        },
        {
          path: '/auth/sign-up/video-verification',
          element: <VideoVerificationPage />,
        },
        {
          path: '/auth/sign-up/create-passcode',
          element: <CreatePasscodePage />,
        },
        {
          path: '/auth/sign-up/login-with-retail',
          // element: <LoginWithRetailPage />,
          element: <CreateNewAccountPage />,
        },
        {
          path: '/auth/sign-up/create-merchant-passcode',
          element: <CreateMerchantPasscodePage />,
        },
        {
          path: '/auth/sign-up/create-transaction-pin',
          element: <CreateTransactionPinPage />,
        },
        {
          path: '/auth/sign-up/welcome',
          element: <WelcomePage />,
        },
      ],
    },
    {
      path: '/auth/register',
      element: <RegisterPage />,
    },
    {
      path: '/auth/reset-passcode',
      element: <RequestResetPasscodePage />,
    },
    {
      path: '/auth/passcode-reset/:resetToken',
      element: <ResetPasscodePage />,
    },
    {
      path: '/auth/device-binding',
      element: <DeviceBindingPage />,
    },
    {
      path: '/auth/reset-passcode-mobile',
      element: <ResetPasscodeMobilePage />,
    },
    {
      path: '/auth/invites/video-verification',
      element: <TeamMemberVideoVerificationForm />,
    },
    {
      path: '/auth/invites/create-passcode',
      element: <TeamMemberPasscode />,
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ],
};

export default SignUpRoutes;
