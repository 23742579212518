import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { ITeamMember } from '../../../types/teamMember';
import { RootState } from '../..';
import { getOutletsAsync } from './getOutlets';

interface IDeleteOutletRequest {
  outletId: string;
}

interface IDeleteOutletResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    name?: string;
    address?: string;
    city?: string;
    state?: string;
    isActive?: boolean;
    createdAt?: string;
    updatedAt?: string;
    teamMembers?: ITeamMember[];
  };

  errors?: IApiResponseError[];
}

const initialState: IApiState<IDeleteOutletResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const deleteOutletAsync = createAsyncThunk(
  'outlet/delete',
  async (
    { outletId }: IDeleteOutletRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.delete<IDeleteOutletResponse>(
        `/outlets/${outletId}`,
        config
      );

      dispatch(getOutletsAsync());

      return data;
    } catch (err) {
      const error = err as AxiosError<IDeleteOutletResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const outletDeletionSlice = createSlice({
  name: 'outlet/delete',
  initialState,
  reducers: {
    resetOutletDeletion(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteOutletAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(deleteOutletAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IDeleteOutletResponse;
      })
      .addCase(deleteOutletAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IDeleteOutletResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetOutletDeletion } = outletDeletionSlice.actions;

export default outletDeletionSlice.reducer;
