import { DrawerCloseButton } from "@chakra-ui/react";

import PrimaryButton, {
  PrimaryButtonProps,
} from "../CustomButtons/PrimaryButton";
import CustomDrawerFooter from "./CustomDrawerFooter";

interface CustomDrawerClosePrimaryButtonProps extends PrimaryButtonProps {
  children: string;
}

export default function CustomDrawerClosePrimaryButton({
  children,
  ...rest
}: CustomDrawerClosePrimaryButtonProps) {
  return (
    <CustomDrawerFooter>
      <PrimaryButton
        as={DrawerCloseButton}
        w="full"
        top="initial" // reset DrawerCloseButton positioning
        right="initial"
        {...rest}
      >
        {children}
      </PrimaryButton>
    </CustomDrawerFooter>
  );
}
