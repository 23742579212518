const Input = {
  baseStyle: {
    field: {
      borderColor: "#ebedef",
      padding: "8px 24px",
      minHeight: "56px",
      borderWidth: 1,
      borderRadius: "14px",
      fontFamily: "Aeonik",
      fontSize: ".875rem",
      lineHeight: "1.25rem",
      ":focus": {
        borderColor: "green.500",
        boxShadow: "0 0 0 1px #14CC52",
        fontSize: ".875rem",
        borderRadius: "14px",
      },
      "::placeholder": {
        fontSize: ".875rem",
        color: "gray.200",
      },
      ":not(:placeholder-shown)": {
        fontSize: ".875rem",
        borderRadius: "14px",
      },
      ":not(:focus)": {
        borderRadius: "14px",
      },
      _invalid: {
        borderColor: "red.600",
        "&:focus": {
          boxShadow: "0 0 0 1px #db4343",
        },
      },
    },
  },
  sizes: {},
  variants: {
    rounded: {
      field: {
        borderColor: "transparent",
        background: "#F9FAFA",
        borderRadius: "24px",
        minHeight: "44px",
        padding: "12px 8px",
        lineHeight: "150%",
        ":focus": {
          borderRadius: "24px",
          boxShadow: "0",
        },
        "::placeholder": {
          letterSpacing: "-0.12px",
          color: "gray.300",
        },
        ":not(:placeholder-shown), :not(:focus)": {
          borderRadius: "24px",
        },
      },
    },
  },
  defaultProps: {
    variant: null,
  },
};

export default Input;
