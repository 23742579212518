import { Button, ButtonProps } from '@chakra-ui/react';

export interface PrimaryButtonProps extends ButtonProps {
  children: string;
  sx?: React.CSSProperties;
  background?: string;
  color?: string;
  border?: string;
  hoverBackground?: string;
  hoverOpacity?: number;
  disableElevation?: boolean;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  children,
  sx,
  background = 'gray.500',
  color = 'white',
  border = '1px solid #1a1c1f',
  hoverBackground = 'gray.500',
  hoverOpacity = 0.98,
  disableElevation,
  ...rest
}) => {
  return (
    <Button
      sx={{
        background,
        border,
        color,
        height: '48px',
        boxShadow: disableElevation
          ? 'initial'
          : 'inset 0px 2px 0px rgba(234, 234, 234, 0.25)',
        borderRadius: '12px',
        fontWeight: '400',
        lineHeight: '23px',
        letterSpacing: '-0.12px',
        '&:disabled': {
          background: 'ash.200',
          color: 'ash.600',
          opacity: 1,
          border: 'initial',
        },
        '&:hover': {
          background: hoverBackground,
          opacity: hoverOpacity,
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
