import { Flex, ResponsiveValue } from '@chakra-ui/react';

import { isiOS } from '../../../helpers/checkDevice';

interface CustomDrawerFooterProps {
  children: React.ReactNode;
  direction?: ResponsiveValue<
    'row' | 'column' | 'column-reverse' | 'row-reverse'
  >;
}

export default function CustomDrawerFooter({
  children,
  direction,
}: CustomDrawerFooterProps) {
  return (
    <Flex
      direction={direction}
      bg="#fff"
      borderRadius="0px 0px 24px 24px"
      p="6"
      width="100%"
      pos="absolute"
      left="0"
      right="0"
      bottom={isiOS() ? '70px' : '0'}
      minHeight="96px"
      overflowY="auto"
      zIndex="9000"
    >
      {children}
    </Flex>
  );
}
