import {
  Action,
  AnyAction,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';

import {
  accountBalanceFetchReducer,
  accountsFetchReducer,
  transactionHistoryReducer,
} from './slices/account';
import {
  limitByBusinessReducer,
  limitListReducer,
  updateTransactionLimitReducer,
} from './slices/accountLimits';
import { businessDirectorCreationReducer } from './slices/addDirector';
import { cddResponsesReducer } from './slices/addDirector';
import {
  getDocumentRequestReducer,
  uploadAdditionalDocumentsReducer,
} from './slices/additionalDocument';
import {
  addBusinessToAggregatorReducer,
  getAggregateMerchantsTransactionsReducer,
  getAggregateMerchantTransactionsReducer,
  getAggregatorByCodeReducer,
  getAggregatorMerchantsReducer,
  getAggregatorQRCodeReducer,
  getAggregatorStatsReducer,
  inviteBusinessToAggregatorNetworkReducer,
} from './slices/aggregator';
import {
  getBusinessByIdReducer,
  resetSecretKeyReducer,
  updateWebhookReducer,
} from './slices/apiKeys';
import appToastReducer from './slices/appToast/appToastSlice';
import {
  accessTokenExpirationReducer,
  authReducer,
  checkIfUserEmailExistsReducer,
  checkIfUserPhoneExistsReducer,
  devAuthReducer,
  oAuthLoginReducer,
  onboardExistingBusinessOwnerReducer,
  refreshTokenReducer,
  registerRetailUserReducer,
  userRegistrationReducer,
} from './slices/auth';
import {
  deleteBeneficiaryReducer,
  saveBeneficiaryReducer,
} from './slices/beneficiaries';
import {
  clientIdValidationReducer,
  getDepositsTransactionsReducer,
  processPaymentReducer,
} from './slices/bet9jaDeposits';
import { getWalletTransactionsReducer } from './slices/betting';
import {
  billersCategoriesReducer,
  billsPaymentReducer,
  billTransactionsReducer,
  customerLookupReducer,
} from './slices/billsPayment';
import {
  businessCategoriesFetchReducer,
  businessCreationReducer,
  businessDeletionReducer,
  businessDocumentCreationReducer,
  businessRepresentativeCreationReducer,
  saveBusinessReducer,
  userBusinessesFetchReducer,
  userDetailsForBusinessFetchReducer,
} from './slices/business';
import { bvnVerificationCreationReducer } from './slices/bvnVerification';
import {
  getAggregatorReferralsCommissionsReducer,
  getCommissionBalanceReducer,
  getCommissionOverviewReducer,
  getEarnedCommissionsReducer,
  getReferralsCommissionsReducer,
  getSecondaryReferralsCommissionsReducer,
  getWithdrawnCommissionsReducer,
  withdrawCommissionReducer,
} from './slices/commissions';
import { environmentReducer } from './slices/devPlatformEnvironment';
import {
  checkSlugExistReducer,
  optInReducer,
} from './slices/devPlatformGetStarted';
import {
  emailVerificationCreationReducer,
  emailVerificationVerifyReducer,
} from './slices/emailVerification';
import {
  bookFlightReducer,
  getAirportsReducer,
  getFlightTransactionsReducer,
  getSingleFlightReducer,
  searchFlightsReducer,
} from './slices/flights';
import {
  getActiveGiftCardProviderByIdReducer,
  getActiveGiftCardProvidersReducer,
  getAllCountriesReducer,
  getAllGiftCardsReducer,
  // getAllGiftCardsReducer,
  getCountryByIdReducer,
  getCustomersTransactionsReducer,
  getGiftCardPricesReducer,
  // getGiftCardPricesReducer,
  makeGiftCardPaymentsReducer,
} from './slices/giftCards';
import {
  approveRepresentativeReducer,
  createIdVerificationReducer,
  getCDDQuestionsReducer,
  getKybDetailsReducer,
  getRepresentativeDetailsReducer,
  kybGetByIdReducer,
  ninVerificationReducer,
  representativeAddressReducer,
  submitCDDQuestionsReducer,
} from './slices/kyb';
import { kycGetByIdReducer } from './slices/kyc';
import { citiesFetchReducer, statesFetchReducer } from './slices/location';
import {
  createOutletReducer,
  deleteOutletReducer,
  disableOutletReducer,
  enableOutletReducer,
  getOutletsReducer,
} from './slices/outlet';
import { permissionsFetchReducer } from './slices/permissions';
import {
  phoneVerificationCreationReducer,
  phoneVerificationVerifyReducer,
} from './slices/phoneNumberVerification';
import { setPinReducer } from './slices/pin';
import {
  generateReferralCodeReducer,
  getReferralCommissionStatsReducer,
  getReferralHistoryReducer,
  getReferralStatsReducer,
  inviteRefereeByMailReducer,
  promptRefereeReducer,
} from './slices/referrals';
import {
  requestResetPasscodeReducer,
  resetPasscodeReducer,
} from './slices/resetPasscode';
import {
  roleCreationReducer,
  roleDeleteReducer,
  rolesFetchReducer,
  roleUpdateReducer,
} from './slices/roles';
import { changePinReducer, resetPinReducer } from './slices/security';
import {
  acceptTeamMemberReducer,
  activateTeamMemberReducer,
  deactivateTeamMemberReducer,
  deleteTeamMemberReducer,
  getTeamMemberInviteDetailsReducer,
  getTeamMembersReducer,
  inviteTeamMemberReducer,
  updateRoleReducer,
  updateTeamMemberReducer,
} from './slices/teamMembers';
import {
  approveTransactionReducer,
  createTransactionMandateReducer,
  declineTransactionReducer,
  deleteTransactionMandateReducer,
  disableTransactionMandateReducer,
  enableTransactionMandateReducer,
  generateAccountStatementReducer,
  getPendingTransactionsForApprovalReducer,
  getPendingTransactionsForInitiatorReducer,
  getTransactionCategoriesReducer,
  getTransactionMandatesReducer,
  updateTransactionMandateReducer,
} from './slices/transactionMandate';
import {
  cancelScheduledTransferReducer,
  getBanksReducer,
  getBulkTransfersReducer,
  getScheduledTransfersReducer,
  getSingleTransfersReducer,
  initiateBulkTransferReducer,
  initiateMultipleTransferReducer,
  initiateScheduledTransferReducer,
  initiateTransferReducer,
  nameEnquiryReducer,
  pauseScheduledTransferReducer,
  resumeScheduledTransferReducer,
  updateScheduledTransferReducer,
} from './slices/transfer';
import { userProfileReducer } from './slices/user';
import {
  createPermanentVAccountReducer,
  createTransientVAccountReducer,
  getVirtualAccountsReducer,
  getVirtualAccountTransactionsReducer,
} from './slices/virtualAccounts';

const reducers = {
  appToast: appToastReducer,
  auth: authReducer,
  devAuth: devAuthReducer,
  accountBalance: accountBalanceFetchReducer,
  accessTokenExpiration: accessTokenExpirationReducer,
  refreshToken: refreshTokenReducer,
  accounts: accountsFetchReducer,
  businessCategoriesFetch: businessCategoriesFetchReducer,
  businessCreation: businessCreationReducer,
  businessDocumentCreation: businessDocumentCreationReducer,
  businessRepresentativeCreation: businessRepresentativeCreationReducer,
  bvnVerificationCreation: bvnVerificationCreationReducer,
  citiesFetch: citiesFetchReducer,
  emailVerificationCreation: emailVerificationCreationReducer,
  emailVerificationVerify: emailVerificationVerifyReducer,
  kybGetById: kybGetByIdReducer,
  kycGetById: kycGetByIdReducer,
  permissionsFetch: permissionsFetchReducer,
  phoneVerificationCreation: phoneVerificationCreationReducer,
  phoneVerificationVerify: phoneVerificationVerifyReducer,
  requestResetPasscode: requestResetPasscodeReducer,
  resetPasscode: resetPasscodeReducer,
  roleCreation: roleCreationReducer,
  roleDelete: roleDeleteReducer,
  rolesFetch: rolesFetchReducer,
  roleUpdate: roleUpdateReducer,
  saveBusiness: saveBusinessReducer,
  statesFetch: statesFetchReducer,
  transactionHistory: transactionHistoryReducer,
  userBusinesses: userBusinessesFetchReducer,
  userRegistration: userRegistrationReducer,
  billerCategories: billersCategoriesReducer,
  billsPayment: billsPaymentReducer,
  billTransactions: billTransactionsReducer,
  customerLookup: customerLookupReducer,
  banksFetch: getBanksReducer,
  nameEnquiry: nameEnquiryReducer,
  setPin: setPinReducer,
  initiateTransfer: initiateTransferReducer,
  createOutlet: createOutletReducer,
  deleteOutlet: deleteOutletReducer,
  disableOutlet: disableOutletReducer,
  enableOutlet: enableOutletReducer,
  getOutlets: getOutletsReducer,
  userDetailsForBusinessFetch: userDetailsForBusinessFetchReducer,
  getTeamMembers: getTeamMembersReducer,
  inviteTeamMember: inviteTeamMemberReducer,
  activateTeamMember: activateTeamMemberReducer,
  deactivateTeamMember: deactivateTeamMemberReducer,
  deleteTeamMember: deleteTeamMemberReducer,
  updateTeamMember: updateTeamMemberReducer,
  getTeamMemberInviteDetails: getTeamMemberInviteDetailsReducer,
  acceptTeamMember: acceptTeamMemberReducer,
  getRepresentativeDetails: getRepresentativeDetailsReducer,
  approveRepresentative: approveRepresentativeReducer,
  getTransactionCategories: getTransactionCategoriesReducer,
  createTransactionMandate: createTransactionMandateReducer,
  getTransactionMandates: getTransactionMandatesReducer,
  enableTransactionMandate: enableTransactionMandateReducer,
  disableTransactionMandate: disableTransactionMandateReducer,
  deleteTransactionMandate: deleteTransactionMandateReducer,
  getPendingTransactionsForApproval: getPendingTransactionsForApprovalReducer,
  approveTransaction: approveTransactionReducer,
  declineTransaction: declineTransactionReducer,
  oAuthLogin: oAuthLoginReducer,
  registerRetailUser: registerRetailUserReducer,
  updateTransactionMandate: updateTransactionMandateReducer,
  createIdVerification: createIdVerificationReducer,
  clientIdValidation: clientIdValidationReducer,
  processPayment: processPaymentReducer,
  getDepositsTransactions: getDepositsTransactionsReducer,
  initiateMultipleTransfer: initiateMultipleTransferReducer,
  initiateBulkTransfer: initiateBulkTransferReducer,
  initiateScheduledTransfer: initiateScheduledTransferReducer,
  getCommissionBalance: getCommissionBalanceReducer,
  getAggregatorByCode: getAggregatorByCodeReducer,
  addBusinessToAggregator: addBusinessToAggregatorReducer,
  inviteBusinessToAggregatorNetwork: inviteBusinessToAggregatorNetworkReducer,
  getAggregateMerchantsTransactions: getAggregateMerchantsTransactionsReducer,
  getAggregateMerchantTransactions: getAggregateMerchantTransactionsReducer,
  getWithdrawnCommissions: getWithdrawnCommissionsReducer,
  getEarnedCommissions: getEarnedCommissionsReducer,
  limitList: limitListReducer,
  limitByBusiness: limitByBusinessReducer,
  updateTransactionLimit: updateTransactionLimitReducer,
  withdrawCommission: withdrawCommissionReducer,
  getAggregatorStats: getAggregatorStatsReducer,
  getAggregatorMerchants: getAggregatorMerchantsReducer,
  getCommissionOverview: getCommissionOverviewReducer,
  generateReferralCode: generateReferralCodeReducer,
  getReferralStats: getReferralStatsReducer,
  getReferralHistory: getReferralHistoryReducer,
  promptReferee: promptRefereeReducer,
  getDocumentRequest: getDocumentRequestReducer,
  inviteRefereeByMail: inviteRefereeByMailReducer,
  uploadAdditionalDocuments: uploadAdditionalDocumentsReducer,
  getKybDetails: getKybDetailsReducer,
  getCDDQuestions: getCDDQuestionsReducer,
  submitCDDQuestions: submitCDDQuestionsReducer,
  getReferralsCommissions: getReferralsCommissionsReducer,
  getReferralCommissionStats: getReferralCommissionStatsReducer,
  changePin: changePinReducer,
  resetPin: resetPinReducer,
  checkIfUserEmailExists: checkIfUserEmailExistsReducer,
  checkIfUserPhoneExists: checkIfUserPhoneExistsReducer,
  onboardExistingBusinessOwner: onboardExistingBusinessOwnerReducer,
  getPendingTransactionsForInitiator: getPendingTransactionsForInitiatorReducer,
  generateAccountStatement: generateAccountStatementReducer,
  userProfile: userProfileReducer,
  getSecondaryReferralsCommissions: getSecondaryReferralsCommissionsReducer,
  getAggregatorReferralsCommissions: getAggregatorReferralsCommissionsReducer,
  getBulkTransfers: getBulkTransfersReducer,
  getScheduledTransfers: getScheduledTransfersReducer,
  pauseScheduledTransfer: pauseScheduledTransferReducer,
  resumeScheduledTransfer: resumeScheduledTransferReducer,
  cancelScheduledTransfer: cancelScheduledTransferReducer,
  updateScheduledTransfer: updateScheduledTransferReducer,
  getSingleTransfers: getSingleTransfersReducer,
  getAirports: getAirportsReducer,
  getFlightTransactions: getFlightTransactionsReducer,
  getAggregatorQRCode: getAggregatorQRCodeReducer,
  searchFlights: searchFlightsReducer,
  getSingleFlight: getSingleFlightReducer,
  bookFlight: bookFlightReducer,
  businessDeletion: businessDeletionReducer,
  getAllCountries: getAllCountriesReducer,
  getCountryById: getCountryByIdReducer,
  getActiveGiftCardProviders: getActiveGiftCardProvidersReducer,
  makeGiftCardPayments: makeGiftCardPaymentsReducer,
  getCustomerTransactions: getCustomersTransactionsReducer,
  getActiveGiftCardProviderById: getActiveGiftCardProviderByIdReducer,
  ninVerification: ninVerificationReducer,
  representativeAddress: representativeAddressReducer,
  getVirtualAccounts: getVirtualAccountsReducer,
  getVirtualAccountTransactions: getVirtualAccountTransactionsReducer,
  createPermanentVAccount: createPermanentVAccountReducer,
  createTransientVAccount: createTransientVAccountReducer,
  saveBeneficiary: saveBeneficiaryReducer,
  getWalletTransactions: getWalletTransactionsReducer,
  developerOptIn: optInReducer,
  environment: environmentReducer,
  getAllGiftCards: getAllGiftCardsReducer,
  getGiftCardPrices: getGiftCardPricesReducer,
  checkSlugExists: checkSlugExistReducer,
  getBusinessById: getBusinessByIdReducer,
  resetSecretKey: resetSecretKeyReducer,
  updateWebhook: updateWebhookReducer,
  beneficiaryDelete: deleteBeneficiaryReducer,
  updateRole: updateRoleReducer,
  createBusinessDirector: businessDirectorCreationReducer,
  cddResponses: cddResponsesReducer,
};

const rootReducer = combineReducers(reducers);

const appReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === 'RESET_STORE') {
    return combineReducers(reducers)(undefined, action);
  }

  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: appReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
