import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { MinimalLayout } from '../layouts';
import Loadable from './Loadable';
import SignOutRoute from './SignOutRoute';

const InvitedTeamMemberPage = Loadable(
  lazy(() => import('../pages/onboardingPages/InvitedTeamMemberPage'))
);
const InvitedBusinessOwnerPage = Loadable(
  lazy(() => import('../pages/onboardingPages/InvitedBusinessOwnerPage'))
);

const SignOutRoutes: RouteObject = {
  path: '/auth/invites/',
  element: (
    <SignOutRoute>
      <MinimalLayout />
    </SignOutRoute>
  ),
  children: [
    {
      path: '/auth/invites/:id/accept',
      element: <InvitedTeamMemberPage />,
    },
    {
      path: '/auth/invites/invited-owner',
      element: <InvitedBusinessOwnerPage />,
    },
  ],
};

export default SignOutRoutes;
