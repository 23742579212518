import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { devApi, prodDevApi } from '../../../api/axios';
import { IFilterProps } from '../../../modules/VirtualAccounts/Overview/AccountActivities/VirtualAccountsCard';
import { IApiResponseError, IApiState } from '../../../types/api';
import { IVirtualAccountsTable } from '../../../types/virtualAccount';
import { RootState } from '../..';

interface IGetVirtualAccountsRequest {
  page?: number;
  filter?: {
    permanent?: boolean;
    transient?: boolean;
  };
}

interface IGetVirtualAccountsResponse {
  totalCount?: number;
  totalPermanentAccountCount: number;
  totalTransientAccountCount: number;
  status?: boolean;
  message?: string;
  data?: IVirtualAccountsTable[];
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetVirtualAccountsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getVirtualAccountsAsync = createAsyncThunk(
  'virtualAccounts',
  async (
    { page = 1, filter }: IGetVirtualAccountsRequest & { filter: IFilterProps },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const devTokenInStorage = localStorage.getItem('devTokenData');

      const parsedDevTokenInStorage = devTokenInStorage
        ? JSON.parse(devTokenInStorage)
        : null;

      const developerId = localStorage.getItem('developerId');

      const environment = state.environment?.value;

      const apiInstance = environment === 'live' ? prodDevApi : devApi;

      const authToken =
        environment === 'live' ? accessToken : parsedDevTokenInStorage;

      let url = `/virtual-accounts?page-size=10&page-number=${page}`;

      if (filter) {
        const { permanent, transient } = filter;

        if (!(permanent && transient)) {
          const accountTypes = [];

          if (permanent) accountTypes.push('permanent');
          if (transient) accountTypes.push('transient');

          if (accountTypes.length > 0) {
            url += `&account-type=${accountTypes.join(',')}`;
          }
        }
      }

      const config = {
        headers: {
          Authorization: `Token ${authToken}`,
          'Business-id': developerId,
          Environment: 'live',
        },
      };

      const { data } = await apiInstance.get(url, config);

      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const virtualAccountsGetSlice = createSlice({
  name: 'virtualAccounts/get',
  initialState,
  reducers: {
    resetGetVirtualAccounts: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVirtualAccountsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getVirtualAccountsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetVirtualAccountsResponse;
      })
      .addCase(getVirtualAccountsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetVirtualAccountsResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetVirtualAccounts } = virtualAccountsGetSlice.actions;

export default virtualAccountsGetSlice.reducer;
