import { Box, Text } from '@chakra-ui/react';

import {
  ArrowDownRedIcon,
  ArrowRightIcon,
  ArrowUpGreenIcon,
} from '../../icons';

interface PercentageChangeProps {
  percentageChange?: number;
  currValue: number;
  prevValue?: number;
}

export default function PercentageChange({
  prevValue,
  percentageChange: percentageChangeFromProps,
  currValue,
}: PercentageChangeProps) {
  let percentageChange = 0;

  if (percentageChangeFromProps) {
    percentageChange = percentageChangeFromProps;
  } else if (prevValue !== undefined) {
    const numericPrevValue = Number(prevValue);
    const numericCurrValue = Number(currValue);

    if (numericPrevValue === 0) {
      // Avoid division by zero
      percentageChange =
        numericCurrValue > numericPrevValue
          ? 100
          : numericCurrValue === numericPrevValue
          ? 0
          : -100;
    } else {
      percentageChange =
        ((numericCurrValue - numericPrevValue) / Math.abs(numericPrevValue)) *
          100 || 0;
    }
  }

  let Icon = null;
  let color;

  if (percentageChange < 0) {
    Icon = ArrowDownRedIcon;
    color = '#911d11';
  } else if (percentageChange > 0) {
    Icon = ArrowUpGreenIcon;
    color = '#2c974a';
  } else {
    Icon = ArrowRightIcon;
    color = 'gray.300';
  }

  return (
    <Box display="flex" gap="7.2px">
      <Icon width="17px" height="16px" />
      <Text color={color} fontSize=".875rem" lineHeight="114%">
        {Math.abs(percentageChange).toFixed(2)}%
      </Text>
    </Box>
  );
}
