import { GenerateJWTPayload, initialize } from '@iterable/web-sdk';
import { useCallback } from 'react';

import { decryptData } from '../helpers/encryption';
import { store } from '../store';
import { IUserOnboardingValues } from '../types/user';

export const useInitializeIterable = () => {
  type TUserDetails = Pick<
    IUserOnboardingValues,
    'email' | 'emailVerificationId'
  >;

  const authToken = store.getState().auth.value?.data?.token?.accessToken || '';
  const userEmailFromState = store.getState().auth.value?.data?.user?.email;
  const userEmailObjectFromStorage = localStorage.getItem('userEmail');
  const decryptedEmailObject = userEmailObjectFromStorage
    ? (decryptData(userEmailObjectFromStorage) as TUserDetails)
    : null;
  const userEmailFromStorage = decryptedEmailObject?.email ?? '';
  const userEmail = userEmailFromState || userEmailFromStorage;

  const handleTokenResolve: (payload: GenerateJWTPayload) => Promise<string> =
    useCallback(() => {
      return new Promise((resolve) => {
        resolve(`${authToken}`);
      });
    }, [authToken]);

  const { setEmail } = initialize(
    `${process.env.REACT_APP_ITERABLE_API_KEY}`,
    handleTokenResolve
  );

  const handleSetEmail = (userEmailFromParams?: string) =>
    setEmail(userEmail || userEmailFromParams || '');

  return handleSetEmail;
};
