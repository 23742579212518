const Tabs = {
  variants: {
    line: {
      tab: {
        fontSize: ".875rem",
        color: "gray.200",
        px: 0,
        py: 4,
        mb: 0,
        _selected: {
          color: "#2C974A",
          fontWeight: "700",
        },
        _active: {
          bg: "transparent",
        },
      },
      tablist: {
        borderBottom: "1px solid",
        borderColor: "#e0e3e6",
        gap: 10,
      },
      tabpanels: {
        padding: "34px 0",
      },
      tabpanel: {
        p: 0,
      },
    },
  },
};

export default Tabs;
