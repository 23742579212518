import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { IFlightData } from '../../../types/flight';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';

interface ISearchFlightsRequest {
  trip: string;
  adults: number;
  children: number;
  infants: number;
  ticketType: string;
  boundaries: {
    from: string;
    to: string;
    date: string;
  }[];
}

export interface ISearchFlightsResponseData {
  price?: number;
  flightData?: IFlightData[];
  rules?: string[];
  selectionValue?: string;
}

export interface ISearchFlightsResponse {
  status?: boolean;
  message?: string;
  data?: ISearchFlightsResponseData[];
  errors?: IApiResponseError[];
}

const initialState: IApiState<ISearchFlightsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const searchFlightsAsync = createAsyncThunk(
  'flights/search',
  async (
    values: ISearchFlightsRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.post(
        '/flights/available-flights',
        values,
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<ISearchFlightsResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
      });

      dispatch(showAlert({ status: 'error', description: errorMessage }));

      return rejectWithValue(error.response?.data);
    }
  }
);

export const flightsSearchSlice = createSlice({
  name: 'flights/search',
  initialState,
  reducers: {
    resetSearchFlights: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchFlightsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(searchFlightsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as ISearchFlightsResponse;
      })
      .addCase(searchFlightsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as ISearchFlightsResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetSearchFlights } = flightsSearchSlice.actions;

export default flightsSearchSlice.reducer;
