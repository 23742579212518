import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import api from "../../../api/axios";
import { IApiResponseError, IApiState } from "../../../types/api";

interface ICreateEmailVerificationRequest {
  email: string;
  type: string;
}

interface ICreateEmailVerificationResponse {
  success?: boolean;
  message?: string;
  data?: {
    id: string;
    email: string;
  };
  errors: IApiResponseError[];
}

const initialState: IApiState<ICreateEmailVerificationResponse> = {
  value: null,
  status: "idle",
  error: "",
};

export const createEmailVerificationAsync = createAsyncThunk(
  "emailVerification/create",
  async (
    { email, type }: ICreateEmailVerificationRequest,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.post("/verification/email", {
        email,
        type,
      });

      return data;
    } catch (err) {
      const error = err as AxiosError<ICreateEmailVerificationResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const emailVerificationCreationSlice = createSlice({
  name: "emailVerification/create",
  initialState,
  reducers: {
    resetEmailVerificationCreation: (state) => {
      state.value = null;
      state.status = "idle";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEmailVerificationAsync.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(createEmailVerificationAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.value = action.payload as ICreateEmailVerificationResponse;
      })
      .addCase(createEmailVerificationAsync.rejected, (state, action) => {
        state.status = "failed";
        state.value = action.payload as ICreateEmailVerificationResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetEmailVerificationCreation } =
  emailVerificationCreationSlice.actions;

export default emailVerificationCreationSlice.reducer;
