import React from "react";
import { Helmet } from "react-helmet-async";

interface PageProps {
  children: React.ReactNode;
  helmetChildren?: React.ReactNode;
  title: string;
}

const Page: React.FC<PageProps> = ({ children, title, helmetChildren }) => (
  <>
    <Helmet>
      <title>{title}</title>
      {helmetChildren}
    </Helmet>
    {children}
  </>
);

export default Page;
