import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiState } from '../../../types/api';

interface IVerifyPhoneVerificationRequest {
  phoneNumber: string;
  otp: string;
  id: string;
}

export interface IVerifyPhoneVerificationResponse {
  success?: boolean;
  message?: string;
  data?: {
    id?: string;
    phoneNumber?: string;
    otp?: string;
  };
}

const initialState: IApiState<IVerifyPhoneVerificationResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const verifyPhoneVerificationAsync = createAsyncThunk(
  'phoneVerification/verify',
  async (
    { phoneNumber, otp, id }: IVerifyPhoneVerificationRequest,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.post(
        `/verification/phonenumber/${id}/verify`,
        {
          phoneNumber,
          otp,
        }
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IVerifyPhoneVerificationResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const phoneVerificationVerifySlice = createSlice({
  name: 'phoneVerification/verify',
  initialState,
  reducers: {
    resetPhoneVerificationVerify: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyPhoneVerificationAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(verifyPhoneVerificationAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IVerifyPhoneVerificationResponse;
      })
      .addCase(verifyPhoneVerificationAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IVerifyPhoneVerificationResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetPhoneVerificationVerify } =
  phoneVerificationVerifySlice.actions;

export default phoneVerificationVerifySlice.reducer;
