import { IPermission } from '../types/permission';

/**
 *
 * @param {string} role The user's role
 * @param permissionToCheck The permission to check
 * @param permissions An array of permissions that belongs to the role
 * @returns {boolean} True if the role is allowed to have access, false otherwise
 */
export default function getHasPermission(
  permissionToCheck: string,
  role?: string,
  permissions?: IPermission[]
) {
  if (role?.toLowerCase() === 'admin') return true;

  if (
    permissions
      ?.map((permission) => permission.slug)
      .includes(permissionToCheck)
  ) {
    return true;
  }

  return false;
}
