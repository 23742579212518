import { Box, BoxProps, Text } from "@chakra-ui/react";

interface CustomOptionSelectorProps extends BoxProps {
  options: string[];
  selectedOption: string;
  setSelectedOption: (option: string) => void;
}

export default function CustomOptionSelector({
  options,
  selectedOption,
  setSelectedOption,
  ...rest
}: CustomOptionSelectorProps) {
  return (
    <Box display="flex" gap={6} {...rest}>
      {options.map((option, index) => (
        <Box key={option + index}>
          <Text
            mb={2}
            fontWeight={500}
            fontSize=".75rem"
            lineHeight="133%"
            color={option === selectedOption ? "#001419" : "gray.200"}
            cursor="pointer"
            as="button"
            textTransform="capitalize"
            onClick={() => setSelectedOption(option)}
          >
            {option}
          </Text>

          <Box
            h="2px"
            background="#001419"
            borderRadius="2px 4px 0px 0px"
            display={option === selectedOption ? "block" : "none"}
          />
        </Box>
      ))}
    </Box>
  );
}
