import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import {
  getNinetyDaysBeforeToday,
  getTodayDateInYYYYMMDD,
} from '../../../helpers/utilityFunctions';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { getTransactionHistoryAsync } from '../account/getTransactionHistorySlice';
import { showAlert } from '../appToast/appToastSlice';
import { getCommissionBalanceAsync } from './getCommissionBalanceSlice';

interface IInitiateWithdrawCommissionRequest {
  customerId: string;
  commissionAccountNumber: string;
  recipientAccountName: string;
  recipientAccountNumber: string;
  amount: number;
  pin: string;
}

interface IInitiateWithdrawCommissionResponse {
  success?: boolean;
  message?: string;
  data?: {
    id?: string;
    customerId?: string;
    commissionAccountNumber?: string;
    recipientAccountName?: string;
    recipientAccountNumber?: string;
    narration?: string;
    amount?: number;
    fee?: number;
    transactionDate?: string;
    reference?: string;
    corebankingReference?: string;
    channel?: string;
    status?: string;
    responseMessage?: string;
    responseCode?: string;
    createdAt?: string;
    updatedAt?: string;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IInitiateWithdrawCommissionResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const initiateWithdrawCommissionAsync = createAsyncThunk(
  'withdrawCommission/create',
  async (
    {
      customerId,
      commissionAccountNumber,
      recipientAccountName,
      recipientAccountNumber,
      amount,
      pin,
    }: IInitiateWithdrawCommissionRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };
      const { data } = await api.post(
        '/commission/transactions/withdraw-commission',
        {
          pin,
          customerId,
          commissionAccountNumber,
          recipientAccountName,
          recipientAccountNumber,
          amount: amount * 100,
          narration: 'Earnings Withdrawal',
          channelType: 'merchant',
        },
        config
      );

      const oldAvailableBalanceFromStorage = localStorage.getItem(
        'availableCommissionBalance'
      );
      const parsedAvailableBalance = oldAvailableBalanceFromStorage
        ? (JSON.parse(oldAvailableBalanceFromStorage) as number)
        : null;

      const newAvailableBalance = parsedAvailableBalance
        ? parsedAvailableBalance - amount * 100
        : null;

      if (newAvailableBalance) {
        localStorage.setItem(
          'availableCommissionBalance',
          JSON.stringify(newAvailableBalance)
        );
      }

      const accountNumberFromStorage = localStorage.getItem(
        'commissionsAccountNumber'
      );

      dispatch(getCommissionBalanceAsync(accountNumberFromStorage ?? ''));

      dispatch(
        getTransactionHistoryAsync({
          searchParams: {
            startDate: getNinetyDaysBeforeToday(),
            endDate: getTodayDateInYYYYMMDD(),
            searchField: 'FinancialDate',
          },
        })
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IInitiateWithdrawCommissionResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
      });

      if (error.response?.status === 401) {
        dispatch(
          showAlert({
            status: 'error',
            type: 'drawer',
            description:
              error?.response?.data?.message ??
              'Your PIN is invalid. Please check and try again',
          })
        );
      } else {
        dispatch(
          showAlert({
            status: 'error',
            type: 'drawer',
            description: errorMessage,
          })
        );
      }

      return rejectWithValue(error.response?.data);
    }
  }
);

export const withdrawCommissionInitiationSlice = createSlice({
  name: 'withdrawCommission/initiate',
  initialState,
  reducers: {
    resetInitiateWithdrawCommission: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateWithdrawCommissionAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(initiateWithdrawCommissionAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IInitiateWithdrawCommissionResponse;
      })
      .addCase(initiateWithdrawCommissionAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IInitiateWithdrawCommissionResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetInitiateWithdrawCommission } =
  withdrawCommissionInitiationSlice.actions;

export default withdrawCommissionInitiationSlice.reducer;
