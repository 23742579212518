import { Box, useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { hideAlert } from '../../store/slices/appToast/appToastSlice';

export default function AppToast() {
  const { isOpen, status, title, duration, type, description } = useAppSelector(
    (state) => state.appToast
  );

  const dispatch = useAppDispatch();

  const toast = useToast();

  useEffect(() => {
    if (isOpen) {
      toast({
        title,
        description,
        status,
        isClosable: true,
        duration: duration ?? 6500,
        variant: type === 'drawer' ? 'solid' : 'left-accent',
        position: 'top-right',
        containerStyle:
          type === 'drawer'
            ? {
                marginTop: '80px',
                marginBottom: '-68px',
                marginRight: '16px',
                width: '100%',
                minWidth: { base: 'initial', sm: '476px' },
                maxWidth: '500px',
              }
            : undefined,
        onCloseComplete() {
          dispatch(hideAlert());
        },
      });
    }
  }, [description, dispatch, duration, isOpen, status, title, toast, type]);

  return <Box className="app-toast" />;
}
