import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IGetAggregateMerchantTransactionsRequest {
  merchantId: string;
  duration: number;
  category: string;
}

interface IGetAggregateMerchantTransactionsResponse {
  status?: boolean;
  message?: string;
  data?: {
    value?: number; // `value` and `volume` are returned for filtered categories such as BETTING, TRANSFERS, etc.
    volume?: number;
    totalValue?: number; // `totalValue` and `totalVolume` are returned for all categories and when no category is specified.
    totalVolume?: number;
    transactions: {
      transactionValue?: number;
      transactionVolume?: number;
      transactionDate?: string;
    }[];
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetAggregateMerchantTransactionsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getAggregateMerchantTransactionsAsync = createAsyncThunk(
  'aggregateMerchantTransactions',
  async (
    {
      merchantId,
      duration,
      category,
    }: IGetAggregateMerchantTransactionsRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get(
        `/aggregators/${businessId}/transactions/merchants/${merchantId}?duration=${duration}&category=${category}`,
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const aggregatorGetSlice = createSlice({
  name: 'aggregateMerchantTransactions/get',
  initialState,
  reducers: {
    resetGetAggregateMerchantTransactions: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAggregateMerchantTransactionsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(
        getAggregateMerchantTransactionsAsync.fulfilled,
        (state, action) => {
          state.status = 'success';
          state.value =
            action.payload as IGetAggregateMerchantTransactionsResponse;
        }
      )
      .addCase(
        getAggregateMerchantTransactionsAsync.rejected,
        (state, action) => {
          state.status = 'failed';
          state.value =
            action.payload as IGetAggregateMerchantTransactionsResponse;
          state.error = action.error.message;
        }
      );
  },
});

export const { resetGetAggregateMerchantTransactions } =
  aggregatorGetSlice.actions;

export default aggregatorGetSlice.reducer;
