import { Box, Stack, Text, useRadioGroup } from '@chakra-ui/react';
import { useState } from 'react';

import {
  CustomDrawerFooter,
  PrimaryButton,
  RadioCard,
} from '../../../components/custom';
import { useAppSelector } from '../../../store/hooks';
import { TStep } from './AdditionalInformationSteps';
import { INFORMATION_TYPES } from './informationTypeConstants';

type TAdditionalInformationType = 'NIN' | 'CDD' | 'Address' | 'Documents' | '';

interface AdditionalInformationTypeProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<TStep>>;
}

export default function AdditionalInformationType({
  setCurrentStep,
}: AdditionalInformationTypeProps) {
  const { value: documentsValue } = useAppSelector(
    (state) => state.getDocumentRequest
  );

  const { value: authValue } = useAppSelector((state) => state.auth);

  const [selectedInformationType, setSelectedInformationType] =
    useState<TAdditionalInformationType>('');

  const handleTypeChange = (value: TAdditionalInformationType) => {
    setSelectedInformationType(value);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'informationTypes',
    defaultValue: selectedInformationType,
    onChange: handleTypeChange,
  });

  const group = getRootProps();

  const handleSubmit = () => {
    setCurrentStep(selectedInformationType as TStep);
  };

  const showCard = (id: string) => {
    if (
      id === 'nin' &&
      authValue?.data?.user?.businessMember?.[0]?.ninVerificationStatus ===
        'pending'
    ) {
      return true;
    } else if (
      id === 'cdd' &&
      authValue?.data?.user?.businessMember?.[0]?.hasCdd === false
    ) {
      return true;
    } else if (
      id === 'address' &&
      (authValue?.data?.user?.businessMember?.[0]?.addressVerificationStatus ===
        'pending' ||
        authValue?.data?.user?.businessMember?.[0]
          ?.addressVerificationStatus === 'rejected')
    ) {
      return true;
    } else if (
      id === 'documents' &&
      !documentsValue?.data?.submitted &&
      documentsValue?.data?.names?.length
    ) {
      return true;
    }
  };

  return (
    <>
      <Box mt={4} mb={6}>
        <Text color="#000" mb={1}>
          Additional Information Required
        </Text>
        <Text color="gray.300" fontSize=".875rem" lineHeight="171.5%">
          Select Additional Information Type to continue
        </Text>
      </Box>

      <Box display="flex" flexDirection="column">
        <Stack spacing={6} flex={1} mb={2} {...group}>
          {INFORMATION_TYPES.map((type) => {
            const radio = getRadioProps({ value: type.id });

            return (
              <Box
                key={type.id}
                sx={{ display: showCard(type.id) ? 'initial' : 'none' }}
              >
                <RadioCard {...radio}>
                  <Text fontWeight={500} color="#000" mb={2}>
                    {type.title}
                  </Text>
                  <Text color="#99a0aC">
                    {type.id === 'documents'
                      ? documentsValue?.data?.reason || type.description
                      : type.description}
                  </Text>
                </RadioCard>
              </Box>
            );
          })}
        </Stack>

        <CustomDrawerFooter>
          <PrimaryButton
            onClick={handleSubmit}
            isDisabled={!selectedInformationType}
            w="full"
          >
            Continue
          </PrimaryButton>
        </CustomDrawerFooter>
      </Box>
    </>
  );
}
