import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import api from "../../../api/axios";
import { IApiState } from "../../../types/api";

interface IVerifyEmailVerificationRequest {
  email: string;
  otp: string;
  id: string;
}

interface IVerifyEmailVerificationResponse {
  success?: boolean;
  message?: string;
  data?: {
    id?: string;
    email?: string;
    otp?: string;
  };
}

const initialState: IApiState<IVerifyEmailVerificationResponse> = {
  value: null,
  status: "idle",
  error: "",
};

export const verifyEmailVerificationAsync = createAsyncThunk(
  "emailVerification/verify",
  async (
    { email, id, otp }: IVerifyEmailVerificationRequest,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.post(`verification/email/${id}/verify`, {
        email,
        otp,
      });

      return data;
    } catch (err) {
      const error = err as AxiosError<IVerifyEmailVerificationResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const emailVerificationVerifySlice = createSlice({
  name: "emailVerification/verify",
  initialState,
  reducers: {
    resetEmailVerificationVerify: (state) => {
      state.value = null;
      state.status = "idle";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyEmailVerificationAsync.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(verifyEmailVerificationAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.value = action.payload as IVerifyEmailVerificationResponse;
      })
      .addCase(verifyEmailVerificationAsync.rejected, (state, action) => {
        state.status = "failed";
        state.value = action.payload as IVerifyEmailVerificationResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetEmailVerificationVerify } =
  emailVerificationVerifySlice.actions;

export default emailVerificationVerifySlice.reducer;
