import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

export interface IGetKybDetailsResponse {
  status?: boolean;
  message?: string;
  data?: {
    business?: {
      name?: string;
      address?: string;
      type?: string;
      email?: string;
      identification?: string;
    };
    representative?: {
      firstName?: string;
      lastName?: string;
      type?: string;
      gender?: string;
      dateOfBirth?: string;
      phoneNumber?: string;
    };
    id?: string;
    businessId?: string;
    cacNumber?: string;
    tinNumber?: string;
    registrationPeriod?: string;
    registrationDate?: string;
    registrationType?: string;
    scumlRegistered?: string;
    businessType?: string;
    approvalStatus?: string;
    approvedAt?: string;
    reason?: string;
    accountCreationStatus?: string;
    cacVerificationStatus?: string;
    tinVerificationStatus?: string;
    addressVerificationStatus?: string;
    members?: {
      id?: string;
      userId?: string;
      firstName?: string;
      middleName?: string;
      lastName?: string;
      gender?: string;
      phoneNumber?: string;
      dateOfBirth?: string;
      email?: string;
      bvn?: string;
      idNumber?: string;
      idType?: string;
      nationality?: string;
      memberType?: string;
      bvnVerificationStatus?: string;
      idVerificationStatus?: string;
      invitedBy?: string;
      invitedAt?: string;
      approvedAt?: string;
      isApproved?: string;
    }[];
    documents?: string[];
    createdAt?: string;
    updatedAt?: string;
    userId?: string;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetKybDetailsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getKybDetailsAsync = createAsyncThunk(
  'kyb/kybDetails',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get(`kyb/${businessId}`, config);

      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const kybDetailsGetSlice = createSlice({
  name: 'kyb/kybDetails/get',
  initialState,
  reducers: {
    resetGetKybDetails: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getKybDetailsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getKybDetailsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetKybDetailsResponse;
      })
      .addCase(getKybDetailsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetKybDetailsResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetKybDetails } = kybDetailsGetSlice.actions;

export default kybDetailsGetSlice.reducer;
