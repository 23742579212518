import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import api from "../../../api/axios";
import { IApiState } from "../../../types/api";
import { RootState } from "../..";

interface IGetBusinessCategoriesResponse {
  status?: boolean;
  message?: string;
  data?: { id?: string; name?: string }[];
}

const initialState: IApiState<IGetBusinessCategoriesResponse> = {
  value: null,
  status: "idle",
  error: "",
};

export const getBusinessCategoriesAsync = createAsyncThunk(
  "businessCategories/get",
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };

      const { data } = await api.get("/business-categories", config);

      return data;
    } catch (err) {
      const error = err as AxiosError<IGetBusinessCategoriesResponse>;

      return rejectWithValue(error.response?.data); 
    }
  }
);

export const businessCategoriesFetch = createSlice({
  name: "businessCategories/fetch",
  initialState,
  reducers: {
    resetBusinessCategories: (state) => {
      state.value = null;
      state.status = "idle";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBusinessCategoriesAsync.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getBusinessCategoriesAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.value = action.payload as IGetBusinessCategoriesResponse;
      })
      .addCase(getBusinessCategoriesAsync.rejected, (state, action) => {
        state.status = "failed";
        state.value = action.payload as IGetBusinessCategoriesResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetBusinessCategories } = businessCategoriesFetch.actions;
export default businessCategoriesFetch.reducer;
