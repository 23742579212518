import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IApproveRepresentativeRequest {
  representativeId: string;
  businessId: string;
  action: string; // approve | decline
}

interface IApproveRepresentativeResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    userId?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    gender?: string;
    phoneNumber?: string;
    dateOfBirth?: string;
    email?: string;
    bvn?: string;
    idNumber?: string;
    idType?: string;
    nationality?: string;
    memberType?: string;
    isApproved?: boolean;
    approvedAt?: string;
    bvnVerificationStatus?: string;
    idVerificationStatus?: string;
    ninVerificationStatus?: string;
    createdA?: string;
    updatedAt?: string;
    businessMemberId?: string;
    businessId?: string;
    created_at?: string;
    updated_at?: string;
  };

  errors?: IApiResponseError[];
}

const initialState: IApiState<IApproveRepresentativeResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const approveRepresentativeAsync = createAsyncThunk(
  'representative/approve',
  async (
    values: IApproveRepresentativeRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.put<IApproveRepresentativeResponse>(
        '/kyb/approve-representative',
        values,
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IApproveRepresentativeResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const representativeEnableSlice = createSlice({
  name: 'representative/approve',
  initialState,
  reducers: {
    resetApproveRepresentative(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(approveRepresentativeAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(approveRepresentativeAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload;
      })
      .addCase(approveRepresentativeAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IApproveRepresentativeResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetApproveRepresentative } = representativeEnableSlice.actions;

export default representativeEnableSlice.reducer;
