import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface ICreateBusinessRepresentativeRequest {
  businessId: string;
  director: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
}

interface ICreateBusinessRepresentativeResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    userId?: string;
    kybId?: null;
    businessId?: string;
    documentId?: string;
    firstName?: string;
    lastName?: string;
    gender?: string;
    phoneNumber?: string;
    dateOfBirth?: string;
    email?: string;
    bvn?: string;
    isBusinessOwner?: boolean;
    bvnVerificationStatus?: string;
    type?: null;
    kyb?: null;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<ICreateBusinessRepresentativeResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const createBusinessRepresentativeAsync = createAsyncThunk(
  'businessRepresentative/create',
  async (
    values: ICreateBusinessRepresentativeRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };

      const { data } = await api.post('/owner-verification', values, config);

      return data;
    } catch (err) {
      const error = err as AxiosError<ICreateBusinessRepresentativeResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const businessRepresentativeCreationSlice = createSlice({
  name: 'businessRepresentative/create',
  initialState,
  reducers: {
    resetBusinessRepresentativeCreation(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBusinessRepresentativeAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(createBusinessRepresentativeAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as ICreateBusinessRepresentativeResponse;
      })
      .addCase(createBusinessRepresentativeAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as ICreateBusinessRepresentativeResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetBusinessRepresentativeCreation } =
  businessRepresentativeCreationSlice.actions;

export default businessRepresentativeCreationSlice.reducer;
