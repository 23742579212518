import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { getTeamMembersAsync } from './getTeamMembers';

interface IDeleteTeamMemberResponse {
  status?: boolean;
  message?: string;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IDeleteTeamMemberResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const deleteTeamMemberAsync = createAsyncThunk(
  'teamMember/delete',
  async (teamMemberId: string, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const businessId =
        localStorage.getItem('businessId') ||
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.delete<IDeleteTeamMemberResponse>(
        `/team-members/${teamMemberId}`,
        config
      );

      dispatch(getTeamMembersAsync());

      return data;
    } catch (err) {
      const error = err as AxiosError<IDeleteTeamMemberResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const teamMemberDeleteSlice = createSlice({
  name: 'teamMember/delete',
  initialState,
  reducers: {
    resetTeamMemberDelete(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteTeamMemberAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(deleteTeamMemberAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IDeleteTeamMemberResponse;
      })
      .addCase(deleteTeamMemberAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IDeleteTeamMemberResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetTeamMemberDelete } = teamMemberDeleteSlice.actions;

export default teamMemberDeleteSlice.reducer;
