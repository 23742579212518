import { Box, Flex, Image, Link } from '@chakra-ui/react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import LogoHorizontal from '../../assets/images/9jaPay Logo_Horizontal.svg';
import Logo from '../../assets/images/logo-square.svg';
// import MinimalNavbarCTA from './MinimalNavbarCTA';

export default function MinimalNavbar() {
  const { pathname } = useLocation();

  const authPathname = pathname === '/auth' || pathname === '/';

  return (
    <Box
      bg="neutral.100"
      // borderBottom={1}
      borderStyle="solid"
      borderColor="ash.200"
      data-testid="homepage-navbar"
    >
      <Flex
        align="center"
        justify="space-between"
        minH="80px"
        // maxW="1728px"
        mx="auto"
        py={{ base: 2 }}
        px={{ base: 5, md: '44px' }}
      >
        <Link as={RouterLink} to="/" data-testid="homepage-link">
          <Image
            src={authPathname ? LogoHorizontal : Logo}
            width={authPathname ? '132px' : '48px'}
            height={authPathname ? '32px' : '48px'}
            data-testid="header-image"
          />
        </Link>
        {/* <MinimalNavbarCTA /> */}
      </Flex>
    </Box>
  );
}
