import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { IOptInRequest, IOptInResponseData } from '../../../types/devOptIn';
import { RootState } from '../..';
import { getUserBusinessesAsync } from '../business/getUserBusinessesSlice';

interface IOptInResponse {
  status?: boolean;
  message?: string;
  data?: IOptInResponseData;
  error?: IApiResponseError[];
}

const initialState: IApiState<IOptInResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const optInAsync = createAsyncThunk(
  'developer/opt-in',
  async (values: IOptInRequest, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };
      const { data } = await api.post<IOptInResponse>(
        '/developer/opt-in',
        values,
        config
      );
      dispatch(getUserBusinessesAsync());
      return data;
    } catch (err) {
      const error = err as AxiosError<IOptInResponse>;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const optInSlice = createSlice({
  name: 'opt-in',
  initialState,
  reducers: {
    resetOptIn(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(optInAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(optInAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload;
      })
      .addCase(optInAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IOptInResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetOptIn } = optInSlice.actions;

export default optInSlice.reducer;
