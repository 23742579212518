import { useContext, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { OnboardingContext } from '../../../contexts/OnboardingContext';
import { useTrackEvent } from '../../../iterable/useTrackEvent';
import { useAppDispatch } from '../../../store/hooks';
import { PrimaryButton } from '../CustomButtons';

interface MetaMapButtonElement {
  addEventListener(
    type: string,
    listener: (event: CustomEvent<unknown>) => void,
    options?: boolean | AddEventListenerOptions
  ): void;
  removeEventListener(
    type: string,
    listener: (event: CustomEvent<unknown>) => void,
    options?: boolean | AddEventListenerOptions
  ): void;
  click(): void;
}

interface MetaMapVideoVerificationProps {
  completedVerificationCallback?: () => void;
  email?: string;
}

export default function MetaMapVideoVerification({
  completedVerificationCallback,
  email: emailFromProps,
}: MetaMapVideoVerificationProps) {
  const { state: routerState } = useLocation();

  const buttonRef = useRef<MetaMapButtonElement | null>(null);

  const { state, dispatch } = useContext(OnboardingContext);
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const trackEvent = useTrackEvent();

  const handleMetamapButtonClick = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  useEffect(() => {
    const handleUserFinishedSdk = ({ detail }: CustomEvent) => {
      dispatch({
        type: 'UPDATE_USER_LIVENESS_VERIFICATION',
        payload: detail?.verificationId,
      });

      if (completedVerificationCallback) {
        completedVerificationCallback();
      }

      navigate('/auth/sign-up/create-passcode', {
        state: { videoConfirmed: true, businessId: routerState?.businessId },
      });

      trackEvent({
        eventName: 'kyb',
        dataFields: {
          livenessCheckVerified: true,
          passcodeCreated: false,
          lastSeen: Date(),
          bvnSubmitted: false,
          bvnVerified: false,
          idVerificationSubmitted: false,
          idVerificationVerified: false,
        },
      });
    };

    const metamapButtonRef = buttonRef.current;

    if (metamapButtonRef) {
      metamapButtonRef.addEventListener(
        'metamap:userFinishedSdk',
        handleUserFinishedSdk
      );
    }

    return () => {
      if (metamapButtonRef) {
        metamapButtonRef.removeEventListener(
          'metamap:userFinishedSdk',
          handleUserFinishedSdk
        );
      }
    };
  }, [
    appDispatch,
    completedVerificationCallback,
    dispatch,
    navigate,
    routerState?.businessId,
    trackEvent,
  ]);

  const userMetadata = JSON.stringify({
    email: emailFromProps ?? state?.email,
  });

  return (
    <>
      <PrimaryButton w="full" onClick={handleMetamapButtonClick}>
        Verify Your Identity
      </PrimaryButton>
      <div style={{ display: 'none' }}>
        <metamap-button
          ref={buttonRef}
          metadata={userMetadata}
          clientid={process.env.REACT_APP_META_MAP_CLIENT_ID}
          flowid={process.env.REACT_APP_META_MAP_VIDEO_FLOW_ID}
          color="#1a1c1f"
        />
      </div>
    </>
  );
}
