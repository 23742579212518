import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import {
  getNinetyDaysBeforeToday,
  getTodayDateInYYYYMMDD,
} from '../../../helpers/utilityFunctions';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { getAccountBalanceAsync } from '../account/getAccountBalanceSlice';
import { getTransactionHistoryAsync } from '../account/getTransactionHistorySlice';
import { showAlert } from '../appToast/appToastSlice';

interface IInitiateTransferRequest {
  amount: number;
  customerId: string;
  narration: string;
  pin: string;
  recipientAccountName: string;
  recipientAccountNumber: string;
  recipientBankCode: string;
  senderAccountName: string;
  senderAccountNumber: string;
  recipientBankName: string;
  nameEnquiryReference?: string;
}

interface IInitiateTransferResponse {
  success?: boolean;
  message?: string;
  data?: {
    id?: string;
    phoneNumber?: string;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IInitiateTransferResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const initiateTransferAsync = createAsyncThunk(
  'transfer/create',
  async (
    {
      amount,
      customerId,
      nameEnquiryReference,
      narration,
      pin,
      recipientAccountName,
      recipientAccountNumber,
      senderAccountName,
      senderAccountNumber,
      recipientBankCode,
    }: IInitiateTransferRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId =
        localStorage.getItem('businessId') ??
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };
      const { data } = await api.post(
        '/transfers',
        {
          customerId,
          nameEnquiryReference,
          narration,
          pin,
          recipientAccountName,
          recipientAccountNumber,
          senderAccountName,
          senderAccountNumber,
          recipientBankCode,
          amount: Math.round(amount * 100),
          senderKycLevel: 3,
        },
        config
      );

      const oldAvailableBalanceFromStorage =
        localStorage.getItem('availableBalance');
      const parsedAvailableBalance = oldAvailableBalanceFromStorage
        ? (JSON.parse(oldAvailableBalanceFromStorage) as number)
        : null;

      const newAvailableBalance = parsedAvailableBalance
        ? parsedAvailableBalance - amount * 100
        : null;

      if (newAvailableBalance) {
        localStorage.setItem(
          'availableBalance',
          JSON.stringify(newAvailableBalance)
        );
      }

      dispatch(getAccountBalanceAsync());

      dispatch(
        getTransactionHistoryAsync({
          searchParams: {
            startDate: getNinetyDaysBeforeToday(),
            endDate: getTodayDateInYYYYMMDD(),
            searchField: 'FinancialDate',
          },
        })
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IInitiateTransferResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
      });

      if (error.response?.status === 401) {
        dispatch(
          showAlert({
            status: 'error',
            type: 'drawer',
            description:
              error?.response?.data?.message ??
              'Your PIN is invalid. Please check and try again',
          })
        );
      } else {
        dispatch(
          showAlert({
            status: 'error',
            type: 'drawer',
            description: errorMessage,
          })
        );
      }

      return rejectWithValue(error.response?.data);
    }
  }
);

export const transferInitiationSlice = createSlice({
  name: 'transfer/initiate',
  initialState,
  reducers: {
    resetInitiateTransfer: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateTransferAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(initiateTransferAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IInitiateTransferResponse;
      })
      .addCase(initiateTransferAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IInitiateTransferResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetInitiateTransfer } = transferInitiationSlice.actions;

export default transferInitiationSlice.reducer;
