import {
  Box,
  Button,
  FormControl,
  IconButton,
  IconProps,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SkeletonText,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { getTitleCase } from '../../../helpers/utilityFunctions';
import { useAppDispatch } from '../../../store/hooks';
import { showAlert } from '../../../store/slices/appToast/appToastSlice';
import { GreenPlusCircle, GreyXCircleIcon, SearchIcon } from '../../icons';

interface CustomMultipleSelectionDropdownProps {
  options: { value: string; label: string }[];
  placeholder: string;
  dropdownHeader: string;
  setFieldValue: (field: string, value: string[]) => void;
  fieldValue: string;
  values: string[];
  isLoading?: boolean;
  searchable?: boolean;
  searchFieldPlaceholder?: string;
  maxSelections?: number;
  menuWidth?: string;
  OptionIcon?: (props: IconProps) => JSX.Element;
}

export default function CustomMultipleSelectionDropdown({
  options,
  placeholder,
  dropdownHeader,
  setFieldValue,
  fieldValue,
  values,
  isLoading,
  searchable,
  searchFieldPlaceholder,
  maxSelections,
  OptionIcon,
  menuWidth = '420px',
}: CustomMultipleSelectionDropdownProps) {
  const dispatch = useAppDispatch();

  const [selectedOptions, setSelectedOptions] = useState(values ?? []);

  const handleSelectOption = (value: string) => {
    if (maxSelections && selectedOptions.length === maxSelections) {
      dispatch(
        showAlert({
          type: 'drawer',
          status: 'info',
          description: `You can only select up to ${maxSelections} options.`,
        })
      );

      return;
    }

    if (selectedOptions?.find((option) => option === value)) return;

    setSelectedOptions((selectedOptions) => [...selectedOptions, value]);

    setFieldValue(fieldValue, [...selectedOptions, value]);
  };

  const [filteredOptions, setFilteredOptions] = useState(options);

  const [searchKey, setSearchKey] = useState('');

  const handleSearchOptions = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setSearchKey(value);

    const valueToSearchBy = value.toLowerCase().trim();

    if (valueToSearchBy === '') {
      setFilteredOptions(options);
    } else {
      setFilteredOptions(
        options.filter((option) =>
          option.label.toLowerCase().includes(valueToSearchBy)
        )
      );
    }
  };

  const handleRemoveSelectedOption = (value: string) => {
    setSelectedOptions((selectedOptions) =>
      selectedOptions.filter((option) => option !== value)
    );

    setFieldValue(
      fieldValue,
      selectedOptions.filter((option) => option !== value)
    );
  };

  useEffect(() => {
    const updatedFilteredOptions = options.filter(
      (option) => !selectedOptions.includes(option.value)
    );

    setFilteredOptions(updatedFilteredOptions);
  }, [options, selectedOptions]);

  return (
    <>
      {selectedOptions.length > 0 && (
        <Box borderRadius="14px" border="1px solid #ebedef" mb={3} pb="8px">
          {selectedOptions.map((selectedOption, index) => {
            const selectedOptionLabel = options.find(
              (option) => option.value === selectedOption
            )?.label;

            return (
              <Box
                key={selectedOption + index}
                pl="16px"
                pr="4px"
                py="8px"
                borderRadius="4px"
                height="40px"
                mb={2}
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={3}
                >
                  <Box display="flex" gap={3} alignItems="center">
                    {OptionIcon && <OptionIcon />}
                    <Text
                      fontSize=".875rem"
                      lineHeight="142.857%"
                      noOfLines={1}
                    >
                      {getTitleCase(selectedOptionLabel ?? '')}
                    </Text>
                  </Box>

                  <Box>
                    <IconButton
                      variant="ghost"
                      _hover={{ background: 'transparent' }}
                      icon={<GreyXCircleIcon />}
                      aria-label="Remove Team Member"
                      onClick={() => handleRemoveSelectedOption(selectedOption)}
                    />
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      )}

      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          sx={{
            height: '56px',
            padding: '8px 24px',
            borderRadius: '14px',
            border: '1px solid #EBEDEF',
            background: '#fff',
            width: '100%',
            maxWidth: '100%',
            color: 'gray.200',
            fontSize: '.875rem',
            fontWeight: 400,
            _hover: { bg: 'transparent' },
            _expanded: { bg: 'transparent' },
            textAlign: 'left',
          }}
        >
          {placeholder}
        </MenuButton>
        <MenuList width={{ base: '100%', sm: menuWidth }}>
          {searchable && (
            <FormControl id="search">
              <InputGroup>
                {/* <InputLeftElement pointerEvents="none" pt="4" pb="2.5">
                  <SearchIcon />
                </InputLeftElement> */}
                <Input
                  variant="rounded"
                  placeholder={searchFieldPlaceholder || 'Search'}
                  value={searchKey}
                  type="search"
                  onChange={handleSearchOptions}
                  sx={{
                    backgroundColor: 'ash.100',
                    height: '44px',
                    borderRadius: '14px',
                    ':focus': {
                      borderRadius: '14px',
                      boxShadow: '0',
                    },
                    ':not(:placeholder-shown), :not(:focus)': {
                      borderRadius: '14px',
                    },
                  }}
                />
              </InputGroup>
            </FormControl>
          )}
          {dropdownHeader && (
            <Text
              color="gray.300"
              fontSize=".6875rem"
              lineHeight="136.364%"
              padding="8px"
              textTransform="uppercase"
            >
              {dropdownHeader}
            </Text>
          )}
          {isLoading ? (
            <MenuItem
              onClick={() => {
                // Do nothing
              }}
              padding="8px"
              borderRadius="4px"
              sx={{
                _hover: {
                  background: 'transparent',
                },
                _focus: { background: 'transparent' },
                width: { base: '100%', sm: '420px' },
              }}
            >
              <SkeletonText
                width="100%"
                skeletonHeight={4}
                endColor="#f9fafa"
              />
            </MenuItem>
          ) : options.length === 0 ? (
            <Text textAlign="center">There are no options to display</Text>
          ) : (
            filteredOptions.map((option, index) => (
              <MenuItem
                key={option.value + index}
                onClick={() => handleSelectOption(option.value)}
                padding="8px"
                borderRadius="4px"
                sx={{
                  _hover: {
                    background: '#EBEDEF',
                    '#addIcon': {
                      visibility: 'visible',
                    },
                  },
                }}
                value={option.value}
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={3}
                >
                  <Box display="flex" gap={3} alignItems="center">
                    {OptionIcon && <OptionIcon />}
                    <Text
                      fontSize=".875rem"
                      lineHeight="142.857%"
                      noOfLines={1}
                    >
                      {getTitleCase(option.label)}
                    </Text>
                  </Box>

                  <Box id="addIcon" visibility="hidden">
                    <GreenPlusCircle />
                  </Box>
                </Box>
              </MenuItem>
            ))
          )}
        </MenuList>
      </Menu>
    </>
  );
}
