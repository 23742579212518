import { Box, BoxProps, Text } from '@chakra-ui/react';

interface CustomStatusBadgeProps extends BoxProps {
  variant?:
    | 'pending'
    | 'confirmed'
    | 'completed'
    | 'failed'
    | 'reversal'
    | 'started'
    | 'paused'
    | 'ended';
  /**
   * This is used to select the type of status color to display
   * @default 1
   */
  type?: 1 | 2;
  children: React.ReactNode;
}

export default function CustomStatusBadge({
  variant,
  children,
  type = 1,
  ...rest
}: CustomStatusBadgeProps) {
  let backgroundColor, color;

  if (variant?.toLowerCase() === 'pending') {
    backgroundColor = '#FFF8EB';
    color = type === 1 ? '#6b4905' : '#996b0f';
  } else if (
    variant?.toLowerCase() === 'confirmed' ||
    variant?.toLowerCase() === 'completed'
  ) {
    backgroundColor = '#EBFFF0';
    color = '#149138';
  } else if (variant?.toLowerCase() === 'failed') {
    backgroundColor = '#ffeceb';
    color = '#911d11';
  } else if (variant?.toLowerCase() === 'reversal') {
    backgroundColor = '#EBF8FF';
    color = '#064869';
  } else if (variant?.toLowerCase() === 'started') {
    backgroundColor = '#EBF8FF';
    color = '#116791';
  } else if (variant?.toLowerCase() === 'paused') {
    backgroundColor = '#F0EBFF';
    color = '#351691';
  } else if (variant?.toLowerCase() === 'ended') {
    backgroundColor = '#FAEEE8';
    color = '#CC5214';
  } else {
    backgroundColor = 'gray.400';
    color = '#fff';
  }
  return (
    <Box
      padding="4px 16px"
      background={backgroundColor}
      color={color}
      borderRadius="32px"
      textAlign="center"
      {...rest}
    >
      <Text
        fontWeight={500}
        color={color}
        fontSize=".75rem"
        lineHeight="200%"
        textTransform="capitalize"
      >
        {children}
      </Text>
    </Box>
  );
}
