import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IGenerateAccountStatementRequest {
  accountNo: string;
  userId: string;
  startDate: string;
  endDate: string;
  transmissionMode: string;
  documentType: string;
}

interface IGenerateAccountStatementResponse {
  status: boolean;
  message: string;
  statusCode: string;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGenerateAccountStatementResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const generateAccountStatementAsync = createAsyncThunk(
  'generateAccountStatement',
  async (
    {
      accountNo,
      userId,
      startDate,
      endDate,
      transmissionMode,
      documentType,
    }: IGenerateAccountStatementRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId =
        localStorage.getItem('businessId') ||
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.post(
        `account-statement/`,
        {
          accountNo,
          userId,
          startDate,
          endDate,
          transmissionMode,
          documentType,
        },
        config
      );
      return data;
    } catch (err) {
      const error = err as AxiosError<IGenerateAccountStatementResponse>;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const generateAccountStatementSlice = createSlice({
  name: 'generateAccountStatement',
  initialState,
  reducers: {
    generateAccountStatementReset: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateAccountStatementAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(generateAccountStatementAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGenerateAccountStatementResponse;
      })
      .addCase(generateAccountStatementAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGenerateAccountStatementResponse;
        state.error = action.error.message;
      });
  },
});

export const { generateAccountStatementReset } =
  generateAccountStatementSlice.actions;

export default generateAccountStatementSlice.reducer;
