import { Box, Link, Text } from '@chakra-ui/react';

export default function MinimalFooter() {
  return (
    <Box
      position="fixed"
      bottom="0"
      background="#fff"
      py={8}
      w={{ base: '100%', lg: '50%' }}
      data-testid="homepage-footer"
    >
      <Box
        maxW="1728px"
        mx="auto"
        display="flex"
        justifyContent="space-between"
      >
        <Text
          fontSize="12px"
          fontWeight={400}
          lineHeight="200%"
          color="richBlack.300"
          pl={{ base: '44px', md: '64px' }}
          data-testid="copyright-text"
        >
          ©{new Date().getFullYear()}{' '}
          <Link
            href="https://www.9japay.com/"
            target="_blank"
            data-testid="website-link"
          >
            9jaPay.
          </Link>{' '}
        </Text>
        <Text
          fontSize="12px"
          fontWeight={400}
          lineHeight="200%"
          color="richBlack.300"
          pr={{ base: '44px', md: '64px' }}
          data-testid="privacy-policy-text"
        >
          <Link
            href="https://www.9japay.com/privacy"
            target="_blank"
            data-testid="privacy-policy-link"
          >
            Privacy Policy
          </Link>{' '}
        </Text>
      </Box>
    </Box>
  );
}
