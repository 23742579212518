import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../..';

interface EnvironmentState {
  value: 'test' | 'live';
}

const initialState: EnvironmentState = {
  value: 'test',
};

const environmentSlice = createSlice({
  name: 'environment',
  initialState,
  reducers: {
    setEnvironment(state, action: PayloadAction<'test' | 'live'>) {
      state.value = action.payload;
    },
    toggleEnvironment(state) {
      state.value = state.value === 'test' ? 'live' : 'test';
    },
  },
});

export const { setEnvironment, toggleEnvironment } = environmentSlice.actions;

export default environmentSlice.reducer;

export const selectEnvironment = (state: RootState) => state.environment.value;
