import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';

import api from '../../../api/axios';
import { convertMilliSecondsToDays } from '../../../helpers/utilityFunctions';
import { IApiResponseError, IApiState } from '../../../types/api';
import { IUserToken } from '../../../types/user';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';
import { calculateTokenRefreshTime } from './../../../helpers/utilityFunctions';
import { setAccessTokenExpirationTime } from './accessTokenExpirationSlice';
import { updateAuthToken } from './authSlice';
import { signOut } from './signOutSlice';

interface IRefreshTokenResponse {
  status?: boolean;
  message?: string;
  data?: {
    user?: {
      id: string;
    };
    token?: IUserToken;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IRefreshTokenResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const refreshTokenAsync = createAsyncThunk(
  'auth/refreshToken',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const refreshToken = state.auth.value?.data?.token?.refreshToken;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };

      const { data } = await api.post<IRefreshTokenResponse>(
        '/auth/refresh',
        { refreshToken },
        config
      );

      const expiresInMilliseconds = data?.data?.token?.expiresIn;
      const expiresInDays = convertMilliSecondsToDays(expiresInMilliseconds);

      const tokenData = data?.data?.token;

      // Update the http-only cookie
      Cookies.set('tokenData', JSON.stringify(tokenData), {
        expires: expiresInDays,
        sameSite: 'Strict',
        secure: true,
      });

      // Update auth state
      dispatch(updateAuthToken(data.data?.token));

      // Restart the refresh token timer
      dispatch(
        setAccessTokenExpirationTime(
          calculateTokenRefreshTime(expiresInMilliseconds)
        )
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IRefreshTokenResponse>;

      dispatch(
        showAlert({
          status: 'info',
          title: 'Session Expired',
          description: 'Please login to continue',
        })
      );

      dispatch(signOut());

      return rejectWithValue(error.response?.data);
    }
  }
);

export const refreshTokenSlice = createSlice({
  name: 'auth/refresh',
  initialState,
  reducers: {
    resetRefreshToken: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshTokenAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(refreshTokenAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload;
      })
      .addCase(refreshTokenAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IRefreshTokenResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetRefreshToken } = refreshTokenSlice.actions;

export default refreshTokenSlice.reducer;
