export const INFORMATION_TYPES = [
  {
    id: 'nin',
    title: 'National Identification Number',
    description: 'Verify your identity by entering your NIN',
  },
  {
    id: 'cdd',
    title: 'CDD',
    description: 'Share more insights about you',
  },
  {
    id: 'address',
    title: 'Residential Information',
    description: 'Tell us more about your residential address',
  },
  {
    id: 'documents',
    title: 'Business Documents',
    description: 'Kindly provide documents requested',
  },
];
