const FormLabel = {
  baseStyle: {
    fontFamily: "Aeonik",
    fontWeight: 400,
    fontSize: ".875rem",
    lineHeight: "1.3125rem",
    letterSpacing: "-0.12px",
    color: "#000",
    paddingLeft: "8px",
  },
};

export default FormLabel;
