import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';
import { getTransactionMandatesAsync } from './getTransactionMandates';

interface IUpdateTransactionMandateRequest {
  id: string;
  name: string;
  categoryId: string;
  minimumAmount: number;
  pin: string;
  amounts: {
    id: string;
    teamMemberIds: string[];
    maximumAmount: number;
  }[];
}

interface IUpdateTransactionMandateResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    businessId?: string;
    categoryId?: string;
    name?: string;
    minimumAmount?: number;
    isActive?: boolean;
    amounts?: {
      id?: string;
      approvers?: {
        id?: string;
        createdAt?: string;
        updatedAt?: string;
        isActive?: boolean;
      }[];
      maximumAmount?: string;
      mandateId?: string;
    }[];
  };
  errors?: IApiResponseError[];
}

export interface ThresholdFormValues {
  id: string;
  thresholdAmount: string;
  approvalBy: string[];
}

const initialState: IApiState<IUpdateTransactionMandateResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const updateTransactionMandateAsync = createAsyncThunk(
  'transactionMandate/update',
  async (
    {
      id,
      amounts,
      categoryId,
      minimumAmount,
      name,
      pin,
    }: IUpdateTransactionMandateRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId =
        localStorage.getItem('businessId') ||
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.put<IUpdateTransactionMandateResponse>(
        `/transaction/mandates/${id}`,
        { amounts, categoryId, minimumAmount, name, pin },
        config
      );

      dispatch(getTransactionMandatesAsync());

      dispatch(
        showAlert({
          status: 'success',
          description:
            data?.message ?? 'Transaction Mandate updated successfully',
        })
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IUpdateTransactionMandateResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const transactionMandateUpdateSlice = createSlice({
  name: 'transactionMandate/update',
  initialState,
  reducers: {
    resetTransactionMandateUpdate(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateTransactionMandateAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(updateTransactionMandateAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IUpdateTransactionMandateResponse;
      })
      .addCase(updateTransactionMandateAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IUpdateTransactionMandateResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetTransactionMandateUpdate } =
  transactionMandateUpdateSlice.actions;
export default transactionMandateUpdateSlice.reducer;
