import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IGetReferralHistoryRequest {
  businessId: string;
  page: number;
  size: number;
  status: boolean;
}
export interface IReferrerHistoryData {
  id?: string;
  refereeName?: string;
  lastPromptDate?: string;
  isPromptAllowed?: boolean;
  dateOnboarded?: string;
  isRefereeActive?: boolean;
}

export interface IGetReferralHistoryResponse {
  status: boolean;
  message: string;
  data: {
    referrerHistory: {
      page: number;
      referees: IReferrerHistoryData[];
      size: number;
      totalElement: number;
    };
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetReferralHistoryResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getReferralHistoryAsync = createAsyncThunk(
  'getReferralHistory',
  async (
    { businessId, page, size, status }: IGetReferralHistoryRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const accessToken = state.auth.value?.data?.token?.accessToken;
      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };
      const { data } = await api.get(
        `referral/referrer/history/${businessId}?page=${page}&size=${size}&status=${status}`,
        config
      );
      return data;
    } catch (err) {
      const error = err as AxiosError<IGetReferralHistoryResponse>;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const referralHistoryFetchSlice = createSlice({
  name: 'referralHistory/get',
  initialState,
  reducers: {
    resetGetReferralHistory: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builders) => {
    builders
      .addCase(getReferralHistoryAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getReferralHistoryAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetReferralHistoryResponse;
      })
      .addCase(getReferralHistoryAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetReferralHistoryResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetReferralHistory } = referralHistoryFetchSlice.actions;

export default referralHistoryFetchSlice.reducer;
