import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { devApi, prodDevApi } from '../../../api/axios';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';

interface ICreateTransientVAccountRequest {
  requestReference: string;
  accountName: string;
  timeToLive?: string;
}

interface ICreateTransientVAccountResponse {
  status?: string;
  message?: string;
  statusCode?: string;
  errors?: IApiResponseError[];
}

const initialState: IApiState<ICreateTransientVAccountResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const createTransientVAccountAsync = createAsyncThunk(
  'transientVAccount/create',
  async (
    values: ICreateTransientVAccountRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const devTokenInStorage = localStorage.getItem('devTokenData');

      const parsedDevTokenInStorage = devTokenInStorage
        ? JSON.parse(devTokenInStorage)
        : null;

      const developerId = localStorage.getItem('developerId');

      const environment = state.environment?.value;

      const apiInstance = environment === 'live' ? prodDevApi : devApi;

      const authToken =
        environment === 'live' ? accessToken : parsedDevTokenInStorage;

      const config = {
        headers: {
          Authorization: `Token ${authToken}`,
          'Business-id': developerId,
          Environment: 'live',
        },
      };

      const { data } = await apiInstance.post<ICreateTransientVAccountResponse>(
        '/transient-virtual-accounts',
        values,
        config
      );

      dispatch(
        showAlert({
          status: 'success',
          description: data?.message ?? 'Virtual Account created successfully',
          type: 'drawer',
        })
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<ICreateTransientVAccountResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
      });

      dispatch(
        showAlert({
          status: 'error',
          description: errorMessage,
          type: 'drawer',
        })
      );

      return rejectWithValue(error.response?.data);
    }
  }
);

export const transientVAccountCreationSlice = createSlice({
  name: 'transientVAccount/create',
  initialState,
  reducers: {
    resetTransientVAccountCreation(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTransientVAccountAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(createTransientVAccountAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as ICreateTransientVAccountResponse;
      })
      .addCase(createTransientVAccountAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as ICreateTransientVAccountResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetTransientVAccountCreation } =
  transientVAccountCreationSlice.actions;

export default transientVAccountCreationSlice.reducer;
