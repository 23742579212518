import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';
import { saveBusinessAsync } from './saveBusinessSlice';

interface ICreateBusinessRequest {
  name?: string;
  category: string;
  address?: string;
  city?: string;
  state?: string;
  createdByOwner?: boolean;
  type: 'soletrader' | 'business';
  email: string;
  aggregatorCode?: string;
  agentCode?: string;
}

interface ICreateBusinessResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    category?: string;
    name?: string;
    address?: string;
    city?: string;
    state?: string;
    creatorId?: string;
    creatorType?: 'owner' | 'representative' | string;
    createdAt?: string;
    updatedAt: string;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<ICreateBusinessResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const createBusinessAsync = createAsyncThunk(
  'business/create',
  async (
    values: ICreateBusinessRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };

      const { data } = await api.post<ICreateBusinessResponse>(
        '/business',
        values,
        config
      );

      if (data.data?.id && data.data?.name) {
        localStorage.setItem('businessId', data.data?.id);
        localStorage.setItem('businessName', data.data?.name);
      }

      dispatch(
        saveBusinessAsync({
          businessId: data?.data?.id ?? '',
          businessType:
            values.type === 'business' ? 'REGISTERED' : 'SOLETRADER',
        })
      );

      if (values.type === 'business') {
        dispatch(
          showAlert({
            status: 'success',
            description: 'Business Information Submitted Successfully',
            type: 'drawer',
          })
        );
      }

      return data;
    } catch (err) {
      const error = err as AxiosError<ICreateBusinessResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
        customMessage: 'Failed to submit business details, try again',
      });

      dispatch(
        showAlert({
          status: 'error',
          description: errorMessage,
          type: 'drawer',
        })
      );

      return rejectWithValue(error.response?.data);
    }
  }
);

export const businessCreationSlice = createSlice({
  name: 'business/create',
  initialState,
  reducers: {
    resetBusinessCreation(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBusinessAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(createBusinessAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as ICreateBusinessResponse;
      })
      .addCase(createBusinessAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as ICreateBusinessResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetBusinessCreation } = businessCreationSlice.actions;

export default businessCreationSlice.reducer;
