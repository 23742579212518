import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

interface IUploadAdditionalDocumentsRequest {
  documentRequestId: string;
  documents: {
    name: string;
    file: string;
  }[];
}

interface IUploadAdditionalDocumentsResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    businessId?: string;
    kybId?: string;
    userId?: string;
    names?: string[];
    submitted?: boolean;
    submittedAt?: string;
    createdAt?: string;
    updatedAt?: string;
    reason?: string;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IUploadAdditionalDocumentsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const uploadAdditionalDocumentsAsync = createAsyncThunk(
  'kyb/uploadAdditionalDocuments',
  async (
    values: IUploadAdditionalDocumentsRequest,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const kybId = state.getKybDetails.value?.data?.id ?? '';

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const config = {
        headers: {
          Authorization: `BearerToken ${accessToken}`,
        },
      };

      const { data } = await api.post(
        `/kyb/${kybId}/document-request/upload`,
        values,
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IUploadAdditionalDocumentsResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const uploadAdditionalDocumentsSlice = createSlice({
  name: 'uploadAdditionalDocuments/get',
  initialState,
  reducers: {
    resetUploadAdditionalDocuments(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadAdditionalDocumentsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(uploadAdditionalDocumentsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IUploadAdditionalDocumentsResponse;
      })
      .addCase(uploadAdditionalDocumentsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IUploadAdditionalDocumentsResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetUploadAdditionalDocuments } =
  uploadAdditionalDocumentsSlice.actions;

export default uploadAdditionalDocumentsSlice.reducer;
