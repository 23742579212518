import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { OnboardingProvider } from '../contexts/OnboardingContext';
import { MinimalLayout } from '../layouts';
import MinimalSideLayout from '../layouts/MinimalSideLayout';
import Loadable from './Loadable';
import PublicRoute from './PublicRoute';

// Lazy Imports
const LoginPage = Loadable(
  lazy(() => import('../pages/onboardingPages/LoginPage'))
);
const SignUpPage = Loadable(
  lazy(() => import('../pages/onboardingPages/SignUpPage'))
);
const NotFoundPage = Loadable(lazy(() => import('../pages/NotFoundPage')));
const CreateNewAccountPage = Loadable(
  lazy(() => import('../pages/onboardingPages/CreateNewAccountPage'))
);
const SignUpOTPPage = Loadable(
  lazy(() => import('../pages/onboardingPages/SignUpOTPPage'))
);
const VideoVerificationPage = Loadable(
  lazy(() => import('../pages/onboardingPages/VideoVerificationPage'))
);
const CreatePasscodePage = Loadable(
  lazy(() => import('../pages/onboardingPages/CreatePasscodePage'))
);
const CreateTransactionPinPage = Loadable(
  lazy(() => import('../pages/onboardingPages/CreateTransactionPinPage'))
);
const WelcomePage = Loadable(
  lazy(() => import('../pages/onboardingPages/WelcomePage'))
);

const RequestResetPasscodePage = Loadable(
  lazy(() => import('../pages/onboardingPages/RequestResetPasscodePage'))
);
const ResetPasscodePage = Loadable(
  lazy(() => import('../pages/onboardingPages/ResetPasscodePage'))
);
const TeamMemberVideoVerificationForm = Loadable(
  lazy(
    () => import('../modules/InvitedTeamMember/TeamMemberVideoVerificationForm')
  )
);
const TeamMemberPasscode = Loadable(
  lazy(() => import('../modules/InvitedTeamMember/TeamMemberPasscode'))
);
const LoginWithRetailPage = Loadable(
  lazy(() => import('../pages/onboardingPages/LoginWithRetailPage'))
);
const CreateMerchantPasscodePage = Loadable(
  lazy(() => import('../pages/onboardingPages/CreateMerchantPasscodePage'))
);
const RegisterPage = Loadable(
  lazy(() => import('../pages/onboardingPages/RegisterPage'))
);

// Route Object
const AuthenticationRoutes: RouteObject = {
  path: '/auth',
  element: (
    <PublicRoute>
      <OnboardingProvider>
        <MinimalSideLayout />
      </OnboardingProvider>
    </PublicRoute>
  ),
  children: [
    {
      index: true,
      element: <LoginPage />,
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ],
};

export default AuthenticationRoutes;
