import PrimaryButton, {
  PrimaryButtonProps,
} from "../CustomButtons/PrimaryButton";
import CustomDrawerFooter from "./CustomDrawerFooter";

interface CustomDrawerActionPrimaryButtonProps extends PrimaryButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  children: string;
}
export default function CustomDrawerActionPrimaryButton({
  onClick,
  children,
  ...rest
}: CustomDrawerActionPrimaryButtonProps) {
  return (
    <CustomDrawerFooter>
      <PrimaryButton onClick={onClick} w="full" {...rest}>
        {children}
      </PrimaryButton>
    </CustomDrawerFooter>
  );
}
