import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';

interface IGetTeamMemberInviteDetailsResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    business?: {
      id?: string;
      category?: string;
      type?: string;
      name?: string;
      email?: string;
      address?: string;
      city?: string;
      state?: string;
      hasAccount?: boolean;
      creatorId?: string;
      creatorType?: string;
      createdAt?: string;
      updatedAt?: string;
    };
    user?: {
      id?: string;
      firstName?: string;
      middleName?: string;
      lastName?: string;
      email?: string;
      phoneNumber?: string;
      gender?: string;
      dateOfBirth?: string;
      livenessVerificationStatus?: string;
      lastLoginAt?: string;
      isBlocked?: boolean;
      createdAt?: string;
      updatedAt?: string;
      blockedAt?: string;
      hasPin?: boolean;
    };
    inviteStatus?: string;
    isActive?: boolean;
    createdAt?: string;
    updatedAt?: string;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetTeamMemberInviteDetailsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getTeamMemberInviteDetailsAsync = createAsyncThunk(
  'teamMembersInviteDetails',
  async (inviteId: string, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/team-members/invites/${inviteId}`);
      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const teamMembersInviteDetailsGetSlice = createSlice({
  name: 'teamMembersInviteDetails/get',
  initialState,
  reducers: {
    resetGetTeamMemberInviteDetails: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamMemberInviteDetailsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getTeamMemberInviteDetailsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetTeamMemberInviteDetailsResponse;
      })
      .addCase(getTeamMemberInviteDetailsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetTeamMemberInviteDetailsResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetTeamMemberInviteDetails } =
  teamMembersInviteDetailsGetSlice.actions;

export default teamMembersInviteDetailsGetSlice.reducer;
