const Avatar = {
  baseStyle: {
    excessLabel: {
      ml: 1.5,
      color: "gray.300",
      bg: "transparent",
      fontWeight: 400,
      fontSize: ".875rem",
      lineHeight: "171%",
    },
  },
};

export default Avatar;
