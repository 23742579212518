import CryptoJS from 'crypto-js';

const ENCRYPTION_KEY = 'cTgXqptJPmgkYNWm5ZeF';
const iv = CryptoJS.enc.Hex.parse('SdQSEK6KFLXBS4D'); // Initialization Vector

/**
 * Encrypts the given data using AES encryption.
 * @param {unknown} data - The data to be encrypted.
 * @returns {string} The encrypted ciphertext.
 */
export const encryptData = (data: unknown): string => {
  try {
    const ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      ENCRYPTION_KEY
    ).toString();
    return ciphertext;
  } catch (error) {
    console.error('Failed to encrypt: ', error);
    return '';
  }
};

/**
 * Decrypts the given ciphertext using AES decryption.
 * @param {string} ciphertext - The ciphertext to be decrypted.
 * @returns {unknown} The decrypted data.
 */
export const decryptData = (ciphertext: string): unknown => {
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, ENCRYPTION_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (error) {
    console.error('Failed to decrypt: ', error);
    return null;
  }
};

/**
 * Encrypts data and persists the encrypted value using a fixed value.
 * Use if you want the encrypted value to be persisted for the same input/data.
 * @param {unknown} data - The data to encrypt.
 * @returns {string} The encrypted data.
 */
export const encryptDataWithFixedIV = (data: unknown): string => {
  try {
    const ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY),
      { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    ).toString();

    return ciphertext;
  } catch (error) {
    console.error('Failed to encrypt with fixed iv: ', error);
    return '';
  }
};

/**
 * Decrypts data and retrieves the persisted encrypted value. Use for data that was encryptedWith a fixed iv
 * @param {string} ciphertext - The encrypted data to decrypt.
 * @returns {unknown} The decrypted data.
 */
export const decryptDataWithFixedIV = (ciphertext: string): unknown => {
  try {
    const decryptedBytes = CryptoJS.AES.decrypt(
      ciphertext,
      CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY),
      { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    const decryptedData = JSON.parse(
      decryptedBytes.toString(CryptoJS.enc.Utf8)
    );

    return decryptedData;
  } catch (error) {
    console.error('Failed to decrypt data: ', error);
    return null;
  }
};
