import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';

interface ISaveBusinessRequest {
  businessId: string;
  businessType: 'REGISTERED' | 'SOLETRADER';
}

interface ISaveBusinessResponse {
  status?: boolean;
  message?: string;
  data?: {
    businessId?: string;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<ISaveBusinessResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const saveBusinessAsync = createAsyncThunk(
  'business/save',
  async (
    values: ISaveBusinessRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      };

      const { data } = await api.post('/kyb/save-business', values, config);

      return data;
    } catch (err) {
      const error = err as AxiosError<ISaveBusinessResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
        customMessage: 'Failed to submit business details, try again',
      });

      dispatch(
        showAlert({
          status: 'error',
          description: errorMessage,
          type: 'drawer',
        })
      );

      return rejectWithValue(error.response?.data);
    }
  }
);

export const businessCreationSlice = createSlice({
  name: 'business/save',
  initialState,
  reducers: {
    resetBusinessCreation(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveBusinessAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(saveBusinessAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as ISaveBusinessResponse;
      })
      .addCase(saveBusinessAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as ISaveBusinessResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetBusinessCreation } = businessCreationSlice.actions;

export default businessCreationSlice.reducer;
