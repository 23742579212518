import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { devApi, prodDevApi } from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { IVirtualAccountTransactions } from '../../../types/virtualAccount';
import { RootState } from '../..';

interface IGetTransactionsRequest {
  page?: number;
  startDate?: string;
  endDate?: string;
}

interface IGetTransactionsResponse {
  totalCount?: number;
  totalCreditSum?: number;
  totalDebitSum?: number;
  status?: boolean;
  message?: string;
  data?: IVirtualAccountTransactions[];
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetTransactionsResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getTransactionsAsync = createAsyncThunk(
  'transactions',
  async (
    {
      page = 1,
      startDate,
      endDate,
    }: IGetTransactionsRequest & { startDate?: string; endDate?: string },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const devTokenInStorage = localStorage.getItem('devTokenData');

      const parsedDevTokenInStorage = devTokenInStorage
        ? JSON.parse(devTokenInStorage)
        : null;

      const developerId = localStorage.getItem('developerId');

      const environment = state.environment?.value;

      const apiInstance = environment === 'live' ? prodDevApi : devApi;

      const authToken =
        environment === 'live' ? accessToken : parsedDevTokenInStorage;

      const config = {
        headers: {
          Authorization: `Token ${authToken}`,
          'Business-id': developerId,
          Environment: 'live',
        },
      };

      let url = `/transactions?page-size=10&page-number=${page}`;

      if (startDate && endDate) {
        url += `&start-date=${startDate}&end-date=${endDate}`;
      }

      const { data } = await apiInstance.get(url, config);

      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const transactionsGetSlice = createSlice({
  name: 'transactions/get',
  initialState,
  reducers: {
    resetGetTransactions: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(getTransactionsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IGetTransactionsResponse;
      })
      .addCase(getTransactionsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetTransactionsResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetTransactions } = transactionsGetSlice.actions;

export default transactionsGetSlice.reducer;
