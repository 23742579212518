import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { refreshTokenAsync } from '../../store/slices/auth/refreshTokenSlice';

export default function RefreshTokenTimer() {
  const { expiresIn } = useAppSelector((state) => state.accessTokenExpiration);
  const dispatch = useAppDispatch();

  // Calculate the refresh token time
  const refreshTime = expiresIn - Date.now();

  useEffect(() => {
    const refreshTokenTimer = setTimeout(() => {
      dispatch(refreshTokenAsync());
    }, refreshTime);

    return () => {
      return clearTimeout(refreshTokenTimer);
    };
  }, [dispatch, refreshTime]);

  return <></>;
}
