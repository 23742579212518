import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { showAlert } from '../appToast/appToastSlice';

interface IAcceptTeamMemberRequest {
  id: string;
  phoneNumber: string;
  passcode: string;
  gender: string;
  dateOfBirth: string;
}

interface IAcceptTeamMemberResponse {
  status?: boolean;
  message?: string;
  data?: unknown;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IAcceptTeamMemberResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const acceptTeamMemberAsync = createAsyncThunk(
  'teamMember/accept',
  async (
    {
      id,
      phoneNumber,
      passcode,
      gender,
      dateOfBirth,
    }: IAcceptTeamMemberRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.post<IAcceptTeamMemberResponse>(
        `team-members/invites/${id}/accept`,
        {
          phoneNumber,
          passcode,
          gender,
          dateOfBirth,
        }
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IAcceptTeamMemberResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
      });

      dispatch(
        showAlert({
          status: 'error',
          description: errorMessage,
        })
      );

      return rejectWithValue(error.response?.data);
    }
  }
);

export const teamMemberAcceptSlice = createSlice({
  name: 'teamMember/accept',
  initialState,
  reducers: {
    resetTeamMemberAccept(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(acceptTeamMemberAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(acceptTeamMemberAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IAcceptTeamMemberResponse;
      })
      .addCase(acceptTeamMemberAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IAcceptTeamMemberResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetTeamMemberAccept } = teamMemberAcceptSlice.actions;

export default teamMemberAcceptSlice.reducer;
