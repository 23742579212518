import {
  Box,
  Button,
  Grid,
  GridItem,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import TeamImage from '../../../assets/images/team-image-rep.svg';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { resetApproveRepresentative } from '../../../store/slices/kyb/approveRepresentativeSlice';
import { getRepresentativeDetailsAsync } from '../../../store/slices/kyb/getRepresentativeDetailsSlice';
import { NotificationDotIcon } from '../../icons';
import { CustomModal } from '../CustomModal';
import RepresentativeCardDetails from './RepresentativeCardDetails';

export default function RepresentativeCard() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { status, value } = useAppSelector(
    (state) => state.getRepresentativeDetails
  );
  const { value: authValue } = useAppSelector((state) => state.auth);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const showOnRoutes = ['/app/get-started', '/app/dashboard'];

  useEffect(() => {
    dispatch(getRepresentativeDetailsAsync());
  }, [dispatch]);

  const handleModalClose = () => {
    onClose();
    dispatch(resetApproveRepresentative());
  };

  if (
    status === 'success' &&
    value?.data?.id &&
    !value?.data?.isApproved &&
    value.data.userId !== authValue?.data?.user?.id
  ) {
    return (
      <Box
        display={
          showOnRoutes.some((route) => location.pathname.includes(route))
            ? 'inherit'
            : 'none'
        }
        borderRadius="16px"
        border="1px solid #ab94f2"
        background="#CDBEFA"
        minH="104px"
        mb="62px"
        position="relative"
      >
        <NotificationDotIcon
          position="absolute"
          top="-10px"
          left={{ base: '18px', lg: '24px' }}
        />

        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }}
          gap={6}
          px={8}
          py={{ base: 4, lg: 'initial' }}
        >
          <GridItem
            ml="12px"
            w="100%"
            position="relative"
            backgroundImage={TeamImage}
            backgroundRepeat="no-repeat"
            backgroundSize="contain"
          />
          <GridItem
            w="100%"
            py={{ base: 0, lg: '6' }}
            mt={{ base: '-5', lg: 'initial' }}
          >
            <Text
              fontFamily="RecklessNeue"
              fontSize="1.125rem"
              fontWeight={700}
              lineHeight="2rem"
              mb={{ base: 0, lg: 2 }}
            >
              Pending Representative
            </Text>
            <Text fontSize=".875rem" lineHeight="114.286%">
              An action needs your attention; there's a pending representative
              awaiting your approval.
            </Text>
          </GridItem>
          <GridItem
            w="100%"
            py={{ base: 0, lg: '2rem' }}
            textAlign="right"
            alignSelf="center"
          >
            <Button
              bg="#001419"
              color="#fff"
              fontSize=".875rem"
              fontWeight={500}
              width={{ base: '100%', lg: 'initial' }}
              borderRadius={{ base: '8px', lg: '32px' }}
              p="12px 24px"
              onClick={onOpen}
            >
              Continue
            </Button>
          </GridItem>
        </Grid>

        <CustomModal
          isOpen={isOpen}
          onClose={handleModalClose}
          modalMaxWidth="588px"
          isCentered={false}
        >
          <Box mx={-6}>
            <RepresentativeCardDetails />
          </Box>
        </CustomModal>
      </Box>
    );
  }

  return null;
}
