import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';

interface ILimitValues {
  id: string;
  value: string;
}

interface IUpdateTransactionLimitRequest {
  signedIndemnity: boolean;
  pin: string;
  limits: ILimitValues[];
}

interface IUpdateTransactionLimitResponse {
  status?: boolean;
  message?: string;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IUpdateTransactionLimitResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const updateTransactionLimitAsync = createAsyncThunk(
  'transactionLimit/update',
  async (
    values: IUpdateTransactionLimitRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accountNo = localStorage.getItem('accountNumber');
      const businessId = localStorage.getItem('businessId');

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const config = {
        headers: {
          Authorization: `BearerToken ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const url = `transaction-limits/business/account/${accountNo}`;

      const { data } = await api.put(
        url,
        { ...values, pin: values.pin.toString() },
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IUpdateTransactionLimitResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
      });

      if (error.response?.status === 401) {
        dispatch(
          showAlert({
            status: 'error',
            type: 'drawer',
            description:
              error?.response?.data?.message ??
              'Your PIN is invalid. Please check and try again',
          })
        );
      } else {
        dispatch(
          showAlert({
            status: 'error',
            type: 'drawer',
            description: errorMessage,
          })
        );
      }

      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateTransactionLimitSlice = createSlice({
  name: 'transactionLimit/update',
  initialState,
  reducers: {
    resetUpdateLimit(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateTransactionLimitAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(updateTransactionLimitAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IUpdateTransactionLimitResponse;
      })
      .addCase(updateTransactionLimitAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IUpdateTransactionLimitResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetUpdateLimit } = updateTransactionLimitSlice.actions;

export default updateTransactionLimitSlice.reducer;
