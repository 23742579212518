const Switch = {
  baseStyle: {
    track: {
      bg: "rgba(120, 120, 128, 0.16)",
      padding: 1,
      _checked: {
        bg: "#26BA62",
      },
    },
  },
};

export default Switch;
