import { Box, useRadio, UseRadioProps } from '@chakra-ui/react';

import { CheckedIcon, UncheckedIcon } from '../../icons';

interface RadioCardProps extends UseRadioProps {
  children: React.ReactNode;
  checkIconDensePadding?: boolean;
}

export default function RadioCard(props: RadioCardProps) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderColor="#ebedef"
        borderRadius="16px"
        _checked={{
          bg: '#ebfff0',
          borderColor: '#00c707',
          boxShadow: '0px 4px 8px rgba(20, 204, 82, 0.1)',
        }}
        p="24px"
        position="relative"
      >
        <Box
          position="absolute"
          top={props.checkIconDensePadding ? '16px' : '24px'}
          right={props.checkIconDensePadding ? '16px' : '24px'}
          ml={20}
        >
          {props.isChecked ? (
            <CheckedIcon boxSize="24px" fill="#00C707" />
          ) : (
            <UncheckedIcon boxSize="24px" />
          )}
        </Box>

        <Box>{props.children}</Box>
      </Box>
    </Box>
  );
}
