import { useState } from 'react';

import { useAppSelector } from '../../../store/hooks';
import CDDQuestionsForm from '../IdentityVerification/CDDQuestionsForm';
import NINForm from '../IdentityVerification/NINForm';
import ResidentialAddressForm from '../IdentityVerification/ResidentialAddressForm';
import AdditionalDocumentsForm from './AdditionalDocumentsForm';
import AdditionalInformationType from './AdditionalInformationType';

export type TStep = 'informationType' | 'nin' | 'cdd' | 'address' | 'documents';

export default function AdditionalInformationSteps() {
  const [currentStep, setCurrentStep] = useState<TStep>('informationType');

  const { value } = useAppSelector((state) => state.getDocumentRequest);

  if (currentStep === 'nin') {
    return <NINForm />;
  }

  if (currentStep === 'address') {
    return <ResidentialAddressForm />;
  }

  if (currentStep === 'cdd') {
    return <CDDQuestionsForm />;
  }

  if (currentStep === 'documents') {
    return (
      <AdditionalDocumentsForm
        documentRequestId={value?.data?.id ?? ''}
        documentNames={value?.data?.names ?? []}
      />
    );
  }

  return <AdditionalInformationType setCurrentStep={setCurrentStep} />;
}
