import { useRoutes } from 'react-router-dom';

import AppRoutes from './AppRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import LandingRoutes from './LandingRoutes';
import SignOutRoutes from './SignOutRoutes';
import SignUpRoutes from './SignUpRoutes';

export default function Routes() {
  return useRoutes([
    AuthenticationRoutes,
    AppRoutes,
    LandingRoutes,
    SignOutRoutes,
    SignUpRoutes,
  ]);
}
