import {
  Box,
  Flex,
  InputGroup,
  InputRightElement,
  Skeleton,
  Spinner,
} from '@chakra-ui/react';

import { CheckCircleIcon, GreyXCircleIcon } from '../../icons';

interface InputGroupWithLoaderProps {
  children: React.ReactNode;
  isLoading: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  variant?: 'spinner' | 'skeleton';
}

export default function InputGroupWithLoader({
  children,
  isLoading,
  isSuccess,
  isError,
  variant = 'spinner',
}: InputGroupWithLoaderProps) {
  if (variant === 'skeleton') {
    return (
      <>
        {isLoading ? (
          <Flex
            height="56px"
            py="8px"
            pr="40px"
            pl="16px"
            alignItems="center"
            borderRadius="14px"
            border="1px solid #ebedef"
          >
            <Skeleton height="20px" width="150px" endColor="#f9fafa" />
          </Flex>
        ) : (
          children
        )}
      </>
    );
  }

  if (isLoading) {
    return (
      <InputGroup>
        {children}
        <InputRightElement
          sx={{
            display: isLoading ? 'initial' : 'none',
            mr: 1,
          }}
        >
          <Box
            sx={{
              width: '24px',
              height: '24px',
              borderRadius: '50%',
              background: 'ash.200',
              padding: '4px',
              mt: 4,
            }}
          >
            <Spinner
              speed="0.65s"
              thickness="2px"
              color="#7B828E"
              size="sm"
              mb="1"
            />
          </Box>
        </InputRightElement>
      </InputGroup>
    );
  }

  if (isSuccess) {
    return (
      <InputGroup>
        {children}
        <InputRightElement
          sx={{
            mr: 1,
            mt: 2,
          }}
        >
          <CheckCircleIcon boxSize="24px" />
        </InputRightElement>
      </InputGroup>
    );
  }

  if (isError) {
    return (
      <InputGroup>
        {children}
        <InputRightElement
          sx={{
            mr: 1,
            mt: 2,
          }}
        >
          <GreyXCircleIcon boxSize="24px" />
        </InputRightElement>
      </InputGroup>
    );
  }

  return <>{children}</>;
}
