import {
  AbsoluteCenter,
  Box,
  FormControl,
  FormLabel,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import {
  AnimatedLogo,
  CustomDrawerClosePrimaryButton,
  CustomDrawerFooter,
  CustomFileInput,
  CustomFormErrorMessage,
  PrimaryButton,
  StatusBox,
} from '../../../components/custom';
import { fileValidation } from '../../../constants/validationConstants';
import { convertFileToBase64 } from '../../../helpers/utilityFunctions';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  resetUploadAdditionalDocuments,
  uploadAdditionalDocumentsAsync,
} from '../../../store/slices/additionalDocument/uploadAdditionalDocumentsSlice';

interface AdditionalDocumentsFormProps {
  documentRequestId: string;
  documentNames: string[];
}

export default function AdditionalDocumentsForm({
  documentRequestId,
  documentNames,
}: AdditionalDocumentsFormProps) {
  const dispatch = useAppDispatch();

  const { status, value } = useAppSelector(
    (state) => state.uploadAdditionalDocuments
  );

  const errorMessage = useGetUIErrorMessage({
    message: value?.message,
    errors: value?.errors,
    customMessage:
      'An error occurred while submitting addition documents, try again.',
  });

  const initialValues: { [key: string]: File | null } = documentNames.reduce(
    (a, b) => ({ ...a, [b]: null }),
    {}
  );

  const validationSchema = Yup.object().shape(
    documentNames.reduce(
      (accumulator, documentName) => ({
        ...accumulator,
        [documentName]: fileValidation.required(`${documentName} is required`),
      }),
      {}
    )
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const documentsInBase64 = await Promise.all(
        Object.keys(values).map(async (key, index) => ({
          name: documentNames[index],
          file: values[key] ? await convertFileToBase64(values[key]) : '',
        }))
      );

      dispatch(
        uploadAdditionalDocumentsAsync({
          documentRequestId,
          documents: documentsInBase64,
        })
      );
    },
  });

  const {
    errors,
    touched,
    values,
    isValid,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
  } = formik;

  const handleFileUploadChange = (fieldName: string, file: File) => {
    setFieldValue(fieldName, file);
  };

  if (status === 'loading') {
    return (
      <Box
        position="relative"
        zIndex="2"
        height="80vh"
        top="-120px"
        background="#fff"
      >
        <AbsoluteCenter>
          <AnimatedLogo />
        </AbsoluteCenter>
      </Box>
    );
  }

  if (status === 'success') {
    return (
      <>
        <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
          <StatusBox
            variant="success"
            title="Documents Submitted Successfully!"
          >
            {value?.message ?? ''}
          </StatusBox>
        </AbsoluteCenter>

        <CustomDrawerClosePrimaryButton>Done</CustomDrawerClosePrimaryButton>
      </>
    );
  }

  if (status === 'failed') {
    return (
      <>
        <Box
          position="relative"
          zIndex="2"
          height="80vh"
          top="-120px"
          background="#fff"
        >
          <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
            <StatusBox variant="error" title="Failed to upload documents">
              {errorMessage}
            </StatusBox>
          </AbsoluteCenter>
        </Box>
        {/* <CustomDrawerFooter> */}
        <PrimaryButton
          onClick={() => {
            dispatch(resetUploadAdditionalDocuments());
          }}
          w="full"
        >
          Retry
        </PrimaryButton>
        {/* </CustomDrawerFooter> */}
      </>
    );
  }

  return (
    <>
      <Box mt={4} mb={6}>
        <Text color="#000" mb={1}>
          Additional information required
        </Text>
        <Text color="gray.300" fontSize=".875rem" lineHeight="171.5%">
          Please provide the necessary information and upload the required
          documents to proceed.
        </Text>
      </Box>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column">
            <Stack spacing={8} flex="1">
              {documentNames.map((documentName, index) => (
                <FormControl
                  key={documentName + index}
                  id={documentName + index}
                  isInvalid={Boolean(
                    touched[documentName] && errors[documentName]
                  )}
                >
                  <FormLabel>{documentName}</FormLabel>
                  <CustomFileInput
                    onChange={(value) =>
                      handleFileUploadChange(documentName, value)
                    }
                    fieldName={documentName}
                    isInvalid={Boolean(
                      touched[documentName] && errors[documentName]
                    )}
                    value={values[documentName]}
                    setFieldTouched={setFieldTouched}
                    accept=".png, .jpg, .jpeg, .pdf"
                  />
                  <CustomFormErrorMessage>
                    {touched[documentName] && errors[documentName]}
                  </CustomFormErrorMessage>
                </FormControl>
              ))}
            </Stack>

            {/* <CustomDrawerFooter> */}
            <PrimaryButton type="submit" isDisabled={!isValid} w="full">
              Save and Continue
            </PrimaryButton>
            {/* </CustomDrawerFooter> */}
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
}
