import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';

export interface IGetActiveGiftCardProviderByIdResponse {
  status?: boolean;
  message?: string;
  data?: {
    record?: {
      id?: string;
      name?: string;
      img_url?: string;
      gift_card_category_id?: string;
      priceType?: 'Range' | 'Fixed';
      naira_per_price?: number;
      price?: {
        min_price?: {
          amount?: string | number;
          naira?: string | number;
        };
        max_price?: {
          amount?: string | number;
          naira?: string | number;
        };
        fixed_price?: {
          amount?: string | number;
          naira?: string | number;
        };
      };
      country?: {
        name?: string;
        code?: string;
      };
      created_at?: string;
      updated_at?: string;
    }[];
  };
  code?: number;
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetActiveGiftCardProviderByIdResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getActiveGiftCardProviderByIdAsync = createAsyncThunk(
  'giftCards/activeGiftCardProviderById',
  async (giftCardId: string, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get(
        `/gift-card/active-provider-gift-card/${giftCardId}`,
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const activeGiftCardProviderByIdGetSlice = createSlice({
  name: 'giftCards/activeGiftCardProviderById/get',
  initialState,
  reducers: {
    resetGetActiveGiftCardProviderById: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActiveGiftCardProviderByIdAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(
        getActiveGiftCardProviderByIdAsync.fulfilled,
        (state, action) => {
          state.status = 'success';
          state.value =
            action.payload as IGetActiveGiftCardProviderByIdResponse;
        }
      )
      .addCase(getActiveGiftCardProviderByIdAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IGetActiveGiftCardProviderByIdResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetActiveGiftCardProviderById } =
  activeGiftCardProviderByIdGetSlice.actions;

export default activeGiftCardProviderByIdGetSlice.reducer;
