import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';

import { XFilledIcon } from '../../icons';

interface CustomModalProps extends ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  modalHeader?: string;
  btnRef?: React.RefObject<HTMLButtonElement>;
  /**
   * @default 500px
   */
  modalMaxWidth?: number | string;
  hideCloseButton?: boolean;
  blurOverlay?: boolean;
  isCentered?: boolean;
}

export default function CustomModal({
  btnRef,
  isOpen,
  onClose,
  modalHeader,
  children,
  hideCloseButton,
  blurOverlay,
  modalMaxWidth = '500px',
  isCentered = true,
}: CustomModalProps) {
  return (
    <Box pos="relative">
      <Modal
        finalFocusRef={btnRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered={isCentered}
      >
        {blurOverlay ? (
          <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        ) : (
          <ModalOverlay />
        )}
        <ModalContent
          sx={{
            maxW: modalMaxWidth,
            p: 6,
            pb: '96px',
            borderRadius: '24px',
            mx: { base: 4 },
          }}
        >
          {ModalHeader && <ModalHeader>{modalHeader}</ModalHeader>}
          {hideCloseButton ? null : (
            <ModalCloseButton
              sx={{ mt: 2, mr: 3.5 }}
              _hover={{ backgroundColor: 'transparent' }}
            >
              <XFilledIcon />
            </ModalCloseButton>
          )}
          <ModalBody>{children}</ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
