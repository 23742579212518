import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { getScheduledTransfersAsync } from './getScheduledTransfersSlice';

interface ICancelScheduledTransferResponse {
  status?: boolean;
  message?: string;
  errors?: IApiResponseError[];
}

const initialState: IApiState<ICancelScheduledTransferResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const cancelScheduledTransferAsync = createAsyncThunk(
  'scheduledTransfer/cancel',
  async (id: string, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId =
        localStorage.getItem('businessId') ||
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      setTimeout(() => {
        // window.location.reload();
        dispatch(getScheduledTransfersAsync());
      }, 2500);

      const { data } = await api.delete<ICancelScheduledTransferResponse>(
        `/transfers/scheduled/${id}/cancel`,
        config
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<ICancelScheduledTransferResponse>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const scheduledTransferCancelSlice = createSlice({
  name: 'scheduledTransfer/cancel',
  initialState,
  reducers: {
    resetScheduledTransferCancel(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cancelScheduledTransferAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(cancelScheduledTransferAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as ICancelScheduledTransferResponse;
      })
      .addCase(cancelScheduledTransferAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as ICancelScheduledTransferResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetScheduledTransferCancel } =
  scheduledTransferCancelSlice.actions;

export default scheduledTransferCancelSlice.reducer;
