import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { ITeamMember } from '../../../types/teamMember';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';
import { getOutletsAsync } from './getOutlets';

interface ICreateOutletRequest {
  accountType: string;
  name: string;
  address: string;
  city: string;
  state: string;
  teamMembers?: [];
  outletImage?: string;
}

interface ICreateOutletResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    name?: string;
    address?: string;
    city?: string;
    state?: string;
    isActive?: boolean;
    createdAt?: string;
    updatedAt?: string;
    teamMembers?: ITeamMember[];
  };

  errors?: IApiResponseError[];
}

const initialState: IApiState<ICreateOutletResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const createOutletAsync = createAsyncThunk(
  'outlet/create',
  async (
    values: ICreateOutletRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const businessId = localStorage.getItem('businessId');

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.post<ICreateOutletResponse>(
        '/outlets',
        values,
        config
      );

      dispatch(
        showAlert({
          status: 'success',
          description: data?.message ?? 'Outlet created successfully',
          type: 'drawer',
        })
      );

      dispatch(getOutletsAsync());

      return data;
    } catch (err) {
      const error = err as AxiosError<ICreateOutletResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
      });

      dispatch(
        showAlert({
          status: 'error',
          description: errorMessage,
          type: 'drawer',
        })
      );

      return rejectWithValue(error.response?.data);
    }
  }
);

export const outletCreationSlice = createSlice({
  name: 'outlet/create',
  initialState,
  reducers: {
    resetOutletCreation(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOutletAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(createOutletAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as ICreateOutletResponse;
      })
      .addCase(createOutletAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as ICreateOutletResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetOutletCreation } = outletCreationSlice.actions;

export default outletCreationSlice.reducer;
