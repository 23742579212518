import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import { IApiResponseError, IApiState } from '../../../types/api';
import { IPendingTransactionForInitiator } from '../../../types/pendingTransaction';
import { RootState } from '../..';

interface IGetPendingTransactionsForInitiatorResponse {
  status?: boolean;
  message?: string;
  data?: IPendingTransactionForInitiator[];
  errors?: IApiResponseError[];
}

const initialState: IApiState<IGetPendingTransactionsForInitiatorResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const getPendingTransactionsForInitiatorAsync = createAsyncThunk(
  'pendingTransactions/initiator',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;

      const businessId =
        localStorage.getItem('businessId') ||
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.get('/transaction/initiator/pending', config);

      return data;
    } catch (err) {
      const error = err as AxiosError<IApiResponseError>;

      return rejectWithValue(error.response?.data);
    }
  }
);

export const pendingTransactionsGetSlice = createSlice({
  name: 'pendingTransactions/initiator/get',
  initialState,
  reducers: {
    resetGetPendingTransactionsForInitiator: (state) => {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPendingTransactionsForInitiatorAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(
        getPendingTransactionsForInitiatorAsync.fulfilled,
        (state, action) => {
          state.status = 'success';
          state.value =
            action.payload as IGetPendingTransactionsForInitiatorResponse;
        }
      )
      .addCase(
        getPendingTransactionsForInitiatorAsync.rejected,
        (state, action) => {
          state.status = 'failed';
          state.value =
            action.payload as IGetPendingTransactionsForInitiatorResponse;
          state.error = action.error.message;
        }
      );
  },
});

export const { resetGetPendingTransactionsForInitiator } =
  pendingTransactionsGetSlice.actions;

export default pendingTransactionsGetSlice.reducer;
