import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '../../../api/axios';
import useGetUIErrorMessage from '../../../hooks/useGetUIErrorMessage';
import { IApiResponseError, IApiState } from '../../../types/api';
import { RootState } from '../..';
import { showAlert } from '../appToast/appToastSlice';

interface IEnableTransactionMandateResponse {
  status?: boolean;
  message?: string;
  data?: {
    id?: string;
    businessId?: string;
    categoryId?: string;
    name?: string;
    minimumAmount?: number;
    description?: string;
    isActive?: boolean;
    createdAt?: string;
    updatedAt?: string;
  };
  errors?: IApiResponseError[];
}

const initialState: IApiState<IEnableTransactionMandateResponse> = {
  value: null,
  status: 'idle',
  error: '',
};

export const enableTransactionMandateAsync = createAsyncThunk(
  'transactionMandate/enable',
  async (
    transactionMandateId: string,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;

      const accessToken = state.auth.value?.data?.token?.accessToken;
      const businessId =
        localStorage.getItem('businessId') ||
        state.auth.value?.data?.user?.businesses?.[0]?.id;

      const config = {
        headers: {
          Authorization: `Token ${accessToken}`,
          'X-Business-Id': businessId,
        },
      };

      const { data } = await api.put<IEnableTransactionMandateResponse>(
        `/transaction/mandates/${transactionMandateId}/enable`,
        {},
        config
      );

      dispatch(
        showAlert({
          status: 'success',
          description: `${data.data?.name} enabled successfully`,
        })
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IEnableTransactionMandateResponse>;

      const errorMessage = useGetUIErrorMessage({
        message: error.response?.data?.message,
        errors: error.response?.data?.errors,
      });

      dispatch(
        showAlert({
          status: 'error',
          description: errorMessage,
          type: 'drawer',
        })
      );

      return rejectWithValue(error.response?.data);
    }
  }
);

export const transactionMandateEnableSlice = createSlice({
  name: 'transactionMandate/enable',
  initialState,
  reducers: {
    resetTransactionMandateEnable(state) {
      state.value = null;
      state.status = 'idle';
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(enableTransactionMandateAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(enableTransactionMandateAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.value = action.payload as IEnableTransactionMandateResponse;
      })
      .addCase(enableTransactionMandateAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload as IEnableTransactionMandateResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetTransactionMandateEnable } =
  transactionMandateEnableSlice.actions;

export default transactionMandateEnableSlice.reducer;
